import React, { useState, useEffect } from "react";
import styled from "styled-components";
import * as moment from "moment";
import { TextField, Button } from "@material-ui/core";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import { CSVLink, CSVDownload } from "react-csv";
import download from "../../assets/download.png";
import Select from "react-select";



const StyledDatePicker = styled.div`
  .react-datepicker-wrapper {
    width: 100%;
  }
  .react-datepicker__input-container input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  .react-datepicker__header {
    background-color: #f0f0f0;
  }
  .react-datepicker__day--selected {
    background-color: #007bff;
    color: white;
  }
  .react-datepicker__day--today {
    border: 1px solid #007bff;
  }
`;



var datetime = new Date();
var tdy_date = datetime
  .toISOString()
  .slice(0, 10)
  .split("-")
  .reverse()
  .join("-");
var t_date = datetime.toISOString().slice(0, 10).split("-").join("-");
export default function ViewPurchase(props) {




  const fp = props.admin_purchase.filter(item => item.frame_type !== 'Old Frame');

  const tq = fp.reduce((sum, item) =>
    sum + item.quantity, 0
  );


  const headers = [
    { label: "SNo", key: 'sno' },
    { label: "Purchase Date", key: 'pur_date' },
    { label: "Company Name", key: "company_name" },
    { label: "Invoice Number", key: "invoice_number" },
    { label: "Brand", key: "brand" },
    { label: "HSN Code", key: "hsn_code" },
    { label: "Model Number", key: "model_number" },
    { label: "Frame Type", key: "frame_type" },
    { label: `Quantity (${tq})`, key: "quantity" },
  ];

  // Title Row
  const titleRow = ['Nesi Purchase Report', '', '']; // Adjust based on the number of columns


  return (
    <>
      <Section>
        <div className="view__acc_container">
          <div className="view__filterbox__container">
            <div className="view__filterdate__container">
              <div style={{ marginTop: "-0.5rem" }}>
                <StyledDatePicker>
                  <DatePicker
                    value={
                      props.from_date !== "Invalid date" ? props.from_date : ""
                    }
                    onChange={(date) => {
                      props.onHandleFromDate(date);
                    }}
                    dateFormat="yyyy-MM-dd"
                    placeholderText="From (Bill Date)"
                    monthsShown={1}
                    showYearDropdown
                    style={{ backgroundColor: 'red' }}
                  />
                </StyledDatePicker>

              </div>

              <div style={{ marginTop: "-0.5rem" }}>
                <StyledDatePicker>
                  <DatePicker
                    value={props.to_date !== "Invalid date" ? props.to_date : ""}
                    onChange={(date) => {
                      props.onHandleToDate(date);
                    }}
                    dateFormat="yyyy-MM-dd"
                    placeholderText="To (Bill Date)"
                    monthsShown={1}
                    showYearDropdown
                  />
                </StyledDatePicker>



              </div>

              <div style={{ marginTop: "0rem" }}>
                <Select
                  value={props.rep_company_name_val}
                  onChange={(e) => {
                    props.onHandleRep_company_name_val(e);
                    props.onHandleRep_company_name(e);
                  }}
                  options={props.companyname_options}
                  placeholder={"Company Name"}
                  // styles={customStyles_companyname}
                  menuPortalTarget={document.body}
                />
              </div>

              <div style={{ marginTop: "0rem" }}>
                <Select
                  value={props.rep_brand_name_val}
                  onChange={(e) => {
                    props.onHandleRep_brand_name_val(e);
                    props.onHandleRep_brand_name(e);
                  }}
                  options={props.brandname_options}
                  placeholder={"Brand Name"}
                  // styles={customStyles_companyname}
                  menuPortalTarget={document.body}
                />
              </div>

              <div>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    props.onHandleSearchDate();
                  }}
                >
                  <i className="fa fa-search"></i>{" "}
                </Button>
              </div>
              <div style={{ marginTop: "-1.8rem", paddingLeft: "3rem" }}>

                <CSVLink
                  filename={`Nesi-${tdy_date}.csv`}
                  target="_blank"
                  data={props.admin_purchase.map((row, index) => ({
                    ...row,
                    sno: index + 1,
                    pur_date: moment(row.pur_date).format("DD-MM-YYYY"),

                  }))}

                  headers={headers}
                >
                  <img
                    type="button"
                    src={download}
                    style={{
                      height: "2rem",
                      textAlign: "center",
                      marginTop: "2rem",
                    }}
                  />
                </CSVLink>
              </div>
            </div>

            <div className="view__filtersearch__container">
              <TextField
                className="txtfld"
                id="outlined-basic"
                variant="outlined"
                fullWidth={false}
                onChange={(e) => {
                  props.onHandleSearch(e.target.value);
                }}
                value={props.search}
                placeholder="Search"
                style={{ backgroundColor: "white" }}
              />
            </div>
          </div>
          <table className="table table-bordered table-responsive-sm">
            <thead
              style={{
                backgroundColor: "#0a3d62",
                color: "#ffffff",
              }}
            >
              <tr>
                <th>S.No</th>
                <th>PurchaseDate</th>
                <th>Company Name</th>
                <th>Invoice Number</th>
                <th>Brand</th>
                <th>HSN Code</th>
                <th>Model Number</th>
                <th>FrameType</th>
                <th>FrameColor</th>
                <th>FrameSize</th>
                <th>Quantity</th>
                <th>Frame Rate</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {props.admin_purchase.length > 0 ? (
                props.admin_purchase.map((pur, index) => {
                  return (
                    <tr
                      key={index}
                      onClick={() => {
                        props.onHandleUpdate(pur);
                      }}
                    >
                      <td> {(props.cp - 1) * 5 + (index + 1)} </td>
                      {/* <td> {moment(pur.entry_date).format("DD-MM-YYYY")} </td> */}
                      <td> {moment(pur.pur_date).format("DD-MM-YYYY")} </td>
                      <td> {pur.company_name} </td>
                      <td> {pur.invoice_number} </td>
                      <td> {pur.brand} </td>
                      <td> {pur.hsn_code} </td>
                      <td> {pur.model_number} </td>
                      <td> {pur.frame_type} </td>
                      <td> {pur.frame_color} </td>
                      <td> {pur.frame_size} </td>

                      <td> {pur.quantity} </td>
                      <td> {pur.frame_rate} </td>

                      <td style={{ cursor: "pointer" }}>
                        <i
                          className="fa fa-edit"
                          onClick={() => {
                            props.onHandleUpdate(pur);
                          }}
                        ></i>
                      </td>
                      <td style={{ cursor: "pointer" }}>
                        <i
                          className="fa fa-trash"
                          onClick={() => {
                            props.onHandleDelete(pur);
                          }}
                        ></i>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td>No Data</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </Section >
    </>
  );
}

const Section = styled.section`
  .view__acc_container {
    margin: 0px 0px;
    padding-left: 8rem;

  }

  .view__filterbox__container {
    margin: 0px auto;

    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    gap: 1rem;
    padding: 0.5rem;
    background-color: #216ea6;
  }
  .view__filterdate__container {
    padding:1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    flex: 0.7;
  }
  .view__filtersearch__container {
    flex: 0.3;
    text-align: center;
  }

  @media screen and (min-width: 280px) and (max-width: 720px) {
    .view__filterbox__container {
      display: flex;
      flex-direction: column;
    }
    .view__filterdate__container {
      display: flex;
      flex-direction: column;
    }
  }
`;

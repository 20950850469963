import axios from "axios";
import { apiUrl } from "../../config";

export const addFitterMaster = (acc) => async (dispatch) => {
  try {
    const response = await axios.post(
      `${apiUrl}/api/fitter-master/create`,
      acc
    );

    return {
      response,
    };
  } catch (error) {}
};

export const getAllFitterMasterLength = () => async (dispatch) => {
  try {
    const response = await axios.get(
      `${apiUrl}/api/fitter-master/getAllFitterMasterLength`
    );
    console.log("%%%111%%%", response.data.results[0].tot);
    return { response };
  } catch (error) {}
};

export const getAllFitterMaster = (offset, row_count) => async (dispatch) => {
  try {
    const response = await axios.get(
      `${apiUrl}/api/fitter-master/getAllFitterMaster/${offset}/${row_count}`
    );
    dispatch({
      type: "GET_FITTER_SUCCESS",
      payload: response.data.results,
    });
  } catch (error) {}
};

export const updateFitterMaster = (pur) => async (dispatch) => {
  try {
    const response = await axios.post(
      `${apiUrl}/api/fitter-master/update`,
      pur
    );
    return { response };
    // window.location.reload()
  } catch (error) {
    alert("Something Went Wrong");
    console.log(error);
  }
};

export const deleteFitterMaster = (accid) => async (dispatch) => {
  try {
    const response = await axios.post(`${apiUrl}/api/fitter-master/delete`, {
      accid,
    });
    return { response };
    // window.location.reload()
  } catch (error) {
    alert("Something Went Wrong");
    console.log(error);
  }
};

export const searchFitterMaster = (val) => async (dispatch) => {
  const data = { id: val };
  try {
    const response = await axios.post(
      `${apiUrl}/api/fitter-master/search`,
      data
    );
    return { response };
    // window.location.reload()
  } catch (error) {
    return { error };
  }
};

export const getAllFitterNames = () => async (dispatch) => {
  try {
    const response = await axios.get(
      `${apiUrl}/api/fitter-master/getallFitterNames`
    );
    //console.log('##123456##',response.data.results)
    dispatch({ type: "GET_FNAME_SUCCESS", payload: response.data.results });
  } catch (error) {}
};

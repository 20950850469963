import axios from "axios";
import { apiUrl } from "../../config";

export const addSales = (acc) => async (dispatch) => {
  console.log("ACTION------>", acc);
  dispatch({ type: "ADD_SALES_REQUEST" });
  try {
    const response = await axios.post(`${apiUrl}/api/sales/create`, acc);
    console.log("+++ add", response);
    dispatch({ type: "ADD_SALES_SUCCESS" });
    return {
      response,
    };
  } catch (error) {
    dispatch({ type: "ADD_SALES_FAILED", payload: error });
  }
};

export const allSalesClear = (acc) => async (dispatch) => {
  try {
    dispatch({ type: "ALL_SALES_CLEAR" });
  } catch (error) {
    dispatch({ type: "ALL_SALES_FAILED", payload: error });
  }
};

export const getAllSalesLength = () => async (dispatch) => {
  try {
    const response = await axios.get(`${apiUrl}/api/sales/getallsales_length`);
    //console.log('%%%111%%%',response.data.results[0].tot);
    return { response };
  } catch (error) {}
};

export const getAllSalesbycus = (
  offset,
  row_count,
  cus_name,
  phone,
  from_date
) => async (dispatch) => {
  console.log("GGGGGGG", from_date);
  dispatch({ type: "GET_SALES_REQUEST" });

  try {
    const response = await axios.get(
      `${apiUrl}/api/sales/getallsalesbycus/${offset}/${row_count}/${cus_name}/${phone}/${from_date}`
    );
    console.log("%%%%%%", response);
    dispatch({ type: "GET_SALES_SUCCESS", payload: response.data.results });
  } catch (error) {
    console.log("EEEEEEEE", error);
    dispatch({ type: "GET_SALES_FAILED", payload: error });
  }
};

export const getAllSales = (offset, row_count) => async (dispatch) => {
  dispatch({ type: "GET_SALES_REQUEST" });

  try {
    const response = await axios.get(
      `${apiUrl}/api/sales/getallsales/${offset}/${row_count}`
    );
    //console.log('%%%%%%',response);
    dispatch({ type: "GET_SALES_SUCCESS", payload: response.data.results });
  } catch (error) {
    console.log("EEEEEEEE", error);
    dispatch({ type: "GET_SALES_FAILED", payload: error });
  }
};

export const updateSales = (pur) => async (dispatch) => {
  try {
    const response = await axios.post(`${apiUrl}/api/sales/update`, pur);
    return { response };
    // window.location.reload()
  } catch (error) {
    alert("Something Went Wrong");
    console.log(error);
  }
};

export const deleteSales = (accid) => async (dispatch) => {
  try {
    const response = await axios.post(`${apiUrl}/api/sales/delete`, { accid });
    return { response };
    // window.location.reload()
  } catch (error) {
    alert("Something Went Wrong");
    console.log(error);
  }
};

export const getBill = (id, cus_name) => async (dispatch) => {
  dispatch({ type: "GET_SALES_REQUEST" });

  try {
    const response = await axios.get(
      `${apiUrl}/api/sales/getbill/${id}/${cus_name}`
    );
    console.log("%%%%%%", response);
    dispatch({ type: "GET_SALES_SUCCESS", payload: response.data.results });
  } catch (error) {
    dispatch({ type: "GET_SALES_FAILED", payload: error });
  }
};

export const searchSales = (val) => async (dispatch) => {
  const data = { id: val };
  try {
    const response = await axios.post(`${apiUrl}/api/sales/search`, data);
    return { response };
    // window.location.reload()
  } catch (error) {
    return { error };
    //alert('Something Went Wrong')
    console.log(error);
  }
};

export const filterSales = (data) => async (dispatch) => {
  try {
    const response = await axios.post(`${apiUrl}/api/sales/filterdate`, data);
    return { response };
    // window.location.reload()
  } catch (error) {
    return { error };
    //alert('Something Went Wrong')
    console.log(error);
  }
};

export const CheckedBillSales = (id, val) => async (dispatch) => {
  const data = { id, val };
  try {
    const response = await axios.post(`${apiUrl}/api/sales/checked`, data);
    return { response };
    // window.location.reload()
  } catch (error) {
    return { error };
    //alert('Something Went Wrong')
    console.log(error);
  }
};

import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
//import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Box, MenuItem, InputLabel } from "@mui/material";
import {
  TextField,
  Button,
  Grid,
  Paper,
  FormControl,
  FormHelperText,
} from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import { makeStyles } from "@material-ui/core/styles";
import * as moment from "moment";
import AdminNavbar from "../../components/AdminNavbar/AdminNavbar";
import Sidebar from "../../components/Sidebar";
import { useDispatch, useSelector } from "react-redux";
import jwt_decode from "jwt-decode";
import Select from "react-select";
import ViewJob from "./ViewJob";
import ViewPurchase from "./ViewPurchase";
import { getAllFitterNames } from "../../redux/actions/fitterMasterAction";

import { getAllLensPowerNames } from "../../redux/actions/lensPowerMasterAction";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";

import {
  addPurchase,
  deletePurchase,
  updatePurchase,
  getAllPurchase,
  getAllPurchaseLength,
  allPurchaseClear,
  searchPurchase,
  filterPurchase,
  updatePurchaseQuantity,
  deletePurchaseQuantity,
} from "../../redux/actions/purchaseAction";

import {
  addJob,
  deleteJob,
  updateJob,
  getAllJob,
  getAllJobbycus,
  getAllJobLength,
  allJobClear,
  searchJob,
  filterJob,
  CheckedBillJob,
  updateJobStatus,
} from "../../redux/actions/jobAction";

import Loading from "../../components/Loading";
import Error from "../../components/Error";
import OnLineErrors from "../../components/OnLineErrors";
import Success from "../../components/Success";
import "./pagestyle.css";
import "./printest.css";
import { useReactToPrint } from "react-to-print";
import Modal from "react-modal";
import logo from "../../assets/logo.png";

var datetime = new Date();
var tdy_date = datetime
  .toISOString()
  .slice(0, 10)
  .split("-")
  .reverse()
  .join("-");
var t_date = datetime.toISOString().slice(0, 10).split("-").join("-");

export default function Job() {
  const classes = useStyles();

  const [is_edit, setis_edit] = useState(false);
  const [id, setid] = useState("");
  const [brand, setbrand] = useState("");

  const [fitter_name, setfitter_name] = useState("");
  const [fitter_name_val, setfitter_name_val] = useState("");
  const [job_order_date, setjob_order_date] = useState("");
  const [job_deliver_date, setjob_deliver_date] = useState("");
  const [lens_power, setlens_power] = useState("");
  const [lens_power_val, setlens_power_val] = useState("");

  const [fitterlist, setfitterlist] = useState([]);
  const [lenspowerlist, setlenspowerlist] = useState([]);

  const [quantity, setquantity] = useState("");
  const [maxquantity, setmaxquantity] = useState("");
  const [frame_rate, setframe_rate] = useState("");

  const [amount, samount] = useState("");
  const [model_number, setmodel_number] = useState("");
  const [frame_color, setframe_color] = useState("");
  const [frame_size, setframe_size] = useState("");
  const [frame_type, setframe_type] = useState("");
  const [lens_rate, setlens_rate] = useState();
  const [status, setstatus] = useState("");
  const [status_val, setstatus_val] = useState("");
  const [errors, seterrors] = useState([]);
  const [search, setsearch] = useState("");
  const [tot, setTot] = useState(0);

  const [from_date, setfrom_date] = useState("");
  const [to_date, setto_date] = useState("");

  const [openStatus, setOpenStatus] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("sm");

  const status_options = [
    { value: "estimation", label: "estimation" },
    { value: "jobwork", label: "jobwork" },
    { value: "sale", label: "bill" },
  ];

  const handleEditClose = () => {
    setOpenEdit(false);
  };
  const handleStatusClose = () => {
    setOpenStatus(false);
  };

  const customStyles = {
    content: {
      width: "50%",
      height: "90%",
      left: "25%",
      backgroundColor: "#FFF",
    },

    control: (base) => ({
      ...base,
      borderColor: errors.base === "*" ? "red" : "gray",
    }),
  };
  const customStyles_fitter_name = {
    control: (base) => ({
      ...base,
      borderColor: errors.fitter_name === "*" ? "red" : "gray",
    }),
  };

  const customStyles_lenspower = {
    control: (base) => ({
      ...base,
      borderColor: errors.lens_power === "*" ? "red" : "gray",
    }),
  };

  const userState = useSelector((state) => state.loginUserReducer);
  const { currentUser } = userState;

  const fState = useSelector((state) => state.getAllFitterNamesReducer);
  const fitter_names = fState.fitter_names;

  const lpState = useSelector((state) => state.getAllLensPowerNamesReducer);
  const lenspower_names = lpState.lenspower_names;

  const add_purchase_state = useSelector(
    (state) => state.getAddPurchaseReducer
  );
  const { success, error, loading } = add_purchase_state;

  const all_purchase_state = useSelector(
    (state) => state.getAllPurchaseReducer
  );
  const { purchase } = all_purchase_state;

  const all_job_state = useSelector((state) => state.getAllJobReducer);
  const { job } = all_job_state;

  // console.log("------------------job------------------", job);

  useEffect(() => {
    handleTodayDate();
    if (currentUser.length > 0) {
      var decoded = jwt_decode(userState.currentUser);
      //console.log('-------#####--------',decoded.role)
      //console.log('-------*****--------',((decoded.role !== 'admin') && (decoded.role !== 'nesi')))
      if (decoded.role !== "admin" && decoded.role !== "nesi") {
        window.location.href = "/login";
      }
    } else {
      window.location.href = "/login";
    }
    setpro_data(purchase);
    setData(job);
    dispatch(getAllFitterNames());
    dispatch(getAllLensPowerNames());
    dispatch(allJobClear());
    dispatch(getAllJobLength()).then((response) => {
      setTot(response.response.data.results[0].tot);
    });
    dispatch(getAllJob(1, itemsPerPage));

    dispatch(allPurchaseClear());
    handleFitterList();
    handleLensPowerlist();
    //  dispatch(getAllPurchaseLength()).then((response)=>{
    //   setTot(response.response.data.results[0].tot)
    //  })
    // dispatch(getAllPurchase(1, 1));
  }, []);

  useEffect(() => {
    setData(job);
  }, [job]);


  useEffect(() => {
    setpro_data(purchase);
  }, [purchase]);

  useEffect(() => {
    if (search.length < 1) {
      dispatch(getAllPurchase(currentPage, 1));
    }
  }, [search]);

  const dispatch = useDispatch();

  const handleTodayDate = () => {
    let datetime = new Date();
    let tdy_date = datetime.toISOString().slice(0, 10);

    setfrom_date(moment(tdy_date).format("DD-MM-YYYY"));
    setto_date(moment(tdy_date).format("DD-MM-YYYY"));
  };

  //
  const handleLensPowerlist = () => {
    setlenspowerlist(lenspower_names);
  };
  let lenspower_name_options = lenspowerlist.map(function (rv) {
    return { value: rv.lens_power, label: rv.lens_power };
  });
  const handleFitterList = () => {
    setfitterlist(fitter_names);
  };
  let fittername_options = fitterlist.map(function (rv) {
    return { value: rv.fitter_name, label: rv.fitter_name };
  });
  //
  const handleClear = () => {
    // console.log('------clear-------')
    setis_edit(false);
    setbrand("");
    setmodel_number("");

    setquantity("");
    setmaxquantity("");
    setframe_rate("");
    setlens_rate("");
    setframe_type("");

    setfitter_name("");
    setfitter_name_val("");
    setjob_order_date("");
    setlens_power("");
    setlens_power_val("");
    setjob_deliver_date("");
  };

  const handleDelete = (sal) => {
    console.log("@@@@", sal);

    var option = window.confirm(`Are You Sure want to delete  ${sal.brand}`);
    if (option) {
      // dispatch(deleteJob(sal.id)).then((res) => {
      //   dispatch(deletePurchaseQuantity(sal)).then((res) => {
      //     dispatch(getAllJob(1, itemsPerPage, cus_name, phone));
      //     dispatch(getAllPurchase(currentPage, 10));
      //     handleClear();
      //   });
      // });

      dispatch(deletePurchaseQuantity(sal))
        .then((res) => {
          dispatch(deleteJob(sal.id)).then((res) => {
            dispatch(getAllJob(1, itemsPerPage, cus_name, phone));
            dispatch(getAllPurchase(currentPage, 10));
            handleClear();
          });
        })
        .catch((err) => {
          console.log("--EROOR--", err);
        });
    }
  };

  const handleSwitchChange = (sal, new_status) => {
    console.log("+++++tar++++", sal.id, new_status);

    const data = { from_date, to_date, cus_name };
    dispatch(CheckedBillJob(sal.id, new_status)).then((res) => {
      dispatch(filterJob(data))
        .then((res) => {
          console.log("------ RP ------", res.response.data.results);
          setData(res.response.data.results);
        })
        .catch((err) => {
          console.log("------ ERR ------", err);
          //dispatch(getAllBillService(1,itemsPerPage, cus_name, phone))
        });
    });
  };

  const handleSearch = (val) => {
    //setfrom_date('');
    //setto_date('');
    setsearch(val);
    if (search.length > 0) {
      dispatch(searchJob(val))
        .then((res) => {
          setData(res.response.data.results);
        })
        .catch((err) => {
          setsearch("");
          dispatch(getAllJob(1, itemsPerPage, cus_name, phone));
        });
    }
  };

  const handleSearchdate = () => {
    if (from_date !== "" && to_date !== "") {
      setsearch("");
      if (cus_name === "") {
        setphone("**");
      }
      const data = { from_date, to_date };
      dispatch(filterJob(data))
        .then((res) => {
          console.log("------ RP ------", res.response.data.results);
          setData(res.response.data.results);
        })
        .catch((err) => {
          dispatch(getAllJob(1, itemsPerPage));
        });
    } else {
      alert("Select date");
    }
  };

  const Bill = () => {
    console.log("FFFFFFFFF", from_date);

    if (cus_name.length > 0) {
      dispatch(getAllJobbycus(1, itemsPerPage, cus_name, phone, from_date))
        .then((res) => {
          console.log("&&&&&======BILL DATA =======&&&&", res);
        })
        .then(() => {
          openModal();
          handlePrint();
          closeModal();
        });
    } else {
      alert("Customer Name Required");
    }
  };

  const Submit = () => {
    const user_data = {
      id,
      fitter_name,
      job_order_date,
      lens_power,
      job_deliver_date,
    };
    console.log("000000", user_data);
    const errors = validate(user_data);
    seterrors(errors);
    console.log("@@@@@", errors);
    if (Object.keys(errors).length === 0) {
      dispatch(updateJob(user_data)).then((res) => {
        //console.log('!!!!!!',res.response.data.success)
        setis_edit(false);
        handleClear();
        handleEditClose();
        dispatch(getAllJob(currentPage, itemsPerPage, cus_name, phone));
        if (res.response.data.success) {
          alert("Updated");
        }
      });
    } else {
      // console.log('@#@#@#@#',errors)
    }
  };

  const UpdateStatus = () => {
    const user_data = {
      id,
      status,
    };
    console.log("000000", user_data);
    // const errors = validate(user_data);
    // seterrors(errors);
    console.log("@@@@@", errors);
    if (status.length > 0) {
      dispatch(updateJobStatus(user_data)).then((res) => {
        //console.log('!!!!!!',res.response.data.success)

        handleClear();
        handleStatusClose();
        dispatch(getAllJob(currentPage, itemsPerPage));
        if (res.response.data.success) {
          alert("Updated");
        }
      });
    } else {
      alert("please select");
      // console.log('@#@#@#@#',errors)
    }
  };

  const validate = (data) => {
    console.log('======', data);


    const errors = {};




    if (!data.fitter_name) {
      errors.fitter_name = "*";
    }

    if (!data.job_order_date || data.job_order_date == "Invalid date") {
      errors.job_order_date = "required";
    }
    if (!data.lens_power) {
      errors.lens_power = "*";
    }
    if (!data.job_deliver_date || data.job_deliver_date == "Invalid date") {
      errors.job_deliver_date = "required";
    }
    const start = new Date(job_order_date);
    const end = new Date(job_deliver_date);

    if (end > start) {
      const differenceInMilliseconds = Math.abs(end - start);
      const differenceInDays = Math.ceil(differenceInMilliseconds / (1000 * 60 * 60 * 24));

    } else {
      alert("Job Deliver Date Should be Greater than Job Order Date.");
      errors.job_deliver_date = "Should be Greater than Job Order Date";
    }


    return errors;
  };

  // -------------- CUSTOMER Start------------- //

  const [cus_name, setcus_name] = useState("");
  const [phone, setphone] = useState("");
  const [place, setplace] = useState("");

  // --------------  CUSTOMER End  -----------------//

  // --------------  Products Start ------------- //
  const [pro_data, setpro_data] = useState([]);
  const [pro_search, setpro_search] = useState("");

  const handleSearchProduct = (val) => {
    setpro_search(val);
    console.log("#####", pro_search);
    if (pro_search != "") {
      dispatch(searchPurchase(val))
        .then((res) => {
          setpro_data(res.response.data.results);
        })
        .catch((err) => {
          setsearch("");
          dispatch(getAllPurchase(1, 10));
        });
    }
  };

  const handleEditProduct = (pur) => {
    setid(pur.id);
    // setpur_date(moment(pur.pur_date).format("DD-MM-YYYY"))
    setbrand(pur.brand);
    setmodel_number(pur.model_number);
    setframe_color(pur.frame_color);
    setframe_size(pur.frame_size);

    setquantity(0);
    setmaxquantity(pur.quantity);
    setframe_rate(pur.frame_rate);
    setframe_type(pur.frame_type);
    window.scrollTo({
      top: 100,
      behavior: "smooth",
    });
  };

  const handleStatusChange = (sal) => {
    console.log("@@@@*****@@@@", sal);
    setid(sal.id);
    setcus_name(sal.cus_name);
    setphone(sal.phone);
    setplace(sal.place);
    setstatus(sal.status);
    setstatus_val({ label: sal.status, value: sal.status });

    window.scrollTo({
      top: 100,
      behavior: "smooth",
    });

    setOpenStatus(true);
  };

  const handleEditJob = (sal) => {
    console.log("@@@@*****@@@@", sal);
    setid(sal.id);
    setcus_name(sal.cus_name);
    setphone(sal.phone);
    setplace(sal.place);
    setfitter_name(sal.fitter_name);
    setfitter_name_val({ label: sal.fitter_name, value: sal.fitter_name });
    setjob_order_date(moment(sal.job_order_date).format("YYYY-MM-DD"));
    setjob_deliver_date(moment(sal.job_deliver_date).format("YYYY-MM-DD"));
    setlens_power(sal.lens_power);
    setlens_power_val({ label: sal.lens_power, value: sal.lens_power });

    window.scrollTo({
      top: 100,
      behavior: "smooth",
    });

    setOpenEdit(true);
  };

  // --------------  Products End  -----------------//

  // ------------- Pagination start---------- //
  const [currentPage, setcurrentPage] = useState(1);
  const [itemsPerPage, setitemsPerPage] = useState(25);

  const [pageNumberLimit, setpageNumberLimit] = useState(2);
  const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(2);
  const [minPageNumberLimit, setminPageNumberLimit] = useState(0);

  const [data, setData] = useState([]);

  const handleClick = (event) => {
    setcurrentPage(Number(event.target.id));
    dispatch(getAllJob(Number(event.target.id), itemsPerPage));
  };

  const pages = [];
  for (let i = 1; i <= Math.ceil(tot / itemsPerPage); i++) {
    pages.push(i);
  }

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  //const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  useEffect(() => {
    dispatch(getAllJob(currentPage, itemsPerPage));
  }, [currentPage, itemsPerPage]);

  const handleNextbtn = () => {
    setcurrentPage(currentPage + 1);
    // dispatch(getAllJob(currentPage))
    if (currentPage + 1 > maxPageNumberLimit) {
      setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
  };

  const handlePrevbtn = () => {
    setcurrentPage(currentPage - 1);
    if ((currentPage - 1) % pageNumberLimit == 0) {
      setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
    }
  };

  let pageIncrementBtn = null;
  if (pages.length > maxPageNumberLimit) {
    pageIncrementBtn = <li onClick={handleNextbtn}> &hellip;+ </li>;
  }

  let pageDecrementBtn = null;
  if (minPageNumberLimit >= 1) {
    pageDecrementBtn = <li onClick={handlePrevbtn}> &hellip;- </li>;
  }

  const renderPageNumbers = pages.map((number) => {
    if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
      return (
        <li
          key={number}
          id={number}
          onClick={handleClick}
          className={currentPage == number ? "active" : null}
        >
          {number}
        </li>
      );
    } else {
      return null;
    }
  });
  // ---------------------------------------------------------------------
  const handleLoadMore = () => {
    setitemsPerPage(itemsPerPage + 100);
    setmaxPageNumberLimit(5);
    setminPageNumberLimit(0);
    setcurrentPage(1);
  };

  // ------------- Pagination end---------- //

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#f00";
  }

  function closeModal() {
    setIsOpen(false);
  }

  // var subtotal = job.reduce(
  //   (x, sal) => x + sal.frame_rate * sal.quantity,
  //   0
  // );

  var subtotal = job.reduce(
    (x, sal) => x + (sal.frame_rate + sal.lens_rate) * sal.quantity,
    0
  );




  console.log('----DATA----', data);


  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={1}>
          <Paper className={classes.paper}>
            <Sidebar title="Job Work" bgcolor="#f6b93b" />
          </Paper>
        </Grid>

        <Grid item xs={11} style={{ marginTop: "5rem" }}>
          {/* <Paper className={classes.paper}></Paper> */}
        </Grid>
      </Grid>

      {/*  */}
      <Grid>
        <React.Fragment>
          <Dialog
            fullWidth={fullWidth}
            maxWidth={maxWidth}
            open={openStatus}
            onClose={handleStatusClose}
            aria-labelledby="max-width-dialog-title"
          >
            <DialogTitle id="max-width-dialog-title">
              {cus_name} {place}
            </DialogTitle>
            <DialogContent>
              <>
                <Grid container spacing={3}>
                  <Grid item xs={1}></Grid>

                  <Grid item xs={10}>
                    <Paper className={classes.paper}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} md={4}></Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <Box>
                            <FormControl className={classes.formControl}>
                              <Select
                                value={status_val}
                                onChange={(e) => {
                                  setstatus_val(e);
                                  setstatus(e.value);
                                }}
                                options={status_options}
                                placeholder={"Search..."}
                                styles={customStyles}
                              />
                              <FormHelperText>Status</FormHelperText>
                            </FormControl>
                          </Box>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}></Grid>
                      </Grid>

                      <Grid container spacing={3} className="mt-5">
                        <Grid item xs={12} sm={4}></Grid>
                        <Grid item xs={12} sm={4}>
                          <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            startIcon={<SaveIcon />}
                            onClick={UpdateStatus}
                          >
                            Update
                          </Button>
                        </Grid>
                        <Grid item xs={12} sm={4}></Grid>
                      </Grid>
                    </Paper>

                    <Grid className="mt-3"></Grid>
                  </Grid>

                  <Grid item sm={1}></Grid>
                </Grid>
              </>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleStatusClose} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </React.Fragment>
      </Grid>
      {/*  */}

      {/* ------------------------------------------------EDIT */}
      <Grid>
        <React.Fragment>
          <Dialog
            fullWidth={fullWidth}
            maxWidth={"lg"}
            open={openEdit}
            onClose={handleEditClose}
            aria-labelledby="max-width-dialog-title"
          >
            <DialogTitle id="max-width-dialog-title">
              {cus_name} {place}
            </DialogTitle>
            <DialogContent>
              <>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Paper className={classes.paper}>
                      <Grid container spacing={1} style={{ marginTop: "0rem" }}>
                        <Grid item xs={12} sm={4} md={3}>
                          {" "}
                          <p style={{ textAlign: "center" }}>Fitter Names</p>
                          <Select
                            value={fitter_name_val}
                            onChange={(e) => {
                              setfitter_name_val(e);
                              setfitter_name(e.value);
                            }}
                            options={fittername_options}
                            placeholder={"Search..."}
                            styles={customStyles_fitter_name}
                          // menuPortalTarget={document.body}
                          />
                        </Grid>
                        <Grid item xs={12} sm={4} md={3}>
                          {" "}
                          <br />
                          <TextField
                            id="date"
                            label="Job Order Date"
                            onChange={(e) => {
                              setjob_order_date(e.target.value);
                            }}
                            type="date"
                            value={job_order_date}
                            //defaultValue={reliving_date}
                            className={classes.textField}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                          <br />
                          {errors.job_order_date && (
                            <OnLineErrors text={errors.job_order_date} />
                          )}
                        </Grid>
                        <Grid item xs={12} sm={4} md={3}>
                          {" "}
                          <p style={{ textAlign: "center" }}>Lens Power</p>
                          <Select
                            value={lens_power_val}
                            onChange={(e) => {
                              setlens_power_val(e);
                              setlens_power(e.value);
                            }}
                            options={lenspower_name_options}
                            placeholder={"Search..."}
                            styles={customStyles_lenspower}
                          />
                        </Grid>
                        <Grid item xs={12} sm={4} md={3}>
                          {" "}
                          <br />
                          <TextField
                            id="date"
                            label="Job Delivery Date"
                            onChange={(e) => {
                              setjob_deliver_date(e.target.value);
                            }}
                            type="date"
                            value={job_deliver_date}
                            //defaultValue={reliving_date}
                            className={classes.textField}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                          <br />
                          {errors.job_deliver_date && (
                            <OnLineErrors text={errors.job_deliver_date} />
                          )}
                        </Grid>
                      </Grid>
                      <br />
                      <Grid container spacing={5} className="mt-4">
                        <Grid item xs={12} sm={4}></Grid>

                        <Grid item xs={12} sm={4}>
                          {" "}
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={Submit}
                          >
                            {" "}
                            {is_edit === false ? "Save" : "Update"}{" "}
                          </Button>
                        </Grid>

                        <Grid item xs={12} sm={6} md={3}></Grid>
                      </Grid>
                      <br /> <br />
                    </Paper>

                    <Grid item xs={12} sm={4}></Grid>
                  </Grid>
                </Grid>
              </>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleEditClose} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </React.Fragment>
      </Grid>
      {/*  */}

      <Grid item xs={12} style={{ marginTop: "3rem" }}>
        <ViewJob
          job={data}
          search={search}
          from_date={from_date}
          to_date={to_date}
          cp={currentPage}
          // onHandleUpdate={handleUpdate}
          onHandleDelete={handleDelete}
          onHandleSearch={handleSearch}
          onHandleFromDate={(date) => {
            setfrom_date(moment(date).format("DD-MM-YYYY"));
          }}
          onHandleToDate={(date) => {
            setto_date(moment(date).format("DD-MM-YYYY"));
          }}
          onHandleSearchDate={handleSearchdate}
          onBillFetch={Bill}
          onHandleSwitchChange={handleSwitchChange}
          onHandleEditChange={handleEditJob}
          onHandleStatusChange={handleStatusChange}
        />
        <br /> <br />
        {search.length > 1 || search.length > 0 || phone === "**" ? null : (
          <>
            <div className="pageNumbers">
              <button
                onClick={handlePrevbtn}
                disabled={currentPage == pages[0] ? true : false}
              >
                {" "}
                &lt;&lt;
              </button>
              {pageDecrementBtn}
              {renderPageNumbers}
              {pageIncrementBtn}
              <button
                onClick={handleNextbtn}
                disabled={currentPage == pages[pages.length - 1] ? true : false}
              >
                {" "}
                &gt;&gt;
              </button>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "20px 50px",
              }}
            >
              <button onClick={handleLoadMore} className="loadmore">
                Load More
              </button>
            </div>
          </>
        )}
      </Grid>

      {/*  */}
      <div style={{ textAlign: "center" }}>
        {/* <button onClick={openModal}>Open Modal</button> */}
        <Modal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
          ariaHideApp={false}
        >
          <div
            className="row"
            style={{ backgroundColor: "#ffecf2", marginTop: "-1rem" }}
          >
            <div className="col-print-6">
              <h2
                ref={(_subtitle) => (subtitle = _subtitle)}
                onClick={handlePrint}
                style={{ textAlign: "left", cursor: "pointer" }}
              >
                Print
              </h2>
            </div>
            <div className="col-print-6">
              <button
                onClick={closeModal}
                style={{
                  float: "right",
                  backgroundColor: "#ffecf2",
                  fontSize: "2.5rem",
                }}
              >
                X
              </button>
            </div>
          </div>
          <>
            <style>{getPageMargins()}</style>

            <div ref={componentRef}>
              <div
                className="row"
                style={{ textAlign: "left", marginTop: "3rem" }}
              >
                <div className="col-print-1"></div>
                <div className="col-print-11">
                  <div className="row">
                    <div className="col-print-0"> </div>
                    <div className="col-print-9">
                      {/* <h2 style={{textAlign:'left'}}>Logo</h2>   */}
                      <img
                        src={logo}
                        style={{
                          textAlign: "left",
                          height: "5rem",
                          width: "8rem",
                        }}
                      />
                    </div>
                    <div className="col-print-3">
                      <h2 style={{ textAlign: "left", marginTop: "3rem" }}>
                        BILL
                      </h2>
                      {/* <p>{bill_num}</p> */}
                    </div>
                  </div>
                </div>
                <div className="col-print-0"></div>
              </div>

              <div
                className="row"
                style={{ textAlign: "center", marginTop: "2rem" }}
              >
                <div className="col-print-1"> </div>
                <div className="col-print-9">
                  <p style={{ textAlign: "left", fontSize: "130%" }}>
                    Nesi Optical Shop <br /> No.6, Gandhipuram Main Road, Karur,{" "}
                    <br />
                    Backside Of EB Office - 639002 <br />
                    +91 9894670007{" "}
                  </p>
                </div>
                <div className="col-print-3"> </div>
              </div>

              <div
                className="row"
                style={{ textAlign: "center", marginTop: "2rem" }}
              >
                <div className="col-print-1"></div>
                <div className="col-print-7">
                  <h5 style={{ textAlign: "left", fontSize: "130%" }}>
                    Bill To
                  </h5>
                  <p style={{ textAlign: "left", fontSize: "130%" }}>
                    {cus_name} <br /> {place} <br /> {phone}{" "}
                  </p>
                </div>
                <div className="col-print-4">
                  {/* <h5 style={{textAlign:'left',fontSize:'130%'}}>Invoice# : INV-12</h5>   */}
                  <p style={{ textAlign: "left", fontSize: "130%" }}>
                    Invoice Date: {tdy_date}{" "}
                  </p>
                </div>
              </div>

              <div
                className="row"
                style={{ textAlign: "center", marginTop: "4rem" }}
              >
                <div className="col-print-1"></div>
                <div className="col-print-10">
                  <table className="table  table-responsive">
                    <thead
                      style={{
                        backgroundColor: "#fff",
                        color: "#1a4567",
                        fontSize: "150%",
                      }}
                    >
                      <tr>
                        <th style={{ width: "100%" }}>Brand</th>
                        <th style={{ width: "100%" }}>UnitPrice</th>
                        <th style={{ width: "100%" }}>Quantity</th>
                        <th style={{ width: "100%" }}>Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {job.length > 0 ? (
                        job.map((sal, index) => {
                          return (
                            <tr key={index} style={{ fontSize: "130%" }}>
                              <td> {sal.brand} </td>
                              <td> {sal.frame_rate + sal.lens_rate} </td>
                              <td> {sal.quantity} </td>
                              <td> {sal.amount}</td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td>No Data</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  <hr />

                  <div style={{ marginTop: "2rem" }}>
                    <br></br>
                    <p
                      style={{
                        textAlign: "right",
                        marginRight: "2rem",
                        fontSize: "130%",
                      }}
                    >
                      SubTotal : {Math.round(subtotal)}
                    </p>
                    {/* <p
                      style={{
                        textAlign: "right",
                        marginRight: "2rem",
                        fontSize: "130%",
                      }}
                    >
                      SGST : {Math.round(subtotal * 0.09)}
                    </p>
                    <p
                      style={{
                        textAlign: "right",
                        marginRight: "2rem",
                        fontSize: "130%",
                      }}
                    >
                      CGST : {Math.round(subtotal * 0.09)}
                    </p> */}
                    <p
                      style={{
                        textAlign: "right",
                        marginRight: "2rem",
                        fontSize: "150%",
                        fontWeight: "bold",
                      }}
                    >
                      Total Amount: <span>&#8377;</span> {subtotal}
                    </p>
                  </div>
                </div>
                <div className="col-print-1"></div>
              </div>

              <div className="footer">
                <p>THANK YOU FOR YOUR BUSINESS </p>
              </div>
            </div>
          </>
        </Modal>
      </div>
      {/*  */}
    </>
  );
}

const marginTop = "20px";
const marginRight = "5px";
const marginBottom = "10px";
const marginLeft = "5px";
const getPageMargins = () => {
  return `@page { margin: ${marginTop} ${marginRight} ${marginBottom} ${marginLeft} !important; }`;
};

const Section = styled.section`
  margin: 10px auto;
  max-width: 650px !important;
  .wrapper {
    background-color: #ffffff;
    border: 1px solid #acdf87;
    list-style-type: none;
    padding: 10px 50px;
    border-radius: 10px;
  }

  .form-row {
    display: flex;
    justify-content: flex-end;
    padding: 0.3rem;
  }
  .form-row > label {
    padding: 0.5em 1em 0.5em 0;
    flex: 1;
    color: #1e5631;
  }

  .form-row .txtfld {
    flex: 2;
  }

  @media screen and (min-width: 280px) and (max-width: 720px) {
    .form-row {
      display: flex;
      flex-direction: column;
    }
  }
  @media screen and (min-width: 720px) and (max-width: 1080px) {
    .form-row {
      display: flex;
      flex-direction: column;
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },

  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },

  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
}));

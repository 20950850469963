import React, { useState, useEffect } from "react";

import styled from "styled-components";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { TextField, Button, Grid, Paper, FormControl } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import * as moment from "moment";
import AdminNavbar from "../../components/AdminNavbar/AdminNavbar";
import Sidebar from "../../components/Sidebar";
import { useDispatch, useSelector } from "react-redux";
import jwt_decode from "jwt-decode";
import Select from "react-select";
import ViewPurchase from "./ViewPurchase";
import {
  addPurchase,
  deletePurchase,
  updatePurchase,
  getAllPurchase,
  getAllPurchaseLength,
  allPurchaseClear,
  searchPurchase,
  filterPurchase,
  getAllCompanyNames,
} from "../../redux/actions/purchaseAction";

import { getAllBrandNames } from "../../redux/actions/brandMasterAction";
import { getAllModelNumberNames } from "../../redux/actions/modelNumberMasterAction";
import { getAllFrameColorNames } from "../../redux/actions/frameColorMasterAction";
import Loading from "../../components/Loading";
import Error from "../../components/Error";
import OnLineErrors from "../../components/OnLineErrors";
import Success from "../../components/Success";

import "./pagestyle.css";

export default function Purchase() {
  const classes = useStyles();

  const [is_edit, setis_edit] = useState(false);
  const [id, setid] = useState("");
  const [pur_date, setpur_date] = useState("");
  const [company_name, setcompany_name] = useState("");
  const [company_name_val, setcompany_name_val] = useState("");
  const [invoice_number, setinvoice_number] = useState("");
  const [brand, setbrand] = useState("");
  const [brand_val, setbrand_val] = useState("");
  const [model_number, setmodel_number] = useState("");
  const [model_number_val, setmodel_number_val] = useState("");
  const [frame_type, setframe_type] = useState("");
  const [frame_type_val, setframe_type_val] = useState("");
  const [frame_color, setframe_color] = useState("");
  const [frame_color_val, setframe_color_val] = useState("");

  const [frame_size, setframe_size] = useState("");
  const [frame_size_val, setframe_size_val] = useState("");

  const [quantity, setquantity] = useState("");
  const [hsn_code, sethsn_code] = useState("9003110");
  const [frame_rate, setframe_rate] = useState("");

  const [compnaieslist, setcompnaieslist] = useState([]);
  const [brandlist, setbrandlist] = useState([]);
  const [modelnumlist, setmodelnumlist] = useState([]);
  const [framecolorlist, setframecolorlist] = useState([]);

  const [errors, seterrors] = useState([]);
  const [search, setsearch] = useState("");
  const [tot, setTot] = useState(0);

  const [from_date, setfrom_date] = useState("");
  const [to_date, setto_date] = useState("");

  const optionsframetype = [
    { value: "Shell Full Frame", label: "Shell Full Frame" },
    { value: "Shell Supra", label: "Shell Supra" },
    { value: "3 pc", label: "3 pc" },
    { value: "FNP", label: "FNP" },
    { value: "Metal Full Frame", label: "Metal Full Frame" },
    { value: "Metal Supra", label: "Metal Supra" },
    { value: "Reading glass", label: "Reading glass" },
  ];
  const options_framesize = [
    { value: "No Size", label: "No Size" },
    { value: "42", label: "42" },
    { value: "43", label: "43" },
    { value: "44", label: "44" },
    { value: "45", label: "45" },
    { value: "46", label: "46" },
    { value: "47", label: "47" },
    { value: "48", label: "48" },
    { value: "49", label: "49" },
    { value: "50", label: "50" },
    { value: "51", label: "51" },
    { value: "52", label: "52" },
    { value: "53", label: "53" },
    { value: "54", label: "54" },
    { value: "55", label: "55" },
    { value: "56", label: "56" },
    { value: "57", label: "57" },
    { value: "58", label: "58" },
    { value: "59", label: "59" },
    { value: "60", label: "60" },
    { value: "61", label: "61" },
    { value: "62", label: "62" },
    { value: "63", label: "63" },
    { value: "64", label: "64" },
  ];
  const customStyles_companyname = {
    control: (base) => ({
      ...base,
      borderColor: errors.company_name === "*" ? "red" : "gray",
    }),
  };
  const customStyles_brand = {
    control: (base) => ({
      ...base,
      borderColor: errors.brand === "*" ? "red" : "gray",
    }),
  };

  const customStyles_model_num = {
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    control: (base) => ({
      ...base,

      borderColor: errors.model_number === "*" ? "red" : "gray",
    }),
  };

  const customStyles_frame_color = {
    control: (base) => ({
      ...base,
      borderColor: errors.frame_color === "*" ? "red" : "gray",
    }),
  };

  const customStyles_frametype = {
    control: (base) => ({
      ...base,
      borderColor: errors.frame_type === "*" ? "red" : "gray",
    }),
  };

  const customStyles_frame_size = {
    control: (base) => ({
      ...base,
      borderColor: errors.frame_size === "*" ? "red" : "gray",
    }),
  };

  const userState = useSelector((state) => state.loginUserReducer);
  const { currentUser } = userState;

  const add_purchase_state = useSelector(
    (state) => state.getAddPurchaseReducer
  );
  const { success, error, loading } = add_purchase_state;

  const all_purchase_state = useSelector(
    (state) => state.getAllPurchaseReducer
  );
  const { purchase } = all_purchase_state;

  const cnState = useSelector((state) => state.getAllCompanyNamesReducer);
  const company_names = cnState.company_names;

  const bnState = useSelector((state) => state.getAllBrandNamesReducer);
  const brand_names = bnState.brand_names;

  const mnState = useSelector((state) => state.getAllModelNumberNamesReducer);
  const modelnum_names = mnState.modelnum_names;
  //console.log("!!!!MNAME!!!", modelnum_names);

  const fcState = useSelector((state) => state.getAllFrameColorNamesReducer);
  const framecolor_names = fcState.framecolor_names;
  //console.log("!!!!FCNAME!!!", framecolor_names);

  useEffect(() => {
    if (currentUser.length > 0) {
      var decoded = jwt_decode(userState.currentUser);
      if (decoded.role !== "admin" && decoded.role !== "nesi") {
        window.location.href = "/sales";
      }
    } else {
      window.location.href = "/login";
    }
    setData(purchase);
    dispatch(allPurchaseClear());
    dispatch(getAllPurchaseLength()).then((response) => {
      setTot(response.response.data.results[0].tot);
    });
    dispatch(getAllPurchase(1, itemsPerPage));
    dispatch(getAllCompanyNames());
    dispatch(getAllBrandNames());
    dispatch(getAllModelNumberNames());
    dispatch(getAllFrameColorNames());
    handleCompanyList();
    handleBrandList();
    handleModelNumList();
    handleFrameColorList();
  }, []);

  useEffect(() => {
    const filteredItems = purchase.filter(
      (item) => item.frame_rate < 1
    );
    setData(filteredItems);
    //console.log("!!!!", purchase);
    //setData(purchase);
  }, [purchase]);

  useEffect(() => {
    if (search.length < 1) {
      dispatch(getAllPurchase(currentPage, itemsPerPage));
    }
  }, [search]);

  const dispatch = useDispatch();

  const handleUpdate = (pur) => {
    setis_edit(true);
    setid(pur.id);
    setpur_date(moment(pur.pur_date).format("YYYY-MM-DD"));

    setcompany_name(pur.company_name);
    setcompany_name_val({ label: pur.company_name, value: pur.company_name });
    setinvoice_number(pur.invoice_number);
    setbrand(pur.company_name);
    setbrand_val({ label: pur.brand, value: pur.brand });
    setframe_type(pur.frame_type);
    setframe_type_val({ label: pur.frame_type, value: pur.frame_type });
    setframe_color(pur.frame_color);
    setframe_color_val({ label: pur.frame_color, value: pur.frame_color });
    setmodel_number(pur.model_number);
    setmodel_number_val({ label: pur.model_number, value: pur.model_number });
    setframe_size(pur.frame_size);

    sethsn_code(pur.hsn_code);
    setquantity(pur.quantity);
    setframe_rate(pur.frame_rate);
    window.scrollTo({
      top: 100,
      behavior: "smooth",
    });
  };

  const handleClear = () => {
    // console.log('------clear-------')
    setis_edit(false);
    // setpur_date("");
    // setcompany_name("");
    // setinvoice_number("");
    // sethsn_code("");
    setquantity("");
    // setframe_rate(null);
    // setbrand("");
    setmodel_number("");
    setmodel_number_val("");
    // setframe_color("");
    // setframe_size("");
  };

  const handleCompanyList = () => {
    setcompnaieslist(company_names);
  };
  let companyname_options = compnaieslist.map(function (rv) {
    return { value: rv.company_name, label: rv.company_name };
  });

  //
  const handleBrandList = () => {
    setbrandlist(brand_names);
  };
  let brandname_options = brandlist.map(function (rv) {
    return { value: rv.brand_name, label: rv.brand_name };
  });

  const handleModelNumList = () => {
    setmodelnumlist(modelnum_names);
  };
  let modelnumname_options = modelnumlist.map(function (rv) {
    return { value: rv.model_number, label: rv.model_number };
  });

  const handleFrameColorList = () => {
    setframecolorlist(framecolor_names);
  };
  let framecolorname_options = framecolorlist.map(function (rv) {
    return { value: rv.frame_color, label: rv.frame_color };
  });

  //

  const handleDelete = (pur) => {
    console.log("####", pur.id);
    var option = window.confirm(
      `Are You Sure want to delete Bill Num ${pur.company_name}`
    );
    if (option) {
      dispatch(deletePurchase(pur.id)).then((res) => {
        dispatch(allPurchaseClear());
        dispatch(getAllPurchase(1, itemsPerPage));
      });
    }
  };

  const handleSearch = (val) => {
    setfrom_date("");
    setto_date("");
    setsearch(val);
    dispatch(searchPurchase(val))
      .then((res) => {
        setData(res.response.data.results);
      })
      .catch((err) => {
        setsearch("");
        dispatch(getAllPurchase(1, itemsPerPage));
      });
  };

  const handleSerachdate = () => {
    if (from_date !== "" && to_date !== "") {
      setsearch("");
      const data = { from_date, to_date };
      dispatch(filterPurchase(data))
        .then((res) => {
          console.log("------ RP ------", res.response.data.results);
          setData(res.response.data.results);
        })
        .catch((err) => {
          dispatch(getAllPurchase(1, itemsPerPage));
        });
    } else {
      alert("Select date");
    }
  };

  const Submit = () => {
    const user_data = {
      id,
      pur_date,
      company_name,
      invoice_number,
      brand,
      model_number,
      frame_color,
      frame_size,
      frame_type,
      quantity,
      hsn_code,
    };
    console.log("000000", user_data);
    const errors = validate(user_data);
    seterrors(errors);
    console.log("@@@@@", errors);
    if (Object.keys(errors).length === 0) {
      if (!is_edit) {
        dispatch(addPurchase(user_data)).then((res) => {
          dispatch(getAllPurchase(1, itemsPerPage));
          console.log("----RRR -----", res);
          alert(res.response.data.msg);
          handleClear();
        });
      } else {
        dispatch(updatePurchase(user_data)).then((res) => {
          //console.log('!!!!!!',res.response.data.success)
          setis_edit(false);
          handleClear();
          dispatch(getAllPurchase(currentPage, itemsPerPage));
          if (res.response.data.success) {
            alert("Updated");
          }
        });
      }
    } else {
      // console.log('@#@#@#@#',errors)
    }
  };

  const validate = (data) => {
    const errors = {};

    if (!data.pur_date) {
      errors.pur_date = "required";
    }
    if (!data.company_name) {
      errors.company_name = "*";
    }
    if (!data.invoice_number) {
      errors.invoice_number = "*";
    }
    if (!data.brand) {
      errors.brand = "*";
    }
    if (!data.model_number) {
      errors.model_number = "*";
    }
    if (!data.frame_color) {
      errors.frame_color = "*";
    }
    if (!data.frame_size) {
      errors.frame_size = "*";
    }
    if (!data.frame_type) {
      errors.frame_type = "*";
    }
    if (!data.quantity) {
      errors.quantity = "*";
    }
    if (!data.hsn_code) {
      errors.hsn_code = "*";
    }

    // if (!data.frame_rate) {
    //   errors.frame_rate = "*";
    // }
    // if (!data.gst_amount) {
    //   errors.gst_amount = "*"
    // }

    return errors;
  };

  // ------------- Pagination start---------- //
  const [currentPage, setcurrentPage] = useState(1);
  const [itemsPerPage, setitemsPerPage] = useState(25);

  const [pageNumberLimit, setpageNumberLimit] = useState(2);
  const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(2);
  const [minPageNumberLimit, setminPageNumberLimit] = useState(0);

  const [data, setData] = useState([]);

  const handleClick = (event) => {
    setcurrentPage(Number(event.target.id));
    dispatch(getAllPurchase(Number(event.target.id), itemsPerPage));
  };

  const pages = [];
  for (let i = 1; i <= Math.ceil(tot / itemsPerPage); i++) {
    pages.push(i);
  }

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  //const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  useEffect(() => {
    dispatch(getAllPurchase(currentPage, itemsPerPage));
  }, [currentPage, itemsPerPage]);

  const handleNextbtn = () => {
    setcurrentPage(currentPage + 1);
    // dispatch(getAllPurchase(currentPage))
    if (currentPage + 1 > maxPageNumberLimit) {
      setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
  };

  const handlePrevbtn = () => {
    setcurrentPage(currentPage - 1);
    if ((currentPage - 1) % pageNumberLimit == 0) {
      setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
    }
  };

  let pageIncrementBtn = null;
  if (pages.length > maxPageNumberLimit) {
    pageIncrementBtn = <li onClick={handleNextbtn}> &hellip;+ </li>;
  }

  let pageDecrementBtn = null;
  if (minPageNumberLimit >= 1) {
    pageDecrementBtn = <li onClick={handlePrevbtn}> &hellip;- </li>;
  }

  const renderPageNumbers = pages.map((number) => {
    if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
      return (
        <li
          key={number}
          id={number}
          onClick={handleClick}
          className={currentPage == number ? "active" : null}
        >
          {number}
        </li>
      );
    } else {
      return null;
    }
  });

  const handleLoadMore = () => {
    setitemsPerPage(itemsPerPage + 25);
    setmaxPageNumberLimit(5);
    setminPageNumberLimit(0);
    setcurrentPage(1);
  };

  // ------------- Pagination end---------- //

  return (
    <>
      <Grid container spacing={3} >
        <Grid item xs={1}>
          <Paper className={classes.paper}>
            <Sidebar title="PURCHASE" bgcolor="#0984e3" />
          </Paper>
        </Grid>

        <Grid item xs={11} style={{ marginTop: "5rem" }}>
          <Paper className={classes.paper}>
            <Grid container spacing={5}>
              <Grid item xs={12} sm={6} md={3}>
                <p style={{ textAlign: "center" }}>Company Name</p>
                <Select
                  value={company_name_val}
                  onChange={(e) => {
                    setcompany_name_val(e);
                    setcompany_name(e.value);
                  }}
                  options={companyname_options}
                  placeholder={"Company Name"}
                  styles={customStyles_companyname}
                  menuPortalTarget={document.body}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <p style={{ textAlign: "center" }}>Invoice Number</p>
                <TextField
                  className="txtfld"
                  id="outlined-basic"
                  error={errors.invoice_number ? true : false}
                  variant="outlined"
                  fullWidth={true}
                  onChange={(e) => {
                    setinvoice_number(e.target.value.toUpperCase());
                  }}
                  label="Invoice Number"
                  value={invoice_number}
                  autoComplete="off"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <br />
                <TextField
                  id="date"
                  label="Purchase Date"
                  onChange={(e) => {
                    setpur_date(e.target.value);
                  }}
                  type="date"
                  value={pur_date}
                  //defaultValue={reliving_date}
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <br />
                {errors.pur_date && <OnLineErrors text={errors.pur_date} />}
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <p style={{ textAlign: "center" }}>HSN Code</p>
                <TextField
                  className="txtfld"
                  id="outlined-basic"
                  error={errors.hsn_code ? true : false}
                  variant="outlined"
                  fullWidth={true}
                  onChange={(e) => {
                    sethsn_code(e.target.value);
                  }}
                  value={hsn_code}
                  label="HSN Code"
                  autoComplete="off"
                />
              </Grid>
            </Grid>

            <Grid container spacing={5} className="mt-2">
              <Grid item xs={12} sm={6} md={3}>
                <p style={{ textAlign: "center" }}>Brand</p>
                <Select
                  value={brand_val}
                  onChange={(e) => {
                    setbrand_val(e);
                    setbrand(e.value);
                  }}
                  options={brandname_options}
                  placeholder={"Select Brand..."}
                  styles={customStyles_brand}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <p style={{ textAlign: "center" }}>Model Number</p>

                <Select
                  value={model_number_val}
                  onChange={(e) => {
                    setmodel_number_val(e);
                    setmodel_number(e.value);
                  }}
                  options={modelnumname_options}
                  placeholder={"Select Model Number..."}
                  styles={customStyles_model_num}
                  menuPortalTarget={document.body}
                // styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <p style={{ textAlign: "center" }}>Frame Type</p>
                <Select
                  value={frame_type}
                  onChange={(e) => {
                    setframe_type(e.value);
                  }}
                  options={optionsframetype}
                  placeholder={
                    frame_type.length > 0 ? frame_type : "Select Frame Type..."
                  }
                  styles={customStyles_frametype}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <p style={{ textAlign: "center" }}>Frame Color</p>
                <Select
                  value={frame_color_val}
                  onChange={(e) => {
                    setframe_color_val(e);
                    setframe_color(e.value);
                  }}
                  options={framecolorname_options}
                  placeholder={is_edit ? frame_color : "Select Frame Color..."}
                  styles={customStyles_frame_color}
                />
              </Grid>
            </Grid>

            <Grid container spacing={5} className="mt-2">
              <Grid item xs={12} sm={6} md={3}>
                <p style={{ textAlign: "center", zIndex: "1000" }}>
                  Frame Size
                </p>
                <Select
                  value={frame_size_val}
                  onChange={(e) => {
                    setframe_size_val(e);
                    setframe_size(e.value);
                  }}
                  options={options_framesize}
                  placeholder={is_edit ? frame_size : "Select Frame Size..."}
                  styles={customStyles_frame_size}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <p style={{ textAlign: "center" }}>Quantity</p>
                <TextField
                  className="txtfld"
                  id="outlined-basic"
                  error={errors.quantity ? true : false}
                  type="number"
                  variant="outlined"
                  label="Quantity"
                  fullWidth={true}
                  onChange={(e) => {
                    setquantity(e.target.value);
                  }}
                  value={quantity}
                  autoComplete="off"
                  style={{ zIndex: 1 }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter")
                      Submit();
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                {" "}
                <br /> <br />
                <Button variant="contained" color="primary" onClick={Submit}>
                  {" "}
                  {is_edit === false ? "Save" : "Update"}{" "}
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={12} style={{ marginTop: "1rem" }}>
          <ViewPurchase
            purchase={data}
            search={search}
            from_date={from_date}
            to_date={to_date}
            cp={currentPage}
            onHandleUpdate={handleUpdate}
            onHandleDelete={handleDelete}
            onHandleSearch={handleSearch}
            onHandleFromDate={(date) => {
              setfrom_date(moment(date).format("DD-MM-YYYY"));
            }}
            onHandleToDate={(date) => {
              setto_date(moment(date).format("DD-MM-YYYY"));
            }}
            onHandleSearchDate={handleSerachdate}
          />
          <br /> <br />
          {search.length > 0 || from_date.length > 0 ? null : (
            <>
              {/* <div className="pageNumbers">
                <button
                  onClick={handlePrevbtn}
                  disabled={currentPage == pages[0] ? true : false}
                >
                  {" "}
                  &lt;&lt;
                </button>
                {pageDecrementBtn}
                {renderPageNumbers}
                {pageIncrementBtn}
                <button
                  onClick={handleNextbtn}
                  disabled={
                    currentPage == pages[pages.length - 1] ? true : false
                  }
                >
                  {" "}
                  &gt;&gt;
                </button>
              </div> */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "20px 50px",
                }}
              >
                <button onClick={handleLoadMore} className="loadmore">
                  Load More
                </button>
              </div>
            </>
          )}
        </Grid>
      </Grid>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },

  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

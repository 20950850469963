export const getAddSupplierMasterReducer = (state = {}, action) => {
  switch (action.type) {
    case "ADD_SUPPLIERMASTER_REQUEST":
      return {
        loading: true,
        ...state,
      };
    case "ADD_SUPPLIERMASTER_SUCCESS":
      return {
        loading: false,
        success: true,
      };
    case "ADD_SUPPLIERMASTER_FAILED":
      return {
        loading: false,
        error: action.payload,
      };

    case "ALL_SUPPLIERMASTER_CLEAR":
      return {
        loading: false,
        success: false,
      };

    default:
      return state;
  }
};

export const getAllSupplierMasterReducer = (state = { seller: [] }, action) => {
  switch (action.type) {
    case "GET_SUPPLIERMASTER_REQUEST":
      return {
        loading: true,
        ...state,
      };
    case "GET_SUPPLIERMASTER_SUCCESS":
      return {
        loading: false,
        seller: action.payload,
      };
    case "GET_SUPPLIERMASTER_FAILED":
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const getAllCompanyNamesReducer = (
  state = { company_names: [] },
  action
) => {
  switch (action.type) {
    case "GET_CNAME_REQUEST":
      return {
        loading: true,
        ...state,
      };
    case "GET_CNAME_SUCCESS":
      return {
        loading: false,
        company_names: action.payload,
      };
    case "GET_CNAME_FAILED":
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

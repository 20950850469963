import React from "react";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import jwt_decode from "jwt-decode";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { logoutUser } from "../redux/actions/userActions";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { useHistory, Link, useLocation, NavLink } from "react-router-dom";
import Avatar from "@material-ui/core/Avatar";

import BannerMenu from "./Menus/bannerMenu";

const drawerWidth = 175;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

export default function MiniDrawer(props) {
  const userState = useSelector((state) => state.loginUserReducer);
  const { currentUser } = userState;

  const classes = useStyles();
  const theme = useTheme();

  const [open, setOpen] = React.useState(false);

  const history = useHistory();
  const dispatch = useDispatch();
  const logOut = () => {
    dispatch(logoutUser()).then(() => {
      // dispatch({ type: "USER_LOGOUT", payload: "" });
      // dispatch({ type: "GET_PURCHASE_SUCCESS", payload: "" });

      //window.location.href="/login"

      history.push("/login");
    });
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        style={{ backgroundColor: props.bgcolor }}
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            {props.title}
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <List>
          <ListItem>
            <ListItemIcon>
              {" "}
              <Avatar style={{ backgroundColor: "#d63031" }}>
                <NavLink to="/est" smooth="true" style={{ color: "white" }}>
                  <h6 style={{ marginTop: "0.5rem" }}>Est</h6>
                </NavLink>
              </Avatar>{" "}
            </ListItemIcon>
            <NavLink to="/est" smooth="true">
              {" "}
              <h5>
                <span style={{ color: "#d63031" }}> Est</span>
              </h5>{" "}
            </NavLink>
          </ListItem>
          <br />
          <ListItem>
            <ListItemIcon>
              {" "}
              <Avatar style={{ backgroundColor: "#ffa801" }}>
                <NavLink to="/job" smooth="true" style={{ color: "white" }}>
                  <h6 style={{ marginTop: "0.5rem" }}>JOB</h6>
                </NavLink>
              </Avatar>{" "}
            </ListItemIcon>
            <NavLink to="/job" smooth="true">
              {" "}
              <h5>
                <span style={{ color: "#ffa801" }}> Job Work</span>
              </h5>{" "}
            </NavLink>
          </ListItem>

          <br />
          <ListItem>
            <ListItemIcon>
              {" "}
              <Avatar style={{ backgroundColor: "#00b894" }}>
                <NavLink to="/bills" smooth="true" style={{ color: "white" }}>
                  <h6 style={{ marginTop: "0.5rem" }}>BILL</h6>
                </NavLink>
              </Avatar>{" "}
            </ListItemIcon>
            <NavLink to="/bills" smooth="true">
              {" "}
              <h5>
                <span style={{ color: "#00b894" }}> Bills</span>
              </h5>{" "}
            </NavLink>
          </ListItem>

          <br />
          <ListItem>
            <ListItemIcon>
              {" "}
              <Avatar style={{ backgroundColor: "#1e90ff" }}>
                <NavLink
                  to="/products"
                  smooth="true"
                  style={{ color: "white" }}
                >
                  <h6 style={{ marginTop: "0.5rem" }}>PUR</h6>
                </NavLink>
              </Avatar>{" "}
            </ListItemIcon>
            <NavLink to="/products" smooth="true">
              {" "}
              <h5>
                <span style={{ color: "#1e90ff" }}> Purchase</span>
              </h5>{" "}
            </NavLink>
          </ListItem>
          <br />
          <br />
          <Divider />
          <BannerMenu />
          <br />
          <br />


          {jwt_decode(userState.currentUser).role == "admin" ? (

            <ListItem>
              <ListItemIcon>
                {" "}
                <Avatar style={{ backgroundColor: "#303952" }}>
                  <NavLink to="/sales" smooth="true" style={{ color: "white" }}>
                    <h6 style={{ marginTop: "0.5rem" }}>SAL</h6>
                  </NavLink>
                </Avatar>{" "}
              </ListItemIcon>
              <NavLink to="/sales" smooth="true">
                {" "}
                <h5>
                  <span style={{ color: "#303952" }}> Sales</span>
                </h5>{" "}
              </NavLink>
            </ListItem>
          ) : (
            " "
          )}
          {/* 

          <ListItem>
            <ListItemIcon>
              {" "}
              <Avatar style={{ backgroundColor: "#6ab04c" }}>
                <NavLink to="/sales" smooth="true" style={{ color: "white" }}>
                  <h6 style={{ marginTop: "0.5rem" }}>SAL</h6>
                </NavLink>
              </Avatar>{" "}
            </ListItemIcon>
            <NavLink to="/sales" smooth="true">
              {" "}
              <h5>
                <span style={{ color: "#6ab04c" }}> Sales</span>
              </h5>{" "}
            </NavLink>
          </ListItem> */}




          <br />

          {jwt_decode(userState.currentUser).role == "admin" ? (
            <ListItem>
              <ListItemIcon>
                {" "}
                <Avatar style={{ backgroundColor: "#0a3d62" }}>
                  <NavLink
                    to="/admin-purchase"
                    smooth="true"
                    style={{ color: "white" }}
                  >
                    <h6 style={{ marginTop: "0.5rem" }}>Adm</h6>
                  </NavLink>
                </Avatar>{" "}
              </ListItemIcon>
              <NavLink to="/admin-purchase" smooth="true">
                {" "}
                <h5>
                  <span style={{ color: "#0a3d62" }}> Admin</span>
                </h5>{" "}
              </NavLink>
            </ListItem>
          ) : (
            " "
          )}


          {/* <ListItem>
            <ListItemIcon>
              {" "}
              <Avatar style={{ backgroundColor: "#0a3d62" }}>
                <NavLink
                  to="/admin-purchase"
                  smooth="true"
                  style={{ color: "white" }}
                >
                  <h6 style={{ marginTop: "0.5rem" }}>Rep</h6>
                </NavLink>
              </Avatar>{" "}
            </ListItemIcon>
            <NavLink to="/admin-purchase" smooth="true">
              {" "}
              <h5>
                <span style={{ color: "#0a3d62" }}> Report</span>
              </h5>{" "}
            </NavLink>
          </ListItem> */}
        </List>

        <Divider />

        {/* {jwt_decode(userState.currentUser).role == "admin" ? (
            <NavLink to="/mrp-fixing" smooth="true">
              <h6 style={{ color: "white" }}>MRP Fixing</h6>
            </NavLink>
          ) : (
            " "
          )} */}


        <List className="mt-5">
          <ListItem>
            <ListItemIcon>
              {" "}
              <ExitToAppIcon
                onClick={logOut}
                to="/#"
                smooth="true"
                fontSize="large"
                color="secondary"
                style={{ cursor: "default" }}
              ></ExitToAppIcon>
            </ListItemIcon>
            <NavLink onClick={logOut} to="/#" smooth="true">
              {" "}
              <h6 color="secondary">Log Out</h6>{" "}
            </NavLink>
          </ListItem>
        </List>
      </Drawer>
    </div>
  );
}

//  <NavLink onClick={logOut} to="/#"  smooth="true" duration={500}> <h4>LogOut</h4> </NavLink>

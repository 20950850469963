import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Box, MenuItem, InputLabel } from "@mui/material";
import {
  TextField,
  Button,
  Grid,
  Paper,
  FormControl,
  FormHelperText,
} from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import { makeStyles } from "@material-ui/core/styles";
import * as moment from "moment";
import AdminNavbar from "../../components/AdminNavbar/AdminNavbar";
import Sidebar from "../../components/Sidebar";
import { useDispatch, useSelector } from "react-redux";
import jwt_decode from "jwt-decode";
import Select from "react-select";
import ViewEst from "./ViewEst";
import ViewPurchase from "./ViewPurchase";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";

import {
  addPurchase,
  deletePurchase,
  updatePurchase,
  getAllPurchase,
  getAllPurchaseLength,
  allPurchaseClear,
  searchPurchase,
  filterPurchase,
  updatePurchaseQuantity,
  deletePurchaseQuantity,
} from "../../redux/actions/purchaseAction";

import {
  addEst,
  deleteEst,
  updateEst,
  getAllEst,
  getAllEstbycus,
  getAllEstLength,
  allEstClear,
  searchEst,
  filterEst,
  CheckedBillEst,
  updateEstStatus,
} from "../../redux/actions/estAction";

import { Circles } from 'react-loader-spinner'
import Loading from "../../components/Loading";
import Error from "../../components/Error";
import OnLineErrors from "../../components/OnLineErrors";
import Success from "../../components/Success";
import "./pagestyle.css";
import "./printest.css";
import { useReactToPrint } from "react-to-print";
import Modal from "react-modal";
import logo from "../../assets/logo.png";

var datetime = new Date();
var tdy_date = datetime
  .toISOString()
  .slice(0, 10)
  .split("-")
  .reverse()
  .join("-");
var t_date = datetime.toISOString().slice(0, 10).split("-").join("-");

export default function Est() {
  const classes = useStyles();
  const [is_loading, setis_loding] = useState(false);
  const [is_edit, setis_edit] = useState(false);
  const [id, setid] = useState("");
  const [brand, setbrand] = useState("");

  const [quantity, setquantity] = useState("");
  const [maxquantity, setmaxquantity] = useState("");
  const [frame_rate, setframe_rate] = useState("");
  const [advance, setadvance] = useState("");
  const [amount, setamount] = useState("");
  const [discount, setdiscount] = useState(0);
  const [adv_pay_mode, setadv_pay_mode] = useState("");
  const [adv_pay_mode_val, setadv_pay_mode_val] = useState("");
  const [model_number, setmodel_number] = useState("");
  const [frame_color, setframe_color] = useState("");
  const [frame_size, setframe_size] = useState("");
  const [frame_type, setframe_type] = useState("");
  const [lens_rate, setlens_rate] = useState();
  const [status, setstatus] = useState("");
  const [status_val, setstatus_val] = useState("");

  const [errors, seterrors] = useState([]);
  const [search, setsearch] = useState("");
  const [tot, setTot] = useState(0);
  const [sales_tot, setsales_tot] = useState(0);
  const [from_date, setfrom_date] = useState("");
  const [to_date, setto_date] = useState("");

  const [open, setOpen] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("sm");

  const handleClose = () => {
    setOpen(false);
  };

  const optionsframe_type = [
    { value: "Shell", label: "Shell" },
    { value: "Supra", label: "Supra" },
    { value: "3 pc", label: "3 pc" },
    { value: "Metal frame full", label: "Metal frame full" },
    { value: "Metal frame half", label: "Metal frame half" },
    { value: "Reading glass", label: "Reading glass" },
  ];
  const status_options = [
    { value: "jobwork", label: "jobwork" },
  ];
  const optionspm = [
    { value: "Cash", label: "Cash" },
    { value: "G-Pay", label: "G-Pay" },
    { value: "DebitCard", label: "Debit Card" },
    { value: "CreditCard", label: "Credit Card" },
    { value: "Upi", label: "Upi" },
  ];
  const customStyles = {
    content: {
      width: "50%",
      height: "90%",
      left: "25%",
      backgroundColor: "#FFF",
    },

    control: (base) => ({
      ...base,
      borderColor: errors.base === "*" ? "red" : "gray",
    }),
  };

  const userState = useSelector((state) => state.loginUserReducer);
  const { currentUser } = userState;

  const add_purchase_state = useSelector(
    (state) => state.getAddPurchaseReducer
  );
  const { success, error, loading } = add_purchase_state;

  const all_purchase_state = useSelector(
    (state) => state.getAllPurchaseReducer
  );
  const { purchase } = all_purchase_state;


  const all_est_state = useSelector((state) => state.getAllEstReducer);
  const { est } = all_est_state;

  //console.log("------------------EST------------------", est);

  useEffect(() => {
    handleTodayDate();
    if (currentUser.length > 0) {
      var decoded = jwt_decode(userState.currentUser);
      //console.log('-------#####--------',decoded.role)
      //console.log('-------*****--------',((decoded.role !== 'admin') && (decoded.role !== 'nesi')))
      if (decoded.role !== "admin" && decoded.role !== "nesi") {
        window.location.href = "/login";
      }
    } else {
      window.location.href = "/login";
    }
    setpro_data(purchase);
    setData(est);

    // dispatch(allEstClear());
    dispatch(getAllEstLength()).then((response) => {
      setTot(response.response.data.results[0].tot);
    });

    dispatch(getAllEst(1, itemsPerPage));

    dispatch(allPurchaseClear());
    //  dispatch(getAllPurchaseLength()).then((response)=>{
    //   setTot(response.response.data.results[0].tot)
    //  })
    // dispatch(getAllPurchase(1, 1));
  }, []);


  //console.log('=================', lens_rate)

  //console.log('======== -- TOT -- =========', tot)


  useEffect(() => {
    setData(est);
  }, [est]);

  useEffect(() => {
    setpro_data(purchase);
  }, [purchase]);

  useEffect(() => {
    setTot(tot);
  }, [tot]);

  useEffect(() => {
    if (search.length < 1) {
      dispatch(getAllPurchase(currentPage, 1));
    }
  }, [search]);

  const dispatch = useDispatch();

  const handleTodayDate = () => {
    let datetime = new Date();
    let tdy_date = datetime.toISOString().slice(0, 10);

    setfrom_date(moment(tdy_date).format("DD-MM-YYYY"));
    setto_date(moment(tdy_date).format("DD-MM-YYYY"));
  };

  const handleClear = () => {
    // console.log('------clear-------')
    setis_edit(false);
    setbrand("");
    setmodel_number("");

    setquantity("");
    setmaxquantity("");
    setframe_rate("");
    setlens_rate("");
    setframe_type("");

    setcus_name("");
    setphone("");
    setplace("");
    setadvance("");
    setdiscount("");
  };


  const onChangePhone = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      //this.setState({value: e.target.value})
      setphone(e.target.value);
      if (e.target.value.length > 10) {
        errors.phone = true;
      } else {
        errors.phone = false;
      }
    }
  };

  const handleDelete = (sal) => {
    console.log("@@@@", sal);

    var option = window.confirm(`Are You Sure want to delete  ${sal.brand}`);
    if (option) {
      // dispatch(deleteEst(sal.id)).then((res) => {
      //   dispatch(deletePurchaseQuantity(sal)).then((res) => {
      //     dispatch(getAllEst(1, itemsPerPage, cus_name, phone));
      //     dispatch(getAllPurchase(currentPage, 10));
      //     handleClear();
      //   });
      // });

      dispatch(deletePurchaseQuantity(sal))
        .then((res) => {
          dispatch(deleteEst(sal.id)).then((res) => {
            dispatch(getAllEst(1, itemsPerPage, cus_name, phone));
            dispatch(getAllPurchase(currentPage, 10));
            handleClear();
          });
        })
        .catch((err) => {
          console.log("--EROOR--", err);
        });
    }
  };

  const handleSwitchChange = (sal, new_status) => {
    console.log("+++++tar++++", sal.id, new_status);

    const data = { from_date, to_date, cus_name };
    dispatch(CheckedBillEst(sal.id, new_status)).then((res) => {
      dispatch(filterEst(data))
        .then((res) => {
          console.log("------ RP ------", res.response.data.results);
          setData(res.response.data.results);
        })
        .catch((err) => {
          console.log("------ ERR ------", err);
          //dispatch(getAllBillService(1,itemsPerPage, cus_name, phone))
        });
    });
  };

  const handleSearch = (val) => {
    //setfrom_date('');
    //setto_date('');
    setsearch(val);
    if (search.length > 0) {
      dispatch(searchEst(val))
        .then((res) => {
          setData(res.response.data.results);
        })
        .catch((err) => {
          setsearch("");
          dispatch(getAllEst(1, itemsPerPage, cus_name, phone));
        });
    }
  };

  const handleSearchdate = () => {
    if (from_date !== "" && to_date !== "") {
      setsearch("");
      if (cus_name === "") {
        setphone("**");
      }
      const data = { from_date, to_date };
      dispatch(filterEst(data))
        .then((res) => {
          console.log("------ RP ------", res.response.data.results);
          setData(res.response.data.results);
        })
        .catch((err) => {
          dispatch(getAllEst(1, itemsPerPage));
        });
    } else {
      alert("Select date");
    }
  };

  const Bill = () => {
    console.log("FFFFFFFFF", from_date);

    if (cus_name.length > 0) {
      dispatch(getAllEstbycus(1, itemsPerPage, cus_name, phone, from_date))
        .then((res) => {
          console.log("&&&&&======BILL DATA =======&&&&", res);
        })
        .then(() => {
          openModal();
          handlePrint();
          closeModal();
        });
    } else {
      alert("Customer Name Required");
    }
  };

  const Submit = () => {
    const user_data = {
      id,
      cus_name,
      phone,
      place,
      brand,
      quantity,
      maxquantity,
      frame_rate,
      model_number,
      frame_color,
      frame_size,
      frame_type,
      lens_rate,
      advance,
      amount: (((frame_rate + lens_rate) * quantity) - discount),
      discount,
      adv_pay_mode,
      tot
    };
    console.log("000000", user_data);
    const errors = validate(user_data);
    seterrors(errors);
    console.log("@@@@@", errors);
    if (Object.keys(errors).length === 0) {
      if (!is_edit) {
        setis_loding(true);


        dispatch(addEst(user_data)).then((res) => {
          dispatch(getAllEst(1, itemsPerPage));
          dispatch(updatePurchaseQuantity(user_data)).then((res) => {
            dispatch(getAllPurchase(1, 1));
            handleClear();
          });

          dispatch(getAllEstLength()).then((response) => {
            setTot(response.response.data.results[0].tot);
          });

          alert(res.response.data.msg)
          setis_loding(false);
        });



      } else {
        dispatch(updateEst(user_data)).then((res) => {
          //console.log('!!!!!!',res.response.data.success)
          setis_edit(false);
          handleClear();
          dispatch(getAllEst(1, itemsPerPage));
          //dispatch(getAllEst(currentPage, itemsPerPage, cus_name, phone));
          if (res.response.data.success) {
            alert("Updated");
          }
        });
      }
    } else {
      // console.log('@#@#@#@#',errors)
    }
  };

  const UpdateStatus = () => {
    const user_data = {
      id,
      status,
    };
    console.log("000000", user_data);
    // const errors = validate(user_data);
    // seterrors(errors);
    console.log("@@@@@", errors);
    if (status.length > 0) {
      dispatch(updateEstStatus(user_data)).then((res) => {
        //console.log('!!!!!!',res.response.data.success)

        handleClear();
        handleClose();

        dispatch(getAllEst(currentPage, itemsPerPage));
        if (res.response.data.success) {
          alert("Updated");
        }
      });
    } else {
      alert("please select");
      // console.log('@#@#@#@#',errors)
    }
  };

  const validate = (data) => {
    const errors = {};

    if (!data.cus_name || data.cus_name === "*") {
      errors.cus_name = "*";
    }
    // if (!data.phone || data.phone === "*") {
    //   errors.phone = "*";
    // }
    if (!data.phone || data.phone.length > 10) {
      errors.phone = true;
    }
    if (!data.place) {
      errors.place = "*";
    }
    if (!data.brand) {
      errors.brand = "*";
    }
    if (!data.model_number) {
      errors.model_number = "*";
    }
    if (!data.quantity) {
      errors.quantity = "*";
    }
    if (!data.frame_rate) {
      errors.frame_rate = "*";
    }
    if (!data.frame_type) {
      errors.frame_type = "*";
    }
    if (!data.lens_rate) {
      errors.lens_rate = "*";
    }

    if (!data.amount) {
      errors.amount = "*";
    }

    if (!data.advance) {
      errors.advance = "*";
    }

    return errors;
  };

  // -------------- CUSTOMER Start------------- //

  const [cus_name, setcus_name] = useState("");
  const [phone, setphone] = useState("");
  const [place, setplace] = useState("Karur");

  // --------------  CUSTOMER End  -----------------//

  // --------------  Products Start ------------- //
  const [pro_data, setpro_data] = useState([]);
  const [pro_search, setpro_search] = useState("");

  const handleSearchProduct = (val) => {
    setpro_search(val);
    console.log("#####", pro_search);
    if (pro_search != "") {
      dispatch(searchPurchase(val))
        .then((res) => {
          setpro_data(res.response.data.results);
        })
        .catch((err) => {
          setsearch("");
          dispatch(getAllPurchase(1, 10));
        });
    }
  };

  const handleEditProduct = (pur) => {
    setid(pur.id);
    // setpur_date(moment(pur.pur_date).format("DD-MM-YYYY"))
    setbrand(pur.brand);
    setmodel_number(pur.model_number);
    setframe_color(pur.frame_color);
    setframe_size(pur.frame_size);

    setquantity(0);
    setmaxquantity(pur.quantity);
    setframe_rate(pur.frame_rate);
    setframe_type(pur.frame_type);
    window.scrollTo({
      top: 100,
      behavior: "smooth",
    });
  };

  // const handleEditEst = (sal) => {
  //   console.log("@@@@*****@@@@", sal);
  //   setis_edit(true);
  //   setid(sal.id);
  //   setcus_name(sal.cus_name);
  //   setphone(sal.phone);
  //   setplace(sal.place);

  //   setadv_pay_mode(sal.adv_pay_mode);
  //   setadv_pay_mode_val({ label: sal.adv_pay_mode, value: sal.adv_pay_mode });

  //   window.scrollTo({
  //     top: 100,
  //     behavior: "smooth",
  //   });
  // };

  const handleStatusChange = (sal) => {
    console.log("@@@@*****@@@@", sal);
    setid(sal.id);
    setcus_name(sal.cus_name);
    setphone(sal.phone);
    setplace(sal.place);
    setstatus(sal.status);
    setstatus_val({ label: sal.status, value: sal.status });

    window.scrollTo({
      top: 100,
      behavior: "smooth",
    });

    setOpen(true);
  };
  // --------------  Products End  -----------------//

  // ------------- Pagination start---------- //
  const [currentPage, setcurrentPage] = useState(1);
  const [itemsPerPage, setitemsPerPage] = useState(25);

  const [pageNumberLimit, setpageNumberLimit] = useState(2);
  const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(2);
  const [minPageNumberLimit, setminPageNumberLimit] = useState(0);

  const [data, setData] = useState([]);

  const handleClick = (event) => {
    setcurrentPage(Number(event.target.id));
    dispatch(getAllEst(Number(event.target.id), itemsPerPage));
  };

  const pages = [];
  for (let i = 1; i <= Math.ceil(tot / itemsPerPage); i++) {
    pages.push(i);
  }

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  //const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  useEffect(() => {
    dispatch(getAllEst(currentPage, itemsPerPage));
  }, [currentPage, itemsPerPage]);

  const handleNextbtn = () => {
    setcurrentPage(currentPage + 1);
    // dispatch(getAllEst(currentPage))
    if (currentPage + 1 > maxPageNumberLimit) {
      setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
  };

  const handlePrevbtn = () => {
    setcurrentPage(currentPage - 1);
    if ((currentPage - 1) % pageNumberLimit == 0) {
      setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
    }
  };

  let pageIncrementBtn = null;
  if (pages.length > maxPageNumberLimit) {
    pageIncrementBtn = <li onClick={handleNextbtn}> &hellip;+ </li>;
  }

  let pageDecrementBtn = null;
  if (minPageNumberLimit >= 1) {
    pageDecrementBtn = <li onClick={handlePrevbtn}> &hellip;- </li>;
  }

  const renderPageNumbers = pages.map((number) => {
    if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
      return (
        <li
          key={number}
          id={number}
          onClick={handleClick}
          className={currentPage == number ? "active" : null}
        >
          {number}
        </li>
      );
    } else {
      return null;
    }
  });

  const handleLoadMore = () => {
    setitemsPerPage(itemsPerPage + 100);
    setmaxPageNumberLimit(5);
    setminPageNumberLimit(0);
    setcurrentPage(1);
  };

  // ------------- Pagination end---------- //

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#f00";
  }

  function closeModal() {
    setIsOpen(false);
  }

  // var subtotal = est.reduce(
  //   (x, sal) => x + sal.frame_rate * sal.quantity,
  //   0
  // );

  var subtotal = est.reduce(
    (x, sal) => x + (sal.frame_rate + sal.lens_rate) * sal.quantity,
    0
  );



  // console.log('++++ is_loading +++++', is_loading)
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={1}>
          <Paper className={classes.paper}>
            <Sidebar title="Estimation" bgcolor="#e34d4e" />
          </Paper>
        </Grid>

        <Grid item xs={11} style={{ marginTop: "5rem" }}>
          <Paper className={classes.paper}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={12}>

                <ViewPurchase
                  purchase={pro_data}
                  search={pro_search}
                  from_date={from_date}
                  to_date={to_date}
                  //cp={currentPage}
                  onHandleEditProduct={handleEditProduct}
                  onHandleSearchProduct={handleSearchProduct}
                />

              </Grid>
            </Grid>

            <Grid container spacing={2} style={{ marginTop: "3rem" }}>
              <Grid item xs={12} sm={4} md={2}>
                {" "}
                <TextField
                  className="txtfld"
                  id="outlined-basic"
                  error={errors.brand ? true : false}
                  variant="outlined"
                  fullWidth={false}
                  onChange={(e) => {
                    setbrand(e.target.value);
                  }}
                  label="Brand"
                  value={brand || ""}
                  autoComplete="off"
                />
              </Grid>
              <Grid item xs={12} sm={4} md={2}>
                {" "}
                <TextField
                  className="txtfld"
                  id="outlined-basic"
                  error={errors.model_number ? true : false}
                  variant="outlined"
                  fullWidth={false}
                  onChange={(e) => {
                    setmodel_number(e.target.value);
                  }}
                  value={model_number || ""}
                  label="Model Number"
                  autoComplete="off"
                />
              </Grid>
              <Grid item xs={12} sm={4} md={2}>
                <TextField
                  className="txtfld"
                  id="outlined-basic"
                  error={errors.frame_type ? true : false}
                  variant="outlined"
                  fullWidth={false}
                  onChange={(e) => {
                    setframe_type(e.target.value);
                  }}
                  label="Frame Type"
                  value={frame_type || ""}
                  autoComplete="off"
                />
              </Grid>
              <Grid item xs={12} sm={4} md={2}>
                {" "}
                <TextField
                  className="txtfld"
                  id="outlined-basic"
                  error={errors.frame_rate ? true : false}
                  type="number"
                  variant="outlined"
                  fullWidth={false}
                  // onChange={(e) => {setframe_rate(e.target.value)}}
                  value={frame_rate || ""}
                  label="Frame Rate"
                  autoComplete="off"
                />
              </Grid>
              <Grid item xs={12} sm={4} md={2}>
                {" "}
                <TextField
                  className="txtfld"
                  id="outlined-basic"
                  error={errors.cus_name ? true : false}
                  variant="outlined"
                  fullWidth={false}
                  onChange={(e) => {
                    setcus_name(e.target.value.toUpperCase());
                  }}
                  label="Patient Name"
                  value={cus_name || ""}
                  autoComplete="off"
                />
              </Grid>
              <Grid item xs={12} sm={4} md={2}>
                {" "}
                <TextField
                  className="txtfld"
                  id="outlined-basic"
                  error={errors.phone ? true : false}
                  variant="outlined"
                  fullWidth={false}
                  // onChange={(e) => {
                  //   setphone(e.target.value);
                  // }}
                  onChange={(e) => onChangePhone(e)}
                  value={phone || ""}
                  label="Mobile Number"
                  autoComplete="off"
                />
              </Grid>
            </Grid>

            <Grid container spacing={2} className="mt-4">
              <Grid item xs={12} sm={4} md={2}>
                {" "}
                <TextField
                  className="txtfld"
                  id="outlined-basic"
                  error={errors.place ? true : false}
                  variant="outlined"
                  fullWidth={false}
                  onChange={(e) => {
                    setplace(e.target.value);
                  }}
                  label="Place"
                  value={place || ""}
                  autoComplete="off"
                />
              </Grid>
              <Grid item xs={12} sm={4} md={2}>
                {" "}
                <TextField
                  className="txtfld"
                  id="outlined-basic"
                  type="number"
                  error={errors.lens_rate ? true : false}
                  variant="outlined"
                  fullWidth={false}
                  onChange={(e) => {
                    // setlens_rate(parseFloat(e.target.value));
                    setlens_rate(parseInt(e.target.value));
                  }}

                  label="Lens Rate"
                  value={lens_rate || ''}
                  autoComplete="off"
                />
              </Grid>
              <Grid item xs={12} sm={4} md={2}>
                {" "}
                <TextField
                  className="txtfld"
                  id="outlined-basic"
                  type="number"
                  error={errors.quantity ? true : false}
                  variant="outlined"
                  fullWidth={false}
                  onChange={(e) => {
                    setquantity(
                      e.target.value <= maxquantity && e.target.value > 0
                        ? e.target.value
                        : ""
                    );
                  }}
                  value={quantity || ""}
                  label="Quantity"
                  autoComplete="off"
                />
              </Grid>

              <Grid item xs={12} sm={4} md={2}>
                {" "}
                <TextField
                  className="txtfld"
                  id="outlined-basic"
                  error={errors.amount ? true : false}
                  type="number"
                  variant="outlined"
                  label="Amount"
                  fullWidth={false}
                  // onChange={(e) => {setamount(e.target.value)}}
                  value={((frame_rate + lens_rate) * quantity) - discount || ""}
                  autoComplete="off"
                />
              </Grid>
              <Grid item xs={12} sm={4} md={1}>
                {" "}
                <TextField
                  className="txtfld"
                  id="outlined-basic"
                  error={errors.advance ? true : false}
                  type="number"
                  variant="outlined"
                  label="Advance"
                  fullWidth={false}
                  onChange={(e) => {
                    setadvance(e.target.value);
                  }}
                  value={advance || ""}
                  autoComplete="off"
                />
              </Grid>
              <Grid item xs={12} sm={4} md={1}>
                {" "}
                <TextField
                  className="txtfld"
                  id="outlined-basic"
                  // error={errors.discount ? true : false}
                  type="number"
                  variant="outlined"
                  label="Discount"
                  fullWidth={false}
                  onChange={(e) => {
                    setdiscount(e.target.value);
                  }}
                  value={discount || ""}
                  autoComplete="off"
                />
              </Grid>

              <Grid item xs={12} sm={4} md={2}>
                {" "}
                <div
                  style={{
                    width: "90%",
                    paddingLeft: "1.3rem",
                  }}
                >
                  <Select
                    value={adv_pay_mode_val || ""}
                    onChange={(e) => {
                      setadv_pay_mode(e.value);
                    }}
                    options={optionspm}
                    placeholder={
                      adv_pay_mode.length > 0
                        ? adv_pay_mode
                        : "Advance Pay Mode..."
                    }
                    styles={customStyles}
                  // onKeyDown={(e) => {
                  //   if (e.key === "Enter")
                  //     Submit();
                  // }}
                  />
                </div>
              </Grid>
            </Grid>

            <Grid container spacing={5} className="mt-4">
              <Grid item xs={12} sm={6} md={3}></Grid>

              <Grid item xs={12} sm={6} md={3}>
                {" "}
                <Button variant="contained" color="primary"
                  disabled={is_loading === true ? true : false}
                  onClick={Submit}>
                  {" "}
                  {/* {is_edit === false ? "Save" : "Update"}{" "} */}
                  {is_loading === false ? "Save" : <Circles
                    height="30"
                    width="60"
                    color="#d63031"
                    ariaLabel="circles-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                  />}

                </Button>
              </Grid>

              <Grid item xs={12} sm={6} md={3}></Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>

      {/*  */}
      <Grid>
        <React.Fragment>
          <Dialog
            fullWidth={fullWidth}
            maxWidth={maxWidth}
            open={open}
            onClose={handleClose}
            aria-labelledby="max-width-dialog-title"
          >
            <DialogTitle id="max-width-dialog-title">
              {cus_name} {place}
            </DialogTitle>
            <DialogContent>
              <>
                <Grid container spacing={3}>
                  <Grid item xs={1}></Grid>

                  <Grid item xs={10}>
                    <Paper className={classes.paper}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} md={4}></Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <Box>
                            <FormControl className={classes.formControl}>
                              {/* <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={status}
                                label="Status"
                                error={errors.status ? true : false}
                                onChange={(e) => {
                                  setStatus(e.target.value);
                                }}
                              >
                                <MenuItem value={"estimation"}>
                                  Estimation
                                </MenuItem>
                                <MenuItem value={"jobwork"}>Job Work</MenuItem>
                                <MenuItem value={"sales"}>Sales</MenuItem>
                              </Select> */}
                              <Select
                                value={status_val}
                                onChange={(e) => {
                                  setstatus_val(e);
                                  setstatus(e.value);
                                }}
                                options={status_options}
                                placeholder={"Search..."}
                                styles={customStyles}
                              />
                              <FormHelperText>Status</FormHelperText>
                            </FormControl>
                          </Box>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}></Grid>
                      </Grid>

                      <Grid container spacing={3} className="mt-5">
                        <Grid item xs={12} sm={4}></Grid>
                        <Grid item xs={12} sm={4}>
                          <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            startIcon={<SaveIcon />}
                            onClick={UpdateStatus}
                          >
                            Update
                          </Button>
                        </Grid>
                        <Grid item xs={12} sm={4}></Grid>
                      </Grid>
                    </Paper>

                    <Grid className="mt-3"></Grid>
                  </Grid>

                  <Grid item sm={1}></Grid>
                </Grid>
              </>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </React.Fragment>
      </Grid>
      {/*  */}

      <Grid item xs={12} style={{ marginTop: "3rem" }}>


        <ViewEst
          est={data}
          search={search}
          from_date={from_date}
          to_date={to_date}
          cp={currentPage}
          // onHandleUpdate={handleUpdate}
          onHandleDelete={handleDelete}
          onHandleSearch={handleSearch}
          onHandleFromDate={(date) => {
            setfrom_date(moment(date).format("DD-MM-YYYY"));
          }}
          onHandleToDate={(date) => {
            setto_date(moment(date).format("DD-MM-YYYY"));
          }}
          onHandleSearchDate={handleSearchdate}
          onBillFetch={Bill}
          onHandleSwitchChange={handleSwitchChange}
          // onHandleEditChange={handleEditEst}
          onHandleStatusChange={handleStatusChange}
        />

        <br /> <br />
        {/* {cus_name.length > 1 || search.length > 0 || phone === "**" ? null : ( */}
        {search.length > 0 ? null : (
          <>
            <div className="pageNumbers">
              <button
                onClick={handlePrevbtn}
                disabled={currentPage == pages[0] ? true : false}
              >
                {" "}
                &lt;&lt;
              </button>
              {pageDecrementBtn}
              {renderPageNumbers}
              {pageIncrementBtn}
              <button
                onClick={handleNextbtn}
                disabled={currentPage == pages[pages.length - 1] ? true : false}
              >
                {" "}
                &gt;&gt;
              </button>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "20px 50px",
              }}
            >
              <button onClick={handleLoadMore} className="loadmore">
                Load More
              </button>
            </div>
          </>
        )}
      </Grid>

      {/*  */}
      <div style={{ textAlign: "center" }}>
        {/* <button onClick={openModal}>Open Modal</button> */}
        <Modal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
          ariaHideApp={false}
        >
          <div
            className="row"
            style={{ backgroundColor: "#ffecf2", marginTop: "-1rem" }}
          >
            <div className="col-print-6">
              <h2
                ref={(_subtitle) => (subtitle = _subtitle)}
                onClick={handlePrint}
                style={{ textAlign: "left", cursor: "pointer" }}
              >
                Print
              </h2>
            </div>
            <div className="col-print-6">
              <button
                onClick={closeModal}
                style={{
                  float: "right",
                  backgroundColor: "#ffecf2",
                  fontSize: "2.5rem",
                }}
              >
                X
              </button>
            </div>
          </div>
          <>
            <style>{getPageMargins()}</style>

            <div ref={componentRef}>
              <div
                className="row"
                style={{ textAlign: "left", marginTop: "3rem" }}
              >
                <div className="col-print-1"></div>
                <div className="col-print-11">
                  <div className="row">
                    <div className="col-print-0"> </div>
                    <div className="col-print-9">
                      {/* <h2 style={{textAlign:'left'}}>Logo</h2>   */}
                      <img
                        src={logo}
                        style={{
                          textAlign: "left",
                          height: "5rem",
                          width: "8rem",
                        }}
                      />
                    </div>
                    <div className="col-print-3">
                      <h2 style={{ textAlign: "left", marginTop: "3rem" }}>
                        BILL
                      </h2>
                      {/* <p>{bill_num}</p> */}
                    </div>
                  </div>
                </div>
                <div className="col-print-0"></div>
              </div>

              <div
                className="row"
                style={{ textAlign: "center", marginTop: "2rem" }}
              >
                <div className="col-print-1"> </div>
                <div className="col-print-9">
                  <p style={{ textAlign: "left", fontSize: "130%" }}>
                    Nesi Optical Shop <br /> No.6, Gandhipuram Main Road, Karur,{" "}
                    <br />
                    Backside Of EB Office - 639002 <br />
                    +91 9894670007{" "}
                  </p>
                </div>
                <div className="col-print-3"> </div>
              </div>

              <div
                className="row"
                style={{ textAlign: "center", marginTop: "2rem" }}
              >
                <div className="col-print-1"></div>
                <div className="col-print-7">
                  <h5 style={{ textAlign: "left", fontSize: "130%" }}>
                    Bill To
                  </h5>
                  <p style={{ textAlign: "left", fontSize: "130%" }}>
                    {cus_name} <br /> {place} <br /> {phone}{" "}
                  </p>
                </div>
                <div className="col-print-4">
                  {/* <h5 style={{textAlign:'left',fontSize:'130%'}}>Invoice# : INV-12</h5>   */}
                  <p style={{ textAlign: "left", fontSize: "130%" }}>
                    Invoice Date: {tdy_date}{" "}
                  </p>
                </div>
              </div>

              <div
                className="row"
                style={{ textAlign: "center", marginTop: "4rem" }}
              >
                <div className="col-print-1"></div>
                <div className="col-print-10">
                  <table className="table  table-responsive">
                    <thead
                      style={{
                        backgroundColor: "#fff",
                        color: "#1a4567",
                        fontSize: "150%",
                      }}
                    >
                      <tr>
                        <th style={{ width: "100%" }}>Brand</th>
                        <th style={{ width: "100%" }}>UnitPrice</th>
                        <th style={{ width: "100%" }}>Quantity</th>
                        <th style={{ width: "100%" }}>Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {est.length > 0 ? (
                        est.map((sal, index) => {
                          return (
                            <tr key={index} style={{ fontSize: "130%" }}>
                              <td> {sal.brand} </td>
                              <td> {sal.frame_rate + sal.lens_rate} </td>
                              <td> {sal.quantity} </td>
                              <td> {sal.amount}</td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td>No Data</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  <hr />

                  <div style={{ marginTop: "2rem" }}>
                    <br></br>
                    <p
                      style={{
                        textAlign: "right",
                        marginRight: "2rem",
                        fontSize: "130%",
                      }}
                    >
                      SubTotal : {Math.round(subtotal)}
                    </p>
                    {/* <p
                      style={{
                        textAlign: "right",
                        marginRight: "2rem",
                        fontSize: "130%",
                      }}
                    >
                      SGST : {Math.round(subtotal * 0.09)}
                    </p>
                    <p
                      style={{
                        textAlign: "right",
                        marginRight: "2rem",
                        fontSize: "130%",
                      }}
                    >
                      CGST : {Math.round(subtotal * 0.09)}
                    </p> */}
                    <p
                      style={{
                        textAlign: "right",
                        marginRight: "2rem",
                        fontSize: "150%",
                        fontWeight: "bold",
                      }}
                    >
                      Total Amount: <span>&#8377;</span> {subtotal}
                    </p>
                  </div>
                </div>
                <div className="col-print-1"></div>
              </div>

              <div className="footer">
                <p>THANK YOU FOR YOUR BUSINESS </p>
              </div>
            </div>
          </>
        </Modal>
      </div>
      {/*  */}
    </>
  );
}

const marginTop = "20px";
const marginRight = "5px";
const marginBottom = "10px";
const marginLeft = "5px";
const getPageMargins = () => {
  return `@page { margin: ${marginTop} ${marginRight} ${marginBottom} ${marginLeft} !important; }`;
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },

  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },

  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
}));

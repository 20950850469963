export const getAllFrameColorMasterReducer = (
  state = { framecolor: [] },
  action
) => {
  switch (action.type) {
    case "GET_FRAMECOLOR_SUCCESS":
      return {
        loading: false,
        framecolor: action.payload,
      };
    default:
      return state;
  }
};

export const getAllFrameColorNamesReducer = (
  state = { framecolor_names: [] },
  action
) => {
  switch (action.type) {
    case "GET_FCNAME_SUCCESS":
      return {
        framecolor_names: action.payload,
      };
    default:
      return state;
  }
};

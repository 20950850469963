export const getAllLensPowerMasterReducer = (
  state = { lenspower: [] },
  action
) => {
  switch (action.type) {
    case "GET_LENSPOWER_SUCCESS":
      return {
        loading: false,
        lenspower: action.payload,
      };
    default:
      return state;
  }
};

export const getAllLensPowerNamesReducer = (
  state = { lenspower_names: [] },
  action
) => {
  switch (action.type) {
    case "GET_LPNAME_SUCCESS":
      return {
        lenspower_names: action.payload,
      };
    default:
      return state;
  }
};

import React, { useState, useEffect } from "react";
import styled from "styled-components";
import * as moment from "moment";
import { TextField, Button } from "@material-ui/core";
import DatePicker from "react-datepicker";
import Switch from "react-switch";

export default function ViewJob(props) {
  return (
    <>
      <Section>
        <div className="view__acc_container">
          <div className="view__filterbox__container">
            <div className="view__filterdate__container">
              <div style={{ marginTop: "-0.5rem" }}>
                <br />
                <p>Entry Date</p>
              </div>
              <div style={{ marginTop: "-0.5rem" }}>
                <DatePicker
                  value={
                    props.from_date !== "Invalid date" ? props.from_date : ""
                  }
                  onChange={(date) => {
                    props.onHandleFromDate(date);
                  }}
                  dateFormat="yyyy-MM-dd"
                  placeholderText="From (Bill Date)"
                  monthsShown={1}
                  showYearDropdown
                />


              </div>

              <div style={{ marginTop: "-0.5rem" }}>
                <DatePicker
                  value={props.to_date !== "Invalid date" ? props.to_date : ""}
                  onChange={(date) => {
                    props.onHandleToDate(date);
                  }}
                  dateFormat="yyyy-MM-dd"
                  placeholderText="To (Bill Date)"
                  monthsShown={1}
                  showYearDropdown
                />
              </div>

              <div>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    props.onHandleSearchDate();
                  }}
                >
                  <i className="fa fa-search"></i>{" "}
                </Button>
              </div>

              {/* <div>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    props.onBillFetch();
                  }}
                >
                  {" "}
                  Bill{" "}
                </Button>
              </div> */}
            </div>

            <div className="view__filtersearch__container">
              <TextField
                className="txtfld"
                id="outlined-basic"
                variant="outlined"
                fullWidth={false}
                onChange={(e) => {
                  props.onHandleSearch(e.target.value);
                }}
                value={props.search}
                placeholder="Search"
                style={{ backgroundColor: "white" }}
              />
            </div>
          </div>

          <div
            className="table-responsive"
          // style={{ marginTop: "-0.1rem", overflow: "auto", height: "500px" }}
          >
            <table className="table table-bordered table-responsive-sm">
              <thead style={{ backgroundColor: "#f6b93b", color: "#ffffff" }}>
                <tr>
                  <th>S.No</th>
                  <th>Inward</th>
                  <th>Outward</th>
                  <th>Entry_Date</th>
                  <th>Bill Number</th>
                  <th>Customer Details</th>

                  <th>Brand</th>
                  <th>Model Number</th>
                  <th>Frame Type</th>
                  <th>Frame Color</th>
                  <th>Frame Size</th>

                  <th>Quantity</th>

                  <th>Fitter Name</th>
                  <th>JobOrder Date</th>
                  <th>Lens Power</th>
                  <th>JobDeliver Date</th>
                  {/* <th>Lens Rate</th>
                  <th>Frame Rate</th>
                  <th>Advance</th>
                  <th>Amount</th>
                  <th>Balance</th> */}
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody
                style={{ maxHeight: "200px", overflowY: "auto", width: "100%" }}
              >
                {props.job.length > 0 ? (
                  props.job.map((es, index) => {
                    return (
                      <tr key={index}
                        style={{ backgroundColor: es.fitter_name ? '#f6e58d' : '' }}
                      >
                        <td> {index + 1} </td>
                        <td
                          style={{ cursor: "pointer", fontSize: '1.5rem', color: '#6ab04c' }}
                          onClick={() => {
                            props.onHandleStatusChange(es);
                          }}
                        >
                          <i className="fa fa-eye"></i>

                        </td>

                        <td
                          style={{ cursor: "pointer", fontSize: '1.3rem', color: '#eb4d4b' }}
                          onClick={() => {
                            props.onHandleEditChange(es);
                          }}
                        >
                          <i className="fa fa-edit"></i>
                        </td>


                        <td> {moment(es.entry_date).format("DD-MM-YYYY")} </td>
                        <td> {es.bill_num} </td>
                        <td>
                          {" "}
                          {es.cus_name} {" "}
                        </td>

                        <td> {es.brand} </td>
                        <td> {es.model_number} </td>
                        <td> {es.frame_type} </td>
                        <td> {es.frame_color} </td>
                        <td> {es.frame_size} </td>

                        <td style={{ textAlign: 'center' }}> {es.quantity} </td>

                        <td> {es.fitter_name} </td>
                        <td>
                          {es.job_order_date == null
                            ? ""
                            : moment(es.job_order_date).format("DD-MM-YYYY")}
                        </td>

                        <td> {es.lens_power} </td>
                        <td>
                          {es.job_order_date == null
                            ? ""
                            : moment(es.job_deliver_date).format("DD-MM-YYYY")}
                        </td>
                        {/* <td>
                          <Switch
                            onChange={(val) => {
                              props.onHandleSwitchChange(es, val);
                            }}
                            checked={es.bill_checked === 1 ? true : false}
                          />
                        </td> */}
                        {/* <td>{es.lens_rate}</td>
                        <td> {es.frame_rate} </td>
                        <td>
                          {es.advance} <br />
                          {es.adv_pay_mode}
                        </td>
                        <td>{es.amount}</td>
                        <td>{es.amount - es.advance}</td> */}

                        <td style={{ cursor: "pointer" }}>
                          <i
                            className="fa fa-trash"
                            onClick={() => {
                              props.onHandleDelete(es);
                            }}
                          ></i>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td>No Data</td>
                  </tr>
                )}
              </tbody>

            </table>
          </div>
        </div>
      </Section>
    </>
  );
}

const Section = styled.section`
  .view__acc_container {
    margin: 0px 0px;
    padding-left: 8rem;
  }

  .view__filterbox__container {
    margin: 0px auto;
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    gap: 1rem;
    padding: 0.5rem;
    background-color: #ffeaa7;
  }
  .view__filterdate__container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    flex: 0.7;
  }
  .view__filtersearch__container {
    flex: 0.3;
    text-align: center;
  }

  @media screen and (min-width: 280px) and (max-width: 720px) {
    .view__filterbox__container {
      display: flex;
      flex-direction: column;
    }
    .view__filterdate__container {
      display: flex;
      flex-direction: column;
    }
  }
`;

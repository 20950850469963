import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import { makeStyles } from "@material-ui/core/styles";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import DraftsIcon from "@material-ui/icons/Drafts";
import jwt_decode from "jwt-decode";
import SendIcon from "@material-ui/icons/Send";
import { useHistory, Link, useLocation, NavLink } from "react-router-dom";
import Avatar from "@material-ui/core/Avatar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
    backgroundColor: "#6c5ce7",
    color: "#fff",
    textAlign: "left",
    paddingLeft: "1rem",
    paddingRight: "1rem",
    width: "10rem",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function CustomizedMenus() {
  const userState = useSelector((state) => state.loginUserReducer);
  const { currentUser } = userState;

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const classes = useStyles();

  return (
    <div>
      <ListItem>
        <ListItemIcon>
          {" "}
          <Avatar style={{ backgroundColor: "#6c5ce7" }} onClick={handleClick}>
            <h6 style={{ marginTop: "0.5rem" }}>MAS</h6>
          </Avatar>{" "}
        </ListItemIcon>
        <div>
          <Button
            aria-controls="customized-menu"
            aria-haspopup="true"
            variant="text"
            onClick={handleClick}
          >
            <span style={{ color: "#6c5ce7 " }}>
              <h5 style={{ marginLeft: "-0.5rem" }}>Master</h5>
            </span>
          </Button>
        </div>

        <StyledMenu
          id="customized-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <NavLink to="/supplier-master" smooth="true">
            <h6 style={{ color: "white" }}>Company</h6>
          </NavLink>
          <br />
          <NavLink to="/brand-master" smooth="true">
            <h6 style={{ color: "white" }}>Brand</h6>
          </NavLink>
          <br />
          <NavLink to="/model-number-master" smooth="true">
            <h6 style={{ color: "white" }}>Model Number</h6>
          </NavLink>
          <br />
          <NavLink to="/frame-color-master" smooth="true">
            <h6 style={{ color: "white" }}>Frame Color</h6>
          </NavLink>
          <br />
          <NavLink to="/lens-power-master" smooth="true">
            <h6 style={{ color: "white" }}> Lens Power </h6>
          </NavLink>
          <br />
          <NavLink to="/lens-rate-master" smooth="true">
            <h6 style={{ color: "white" }}>Lens Rate</h6>
          </NavLink>
          <br />
          <NavLink to="/fitter-master" smooth="true">
            <h6 style={{ color: "white" }}>Fitter</h6>
          </NavLink>
          <br />
          {jwt_decode(userState.currentUser).role == "admin" ? (
            <NavLink to="/mrp-fixing" smooth="true">
              <h6 style={{ color: "white" }}>MRP Fixing</h6>
            </NavLink>
          ) : (
            " "
          )}
        </StyledMenu>
      </ListItem>
    </div>
  );
}

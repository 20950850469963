import axios from "axios";
import { apiUrl } from "../../config";

export const addLensRateMaster = (acc) => async (dispatch) => {
  try {
    const response = await axios.post(
      `${apiUrl}/api/lens-rate-master/create`,
      acc
    );

    return {
      response,
    };
  } catch (error) {}
};

export const getAllLensRateMasterLength = () => async (dispatch) => {
  try {
    const response = await axios.get(
      `${apiUrl}/api/lens-rate-master/getAllLensRateMasterLength`
    );
    console.log("%%%111%%%", response.data.results[0].tot);
    return { response };
  } catch (error) {}
};

export const getAllLensRateMaster = (offset, row_count) => async (dispatch) => {
  try {
    const response = await axios.get(
      `${apiUrl}/api/lens-rate-master/getAllLensRateMaster/${offset}/${row_count}`
    );
    dispatch({
      type: "GET_LENSRATE_SUCCESS",
      payload: response.data.results,
    });
  } catch (error) {}
};

export const updateLensRateMaster = (pur) => async (dispatch) => {
  try {
    const response = await axios.post(
      `${apiUrl}/api/lens-rate-master/update`,
      pur
    );
    return { response };
    // window.location.reload()
  } catch (error) {
    alert("Something Went Wrong");
    console.log(error);
  }
};

export const deleteLensRateMaster = (accid) => async (dispatch) => {
  try {
    const response = await axios.post(`${apiUrl}/api/lens-rate-master/delete`, {
      accid,
    });
    return { response };
    // window.location.reload()
  } catch (error) {
    alert("Something Went Wrong");
    console.log(error);
  }
};

export const searchLensRateMaster = (val) => async (dispatch) => {
  const data = { id: val };
  try {
    const response = await axios.post(
      `${apiUrl}/api/lens-rate-master/search`,
      data
    );
    return { response };
    // window.location.reload()
  } catch (error) {
    return { error };
  }
};

export const getAllLensRateNames = () => async (dispatch) => {
  try {
    const response = await axios.get(
      `${apiUrl}/api/lens-rate-master/getallLensRateNames`
    );
    //console.log('##123456##',response.data.results)
    dispatch({ type: "GET_LRNAME_SUCCESS", payload: response.data.results });
  } catch (error) {}
};

import React, { useState, useEffect } from "react";
import styled from "styled-components";
import * as moment from "moment";
import { TextField, Button } from "@material-ui/core";
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import jwt_decode from "jwt-decode";

export default function ViewFrameColorMaster(props) {

  const userState = useSelector((state) => state.loginUserReducer);
  const { currentUser } = userState;

  return (
    <>
      <Section>
        <div className="view__acc_container">

          <h1>Frame Color List</h1>
          <table className="table table-bordered table-responsive-sm">
            <thead style={{ backgroundColor: "#7fbf7f", color: "#ffffff" }}>
              <tr>
                <th>S.No</th>
                <th> <TextField
                  className="txtfld"
                  id="outlined-basic"
                  variant="outlined"
                  fullWidth={false}
                  onChange={(e) => {
                    props.onHandleSearch(e.target.value);
                  }}
                  value={props.search}
                  placeholder="Search"
                  style={{ backgroundColor: "white" }}
                /> <br />Frame Color</th>
                <th>Edit</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              {props.framecolor.length > 0 ? (
                props.framecolor.map((cus, index) => {
                  return (
                    <tr key={index}>
                      <td> {(props.cp - 1) * 5 + (index + 1)} </td>

                      <td> {cus.frame_color} </td>

                      <td style={{ cursor: "pointer" }}>
                        <i
                          className="fa fa-edit"
                          onClick={() => {
                            props.onHandleUpdate(cus);
                          }}
                        ></i>
                      </td>
                      {
                        jwt_decode(userState.currentUser).role == 'admin' ?
                          <td style={{ cursor: "pointer" }}>
                            <i
                              className="fa fa-trash"
                              onClick={() => {
                                props.onHandleDelete(cus);
                              }}
                            ></i>
                          </td>
                          : null
                      }
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td>No Data</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </Section>
    </>
  );
}

const Section = styled.section`
  .view__acc_container {
    margin: 50px 0px;
  }

  .view__filterbox__container {
    margin: 20px auto;
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    gap: 1rem;
    padding: 1rem;
    background-color: #bfdfbf;
  }
  .view__filterdate__container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    flex: 0.7;
  }
  .view__filtersearch__container {
    flex: 0.3;
    text-align: center;
  }

  @media screen and (min-width: 280px) and (max-width: 720px) {
    .view__filterbox__container {
      display: flex;
      flex-direction: column;
    }
    .view__filterdate__container {
      display: flex;
      flex-direction: column;
    }
  }
`;

export const getAllFitterMasterReducer = (state = { fitter: [] }, action) => {
  switch (action.type) {
    case "GET_FITTER_SUCCESS":
      return {
        loading: false,
        fitter: action.payload,
      };
    default:
      return state;
  }
};

export const getAllFitterNamesReducer = (
  state = { fitter_names: [] },
  action
) => {
  switch (action.type) {
    case "GET_FNAME_SUCCESS":
      return {
        fitter_names: action.payload,
      };
    default:
      return state;
  }
};

export const getAllModelNumberMasterReducer = (
  state = { modelnum: [] },
  action
) => {
  switch (action.type) {
    case "GET_MODELNUMBER_SUCCESS":
      return {
        loading: false,
        modelnum: action.payload,
      };
    default:
      return state;
  }
};

export const getAllModelNumberNamesReducer = (
  state = { modelnum_names: [] },
  action
) => {
  switch (action.type) {
    case "GET_MNNAME_SUCCESS":
      return {
        modelnum_names: action.payload,
      };
    default:
      return state;
  }
};

import axios from "axios";
import { apiUrl } from "../../config";

export const addEst = (acc) => async (dispatch) => {
  //console.log("ACTION------>", acc);
  dispatch({ type: "ADD_EST_REQUEST" });
  try {
    const response = await axios.post(`${apiUrl}/api/est/create`, acc);
    console.log("+++ add", response);
    dispatch({ type: "ADD_EST_SUCCESS" });
    return {
      response,
    };
  } catch (error) {
    dispatch({ type: "ADD_EST_FAILED", payload: error });
  }
};

export const allEstClear = (acc) => async (dispatch) => {
  try {
    dispatch({ type: "ALL_EST_CLEAR" });
  } catch (error) {
    dispatch({ type: "ALL_EST_FAILED", payload: error });
  }
};

export const getAllEstLength = () => async (dispatch) => {
  try {
    const response = await axios.get(`${apiUrl}/api/est/getallest_length`);
    //console.log('%%%111%%%',response.data.results[0].tot);
    return { response };
  } catch (error) { }
};

export const getAllEstbycus =
  (offset, row_count, cus_name, phone, from_date) => async (dispatch) => {
    console.log("GGGGGGG", from_date);
    dispatch({ type: "GET_EST_REQUEST" });

    try {
      const response = await axios.get(
        `${apiUrl}/api/est/getallestbycus/${offset}/${row_count}/${cus_name}/${phone}/${from_date}`
      );
      console.log("%%%%%%", response);
      dispatch({ type: "GET_EST_SUCCESS", payload: response.data.results });
    } catch (error) {
      console.log("EEEEEEEE", error);
      dispatch({ type: "GET_EST_FAILED", payload: error });
    }
  };

export const getAllEst = (offset, row_count) => async (dispatch) => {
  dispatch({ type: "GET_EST_REQUEST" });

  try {
    const response = await axios.get(
      `${apiUrl}/api/est/getallest/${offset}/${row_count}`
    );
    //console.log('%%%%%%',response);
    dispatch({ type: "GET_EST_SUCCESS", payload: response.data.results });
  } catch (error) {
    console.log("EEEEEEEE", error);
    dispatch({ type: "GET_EST_FAILED", payload: error });
  }
};

export const updateEst = (pur) => async (dispatch) => {
  try {
    const response = await axios.post(`${apiUrl}/api/est/update`, pur);
    return { response };
    // window.location.reload()
  } catch (error) {
    alert("Something Went Wrong");
    console.log(error);
  }
};

export const updateEstStatus = (pur) => async (dispatch) => {
  try {
    const response = await axios.post(`${apiUrl}/api/est/updateEstStatus`, pur);
    return { response };
    // window.location.reload()
  } catch (error) {
    alert("Something Went Wrong");
    console.log(error);
  }
};

export const deleteEst = (accid) => async (dispatch) => {
  try {
    const response = await axios.post(`${apiUrl}/api/est/delete`, { accid });
    return { response };
    // window.location.reload()
  } catch (error) {
    alert("Something Went Wrong");
    console.log(error);
  }
};

export const getBill = (id, cus_name) => async (dispatch) => {
  dispatch({ type: "GET_EST_REQUEST" });

  try {
    const response = await axios.get(
      `${apiUrl}/api/est/getbill/${id}/${cus_name}`
    );
    console.log("%%%%%%", response);
    dispatch({ type: "GET_EST_SUCCESS", payload: response.data.results });
  } catch (error) {
    dispatch({ type: "GET_EST_FAILED", payload: error });
  }
};

export const searchEst = (val) => async (dispatch) => {
  const data = { id: val };
  try {
    const response = await axios.post(`${apiUrl}/api/est/search`, data);
    return { response };
    // window.location.reload()
  } catch (error) {
    return { error };
    //alert('Something Went Wrong')
    console.log(error);
  }
};

export const filterEst = (data) => async (dispatch) => {
  try {
    const response = await axios.post(`${apiUrl}/api/est/filterdate`, data);
    return { response };
    // window.location.reload()
  } catch (error) {
    return { error };
    //alert('Something Went Wrong')
    console.log(error);
  }
};

export const CheckedBillEst = (id, val) => async (dispatch) => {
  const data = { id, val };
  try {
    const response = await axios.post(`${apiUrl}/api/est/checked`, data);
    return { response };
    // window.location.reload()
  } catch (error) {
    return { error };
    //alert('Something Went Wrong')
    console.log(error);
  }
};

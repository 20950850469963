import axios from "axios";
import { apiUrl } from "../../config";

export const addBrandMaster = (acc) => async (dispatch) => {
  try {
    const response = await axios.post(`${apiUrl}/api/brand-master/create`, acc);
    return {
      response,
    };
  } catch (error) {}
};

export const getAllBrandMasterLength = () => async (dispatch) => {
  try {
    const response = await axios.get(
      `${apiUrl}/api/brand-master/getAllBrandMasterLength`
    );
    console.log("%%%111%%%", response.data.results[0].tot);
    return { response };
  } catch (error) {}
};

export const getAllBrandMaster = (offset, row_count) => async (dispatch) => {
  try {
    const response = await axios.get(
      `${apiUrl}/api/brand-master/getAllBrandMaster/${offset}/${row_count}`
    );
    dispatch({
      type: "GET_BRANDMASTER_SUCCESS",
      payload: response.data.results,
    });
  } catch (error) {}
};

export const updateBrandMaster = (pur) => async (dispatch) => {
  try {
    const response = await axios.post(`${apiUrl}/api/brand-master/update`, pur);
    return { response };
    // window.location.reload()
  } catch (error) {
    alert("Something Went Wrong");
    console.log(error);
  }
};

export const deleteBrandMaster = (accid) => async (dispatch) => {
  try {
    const response = await axios.post(`${apiUrl}/api/brand-master/delete`, {
      accid,
    });
    return { response };
    // window.location.reload()
  } catch (error) {
    alert("Something Went Wrong");
    console.log(error);
  }
};

export const searchBrandMaster = (val) => async (dispatch) => {
  const data = { id: val };
  try {
    const response = await axios.post(
      `${apiUrl}/api/brand-master/search`,
      data
    );
    return { response };
    // window.location.reload()
  } catch (error) {
    return { error };
  }
};

export const getAllBrandNames = () => async (dispatch) => {
  try {
    const response = await axios.get(
      `${apiUrl}/api/brand-master/getallBrandNames`
    );
    //console.log('##123456##',response.data.results)
    dispatch({ type: "GET_BNAME_SUCCESS", payload: response.data.results });
  } catch (error) {}
};

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import jwt_decode from "jwt-decode";
import { logoutUser } from "../../redux/actions/userActions";
import { useHistory, Link, useLocation, NavLink } from "react-router-dom";
import logo from "../../assets/logo.png";

import "./AdminNavbar.css";

const AdminNavbar = () => {
  const userState = useSelector((state) => state.loginUserReducer);
  const { currentUser } = userState;

  const [ismas, setIsMas] = useState(false);
  const [val, setVal] = useState("");
  const [navLinkOpen, navLinkToggle] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const handleNavlinkToggle = () => {
    navLinkToggle(!navLinkOpen);
    setIsMas(false);
  };
  const handleNavlinkToggleMaster = () => {
    setIsMas(true);
    navLinkToggle(!navLinkOpen);
  };

  const dispatch = useDispatch();
  const logOut = () => {
    dispatch(logoutUser()).then(() => {
      history.push("/login");
    });
  };

  const renderClasses = () => {
    let classes = "navlinks";
    if (navLinkOpen) {
      classes += " active";
    }
    return classes;
  };

  return (
    <div
      className={
        jwt_decode(userState.currentUser).role == "admin"
          ? "navbar_admin"
          : "navbar"
      }
      id="top"
    >
      <nav>
        <div className="logo">
          <img
            src={logo}
            style={{ height: "4rem", width: "8rem", backgroundColor: "#fff" }}
          />
        </div>

        <ul className={renderClasses()}>
          <NavLink
            onClick={handleNavlinkToggle}
            to="/job-order"
            smooth="true"
            duration={500}
            exact
            activeClassName="click"
          >
            {" "}
            <h4>Job Order</h4>{" "}
          </NavLink>

          <NavLink
            onClick={handleNavlinkToggle}
            to="/sales"
            smooth="true"
            duration={500}
            exact
            activeClassName="click"
          >
            {" "}
            <h4
              onClick={() => {
                handleNavlinkToggle("sales");
              }}
            >
              Sales
            </h4>{" "}
          </NavLink>

          <NavLink
            onClick={handleNavlinkToggle}
            to="/products"
            smooth="true"
            duration={500}
            exact
            activeClassName="click"
          >
            {" "}
            <h4>Purchase</h4>{" "}
          </NavLink>
          {/*  */}

          {/*  */}

          <li
            className={
              ismas === true
                ? "nav-item dropdown clickmaster"
                : "nav-item dropdown"
            }
          >
            {/* <a id="navbarDropdown" data-toggle="dropdown"> */}
            {/* <h5>Master</h5>{" "} */}

            <NavLink
              id="navbarDropdown"
              data-toggle="dropdown"
              onClick={() => {
                setIsMas(true);
              }}
              to="#"
              smooth="true"
              duration={500}
              exact
            >
              {" "}
              <h5 style={{ color: "#fff" }}>Master</h5>{" "}
            </NavLink>

            {/* </a> */}
            <div
              className=" dropdown-menu"
              style={{
                padding: "1rem 2rem",
                backgroundColor: "#575fcf",
                width: "15rem",
              }}
            >
              <NavLink
                // onClick={handleNavlinkToggleMaster}
                to="/supplier-master"
                smooth="true"
                duration={500}
                exact
              >
                {" "}
                <h6 style={{ color: "#fff" }}>Supplier</h6>{" "}
              </NavLink>
              <br />
              <NavLink
                //onClick={handleNavlinkToggleMaster}
                to="/brand-master"
                smooth="true"
                duration={500}
                exact
              >
                {" "}
                <h6 style={{ color: "#fff" }}>Brand </h6>{" "}
              </NavLink>
              <br />
              <NavLink
                onClick={handleNavlinkToggleMaster}
                to="/model-number-master"
                smooth="true"
                duration={500}
                exact
              >
                {" "}
                <h6 style={{ color: "#fff" }}>Model Number </h6>{" "}
              </NavLink>

              <br />
              <NavLink
                onClick={handleNavlinkToggleMaster}
                to="/frame-color-master"
                smooth="true"
                duration={500}
                exact
              >
                {" "}
                <h6 style={{ color: "#fff" }}>Frame Color </h6>{" "}
              </NavLink>
              <br />
              <NavLink
                onClick={handleNavlinkToggleMaster}
                to="/lens-power-master"
                smooth="true"
                duration={500}
                exact
              >
                {" "}
                <h6 style={{ color: "#fff" }}>Lens Power </h6>{" "}
              </NavLink>
              <br />
              <NavLink
                onClick={handleNavlinkToggleMaster}
                to="/lens-rate-master"
                smooth="true"
                duration={500}
                exact
              >
                {" "}
                <h6 style={{ color: "#fff" }}>Lens Rate </h6>{" "}
              </NavLink>
              <br />
              <NavLink
                onClick={handleNavlinkToggleMaster}
                to="/fitter-master"
                smooth="true"
                duration={500}
                exact
              >
                {" "}
                <h6 style={{ color: "#fff" }}>Fitter</h6>{" "}
              </NavLink>
              <br />

              {jwt_decode(userState.currentUser).role == "admin" ? (
                <NavLink
                  onClick={handleNavlinkToggleMaster}
                  to="/mrp-fixing"
                  smooth="true"
                  duration={500}
                  exact
                >
                  {" "}
                  <h6 style={{ color: "#fff" }}>MRP Fixing</h6>{" "}
                </NavLink>
              ) : (
                ""
              )}
            </div>
          </li>

          <NavLink onClick={logOut} to="/#" smooth="true" duration={500}>
            {" "}
            <h4>LogOut</h4>{" "}
          </NavLink>
        </ul>
        <div onClick={handleNavlinkToggle} className="hamburger-toggle">
          <i className="fas fa-bars fa-lg"></i>
        </div>
      </nav>
    </div>
  );
};

export default AdminNavbar;

import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { TextField, Button } from "@material-ui/core";
import * as moment from "moment";
import AdminNavbar from "../../components/AdminNavbar/AdminNavbar";
import { useDispatch, useSelector } from "react-redux";
import jwt_decode from "jwt-decode";
import Select from "react-select";
import ViewJobOrder from "./ViewJobOrder";
import ViewSales from "./ViewSales";

import {
  addJobOrder,
  deleteJobOrder,
  //updateJobOrder,
  getAllJobOrder,
  getAllJobOrderbyVen,
  getAllJobOrderLength,
  allJobOrderClear,
  searchJobOrder,
  filterJobOrder,
  CheckedBillJobOrder,
} from "../../redux/actions/jobOrderAction";

import { getAllSales, searchSales } from "../../redux/actions/salesAction";

import { getAllLensPowerNames } from "../../redux/actions/lensPowerMasterAction";
import { getAllFitterNames } from "../../redux/actions/fitterMasterAction";
import Loading from "../../components/Loading";
import Error from "../../components/Error";
import OnLineErrors from "../../components/OnLineErrors";
import Success from "../../components/Success";
import "./pagestyle.css";
import "./printJobOrder.css";
import { useReactToPrint } from "react-to-print";
import Modal from "react-modal";
import logo from "../../assets/logo.png";

var datetime = new Date();
var tdy_date = datetime
  .toISOString()
  .slice(0, 10)
  .split("-")
  .reverse()
  .join("-");
var t_date = datetime.toISOString().slice(0, 10).split("-").join("-");

export default function JobOrder() {
  const [is_edit, setis_edit] = useState(false);
  const [id, setid] = useState("");

  const [fitter_name, setfitter_name] = useState("");
  const [fitter_name_val, setfitter_name_val] = useState("");
  const [job_order_number, setjob_order_number] = useState("");
  const [order_date, setorder_date] = useState("");
  const [brand, setbrand] = useState("");
  const [brand_val, setbrand_val] = useState("");
  const [model_number, setmodel_number] = useState("");
  const [model_number_val, setmodel_number_val] = useState("");
  const [frame, setframe] = useState("");
  const [frame_color, setframe_color] = useState("");
  const [frame_color_val, setframe_color_val] = useState("");
  const [frame_size, setframe_size] = useState("");
  const [frame_size_val, setframe_size_val] = useState("");
  const [lens, setlens] = useState("");
  const [lens_power, setlens_power] = useState("");
  const [lens_power_val, setlens_power_val] = useState("");
  const [deliver_date, setdeliver_date] = useState("");

  const [lenspowerlist, setlenspowerlist] = useState([]);
  const [brandlist, setbrandlist] = useState([]);
  const [fitterlist, setfitterlist] = useState([]);
  const [modelnumlist, setmodelnumlist] = useState([]);
  const [framecolorlist, setframecolorlist] = useState([]);

  const [errors, seterrors] = useState([]);
  const [search, setsearch] = useState("");
  const [tot, setTot] = useState(0);

  const [from_date, setfrom_date] = useState("");
  const [to_date, setto_date] = useState("");

  const optionsframe = [
    { value: "Shell", label: "Shell" },
    { value: "Supra", label: "Supra" },
    { value: "3 pc", label: "3 pc" },
    { value: "Metal frame full", label: "Metal frame full" },
    { value: "Metal frame half", label: "Metal frame half" },
    { value: "Reading glass", label: "Reading glass" },
  ];
  const optionslens = [
    { value: "CR", label: "CR" },
    { value: "Sepctra arc", label: "Sepctra arc" },
    { value: "Bio focal", label: "Bio focal" },
    { value: "Progressive", label: "Progressive" },
    { value: "D-bifocal", label: "D-bifocal" },
    { value: "Essilor", label: "Essilor" },
    { value: "Zycus", label: "Zycus" },
  ];
  const options_framesize = [
    { value: "42", label: "42" },
    { value: "43", label: "43" },
    { value: "44", label: "44" },
    { value: "45", label: "45" },
    { value: "46", label: "46" },
    { value: "47", label: "47" },
    { value: "48", label: "48" },
    { value: "49", label: "49" },
    { value: "50", label: "50" },
    { value: "51", label: "51" },
    { value: "52", label: "52" },
    { value: "53", label: "53" },
    { value: "54", label: "54" },
    { value: "55", label: "55" },
    { value: "56", label: "56" },
    { value: "57", label: "57" },
    { value: "58", label: "58" },
    { value: "59", label: "59" },
    { value: "60", label: "60" },
    { value: "61", label: "61" },
    { value: "62", label: "62" },
    { value: "63", label: "63" },
    { value: "64", label: "64" },
  ];
  const customStyles = {
    control: (base) => ({
      ...base,
      borderColor: errors.category === "*" ? "red" : "gray",
    }),
  };
  const customStyles_model_num = {
    control: (base) => ({
      ...base,
      borderColor: errors.model_number === "*" ? "red" : "gray",
    }),
  };
  const customStyles_frame = {
    control: (base) => ({
      ...base,
      borderColor: errors.frame === "*" ? "red" : "gray",
    }),
  };
  const customStyles_lens = {
    control: (base) => ({
      ...base,
      borderColor: errors.lens === "*" ? "red" : "gray",
    }),
  };

  const customStyles_lenspower = {
    control: (base) => ({
      ...base,
      borderColor: errors.lens_power === "*" ? "red" : "gray",
    }),
  };

  const userState = useSelector((state) => state.loginUserReducer);
  const { currentUser } = userState;

  const add_purchase_state = useSelector(
    (state) => state.getAddPurchaseReducer
  );
  const { success, error, loading } = add_purchase_state;

  const all_sales_state = useSelector((state) => state.getAllSalesReducer);
  const { sales } = all_sales_state;

  const bnState = useSelector((state) => state.getAllBrandNamesReducer);
  const brand_names = bnState.brand_names;

  const fState = useSelector((state) => state.getAllFitterNamesReducer);
  const fitter_names = fState.fitter_names;

  const mnState = useSelector((state) => state.getAllModelNumberNamesReducer);
  const modelnum_names = mnState.modelnum_names;

  const fcState = useSelector((state) => state.getAllFrameColorNamesReducer);
  const framecolor_names = fcState.framecolor_names;

  const lpState = useSelector((state) => state.getAllLensPowerNamesReducer);
  const lenspower_names = lpState.lenspower_names;

  const all_joborder_state = useSelector(
    (state) => state.getAllJobOrderReducer
  );
  const { joborder } = all_joborder_state;

  //console.log("framecolor_names !!! __", framecolor_names);

  useEffect(() => {
    handleTodayDate();
    if (currentUser.length > 0) {
      var decoded = jwt_decode(userState.currentUser);
      //console.log('-------#####--------',decoded.role)
      //console.log('-------*****--------',((decoded.role !== 'admin') && (decoded.role !== 'nesi')))
      if (decoded.role !== "admin" && decoded.role !== "nesi") {
        window.location.href = "/login";
      }
    } else {
      window.location.href = "/login";
    }

    setData(joborder);
    setsales_data(sales);

    dispatch(allJobOrderClear());
    dispatch(getAllJobOrderLength()).then((response) => {
      setTot(response.response.data.results[0].tot);
    });
    dispatch(getAllLensPowerNames());
    dispatch(getAllFitterNames());
    dispatch(getAllJobOrder(1, itemsPerPage));
    dispatch(getAllSales(1, 10));
    handleLensPowerlist();
    handleBrandList();
    handleFitterList();
    handleModelNumList();
    handleFrameColorList();
  }, []);

  useEffect(() => {
    setData(joborder);
  }, [joborder]);

  useEffect(() => {
    setsales_data(sales);
  }, [sales]);

  const dispatch = useDispatch();

  const handleTodayDate = () => {
    let datetime = new Date();
    let tdy_date = datetime.toISOString().slice(0, 10);

    setfrom_date(moment(tdy_date).format("DD-MM-YYYY"));
    setto_date(moment(tdy_date).format("DD-MM-YYYY"));
  };

  const handleClear = () => {
    // console.log('------clear-------')
    setis_edit(false);
  };

  //
  const handleLensPowerlist = () => {
    setlenspowerlist(lenspower_names);
  };
  let lenspower_name_options = lenspowerlist.map(function (rv) {
    return { value: rv.lens_power, label: rv.lens_power };
  });

  const handleBrandList = () => {
    setbrandlist(brand_names);
  };
  let brandname_options = brandlist.map(function (rv) {
    return { value: rv.brand_name, label: rv.brand_name };
  });

  const handleFitterList = () => {
    setfitterlist(fitter_names);
  };
  let fittername_options = fitterlist.map(function (rv) {
    return { value: rv.fitter_name, label: rv.fitter_name };
  });

  const handleModelNumList = () => {
    setmodelnumlist(modelnum_names);
  };
  let modelnumname_options = modelnumlist.map(function (rv) {
    return { value: rv.model_number, label: rv.model_number };
  });

  const handleFrameColorList = () => {
    setframecolorlist(framecolor_names);
  };
  let framecolorname_options = framecolorlist.map(function (rv) {
    return { value: rv.frame_color, label: rv.frame_color };
  });

  //
  const handleDelete = (job) => {
    console.log("@@@@", job);

    var option = window.confirm(
      `Are You Sure want to delete  ${job.fitter_name} ( ${job.job_order_number} )`
    );
    if (option) {
      dispatch(deleteJobOrder(job.id)).then((res) => {
        dispatch(getAllJobOrder(1, itemsPerPage, fitter_name));
      });
    }
  };

  const handleSwitchChange = (job, new_status) => {
    console.log("+++++tar++++", job.id, new_status);

    const data = { from_date, to_date, fitter_name };
    dispatch(CheckedBillJobOrder(job.id, new_status)).then((res) => {
      dispatch(filterJobOrder(data))
        .then((res) => {
          console.log("------ RP ------", res.response.data.results);
          setData(res.response.data.results);
        })
        .catch((err) => {
          console.log("------ ERR ------", err);
          //dispatch(getAllBillService(1,itemsPerPage, fitter_name))
        });
    });
  };

  const handleSearch = (val) => {
    //setfrom_date('');
    //setto_date('');
    setsearch(val);
    dispatch(searchJobOrder(val))
      .then((res) => {
        setData(res.response.data.results);
      })
      .catch((err) => {
        setsearch("");
        dispatch(getAllJobOrder(1, itemsPerPage, fitter_name));
      });
  };

  const handleSearchdate = () => {
    if (from_date !== "" && to_date !== "") {
      setsearch("");
      if (fitter_name === "*") {
      }
      const data = { from_date, to_date, fitter_name };
      dispatch(filterJobOrder(data))
        .then((res) => {
          console.log("------ RP ------", res.response.data.results);
          setData(res.response.data.results);
        })
        .catch((err) => {
          dispatch(getAllJobOrder(1, itemsPerPage, fitter_name));
        });
    } else {
      alert("Select date");
    }
  };

  const Bill = () => {
    console.log("FFFFFFFFF", from_date);

    if (fitter_name.length > 0) {
      dispatch(getAllJobOrderbyVen(1, itemsPerPage, fitter_name, from_date))
        .then((res) => {
          console.log("&&&&&======BILL DATA =======&&&&", res);
        })
        .then(() => {
          openModal();
          handlePrint();
          closeModal();
        });
    } else {
      alert("Customer Name Required");
    }
  };

  const Submit = () => {
    const user_data = {
      fitter_name,
      order_date,
      brand,
      frame,
      model_number,
      frame_color,
      frame_size,
      lens,
      lens_power,
      deliver_date,
    };
    console.log("000000", user_data);
    const errors = validate(user_data);
    seterrors(errors);
    console.log("@@@@@", errors);
    if (Object.keys(errors).length === 0) {
      if (!is_edit) {
        dispatch(addJobOrder(user_data)).then((res) => {
          // dispatch(
          //   getAllJobOrderbyVen(1, itemsPerPage, fitter_name, from_date)
          // );
          dispatch(getAllJobOrder(1, itemsPerPage));
          alert(res.response.data.msg);
        });
      } else {
        // dispatch(updateJobOrder(user_data)).then((res) => {
        // console.log('!!!!!!',res.response.data.success)
        //   setis_edit(false);
        //   handleClear();
        //   dispatch(getAllJobOrder(currentPage, itemsPerPage, fitter_name));
        //   if (res.response.data.success) {
        //     alert("Updated");
        //   }
        // });
      }
    } else {
      // console.log('@#@#@#@#',errors)
    }
  };

  const validate = (data) => {
    const errors = {};

    if (!data.fitter_name) {
      errors.fitter_name = "*";
    }

    if (!data.order_date) {
      errors.order_date = "*";
    }
    if (!data.brand) {
      errors.brand = "*";
    }

    if (!data.frame) {
      errors.frame = "*";
    }
    if (!data.model_number) {
      errors.model_number = "*";
    }
    if (!data.frame_color) {
      errors.frame_color = "*";
    }
    if (!data.frame_size) {
      errors.frame_size = "*";
    }
    if (!data.lens) {
      errors.lens = "*";
    }
    if (!data.lens_power) {
      errors.lens_power = "*";
    }
    if (!data.deliver_date) {
      errors.deliver_date = "*";
    }
    return errors;
  };

  const handleEditJobOrder = (job) => {
    console.log("@@@@*****@@@@", job);
    setid(job.id);
    setorder_date(moment(job.order_date).format("DD-MM-YYYY"));
    setfitter_name(job.fitter_name);
    setjob_order_number(job.job_order_number);
    // setbrand(job.brand);
    // setframe(job.frame);
    // setmodel_number(job.model_number);
    // setframe_color(job.frame_color);
    // setframe_size(job.frame_size);
    // setlens(job.lens);
    // setlens_power(job.lens_power);
    // setdeliver_date(job.deliver_date);

    window.scrollTo({
      top: 100,
      behavior: "smooth",
    });
  };
  // --------------  Sales Start ------------- //
  const [sales_data, setsales_data] = useState([]);
  const [sales_search, setsales_search] = useState("");

  const handleSearchSales = (val) => {
    console.log("###5555555####", val);
    setsales_search(val);
    dispatch(searchSales(val))
      .then((res) => {
        setsales_data(res.response.data.results);
      })
      .catch((err) => {
        setsearch("");
        dispatch(getAllSales(1, 10));
      });
  };

  //  const handleEditSales = (pur) => {
  //   // setid(pur.id);
  //    // setpur_date(moment(pur.pur_date).format("DD-MM-YYYY"))
  //   //  setbrand(pur.brand);
  //   //  setdescription(pur.description);
  //   //  setquantity(1);
  //   //  setmaxquantity(pur.quantity);
  //   //  setframe_rate(pur.frame_rate);
  //   //  setgst_amount(pur.gst_amount);
  //    window.scrollTo({
  //      top: 100,
  //      behavior: "smooth",
  //    });
  //  };

  const handleEditSales = (sal) => {
    console.log("@@@@*****@@@@", sal);

    // setcus_name(sal.cus_name);
    // setphone(sal.phone);
    // setplace(sal.place);

    setbrand(sal.brand);
    setbrand_val({ label: sal.brand, value: sal.brand });
    setmodel_number(sal.model_number);
    setmodel_number_val({ label: sal.model_number, value: sal.model_number });
    setframe_color(sal.frame_color);
    setframe_color_val({ label: sal.frame_color, value: sal.frame_color });
    setframe_size(sal.frame_size);
    setframe_size_val({ label: sal.frame_size, value: sal.frame_size });

    window.scrollTo({
      top: 100,
      behavior: "smooth",
    });
  };
  // --------------  Products End  -----------------//

  // ------------- Pagination start---------- //
  const [currentPage, setcurrentPage] = useState(1);
  const [itemsPerPage, setitemsPerPage] = useState(5);

  const [pageNumberLimit, setpageNumberLimit] = useState(5);
  const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
  const [minPageNumberLimit, setminPageNumberLimit] = useState(0);

  const [data, setData] = useState([]);

  const handleClick = (event) => {
    setcurrentPage(Number(event.target.id));
    dispatch(getAllJobOrder(Number(event.target.id), itemsPerPage));
  };

  const pages = [];
  for (let i = 1; i <= Math.ceil(tot / itemsPerPage); i++) {
    pages.push(i);
  }

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  //const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  useEffect(() => {
    dispatch(getAllJobOrder(currentPage, itemsPerPage));
  }, [currentPage, itemsPerPage]);

  const handleNextbtn = () => {
    setcurrentPage(currentPage + 1);
    // dispatch(getAllJobOrder(currentPage))
    if (currentPage + 1 > maxPageNumberLimit) {
      setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
  };

  const handlePrevbtn = () => {
    setcurrentPage(currentPage - 1);
    if ((currentPage - 1) % pageNumberLimit == 0) {
      setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
    }
  };

  let pageIncrementBtn = null;
  if (pages.length > maxPageNumberLimit) {
    pageIncrementBtn = <li onClick={handleNextbtn}> &hellip;+ </li>;
  }

  let pageDecrementBtn = null;
  if (minPageNumberLimit >= 1) {
    pageDecrementBtn = <li onClick={handlePrevbtn}> &hellip;- </li>;
  }

  const renderPageNumbers = pages.map((number) => {
    if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
      return (
        <li
          key={number}
          id={number}
          onClick={handleClick}
          className={currentPage == number ? "active" : null}
        >
          {number}
        </li>
      );
    } else {
      return null;
    }
  });

  const handleLoadMore = () => {
    setitemsPerPage(itemsPerPage + 5);
    setmaxPageNumberLimit(5);
    setminPageNumberLimit(0);
    setcurrentPage(1);
  };

  // ------------- Pagination end---------- //

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#f00";
  }

  function closeModal() {
    setIsOpen(false);
  }

  // var subtotal = joborder.reduce(
  //   (x, job) => x + job.bill_amount * job.quantity,
  //   0
  // );

  return (
    <>
      <AdminNavbar />
      <br />
      {/* <p style={{fontSize:'2.5rem'}} className="text-center">BILL (PRODUCTS)</p> */}
      <div className="row">
        <div className="col-md-6">
          <ViewSales
            sales={sales_data}
            search={sales_search}
            from_date={from_date}
            to_date={to_date}
            cp={currentPage}
            // onHandleUpdate={handleUpdate}
            onHandleDelete={handleDelete}
            onHandleSearchSales={handleSearchSales}
            onHandleFromDate={(date) => {
              setfrom_date(moment(date).format("DD-MM-YYYY"));
            }}
            onHandleToDate={(date) => {
              setto_date(moment(date).format("DD-MM-YYYY"));
            }}
            onHandleSearchDate={handleSearchdate}
            onBillFetch={Bill}
            onHandleSwitchChange={handleSwitchChange}
            onHandleEditChange={handleEditSales}
          />
        </div>
        <div className="col-md-6">
          <Section>
            <>
              {loading && <Loading />}
              {error && <Error success="Something Went Wrong" />}
              {success && <Success success="Inserted Successfully" />}

              <ul className="wrapper">
                <li className="form-row">
                  <label htmlFor="name" style={{ textAlign: "left" }}>
                    Fitter Name
                  </label>
                  <div style={{ width: "65%" }}>
                    <Select
                      value={fitter_name_val}
                      onChange={(e) => {
                        setfitter_name_val(e);
                        setfitter_name(e.value);
                      }}
                      options={fittername_options}
                      placeholder={"Search..."}
                      styles={customStyles}
                    />
                  </div>
                </li>

                <li className="form-row">
                  <label htmlFor="name" style={{ textAlign: "left" }}>
                    Order Date
                  </label>
                  <div className="customDatePickerWidth">
                    <DatePicker
                      value={order_date !== "Invalid date" ? order_date : ""}
                      onChange={(date) => {
                        setorder_date(moment(date).format("DD-MM-YYYY"));
                      }}
                      dateFormat="yyyy-MM-dd"
                      placeholderText="dd-mm-yyyy"
                      monthsShown={1}
                      showYearDropdown
                    />
                    {errors.order_date && (
                      <OnLineErrors text={errors.order_date} />
                    )}
                  </div>
                </li>

                <li className="form-row">
                  <label htmlFor="name">Brand</label>
                  <div style={{ width: "65%" }}>
                    <Select
                      value={brand_val}
                      onChange={(e) => {
                        setbrand_val(e);
                        setbrand(e.value);
                      }}
                      options={brandname_options}
                      placeholder={"Search..."}
                      styles={customStyles}
                    />
                  </div>
                </li>

                <li className="form-row">
                  <label htmlFor="name" style={{ textAlign: "left" }}>
                    Frame
                  </label>
                  <div style={{ width: "65%" }}>
                    <Select
                      value={frame}
                      onChange={(e) => {
                        setframe(e.value);
                      }}
                      options={optionsframe}
                      placeholder={frame}
                      styles={customStyles_frame}
                    />
                  </div>
                </li>

                <li className="form-row">
                  <label htmlFor="name">Model Number</label>
                  <div style={{ width: "65%" }}>
                    <Select
                      value={model_number_val}
                      onChange={(e) => {
                        setmodel_number_val(e);
                        setmodel_number(e.value);
                      }}
                      options={modelnumname_options}
                      placeholder={"Search..."}
                      styles={customStyles_model_num}
                    />
                  </div>
                </li>

                <li className="form-row">
                  <label htmlFor="name"> Frame Color</label>
                  <div style={{ width: "65%" }}>
                    <Select
                      value={frame_color_val}
                      onChange={(e) => {
                        setframe_color_val(e);
                        setframe_color(e.value);
                      }}
                      options={framecolorname_options}
                      placeholder={"Search..."}
                      styles={customStyles}
                    />
                  </div>
                </li>

                <li className="form-row">
                  <label htmlFor="name">Frame Size</label>
                  <div style={{ width: "65%" }}>
                    <Select
                      value={frame_size_val}
                      onChange={(e) => {
                        setframe_size_val(e);
                        setframe_size(e.value);
                      }}
                      options={options_framesize}
                      placeholder={is_edit ? frame_size : "Select..."}
                      styles={customStyles}
                    />
                  </div>
                </li>

                <li className="form-row">
                  <label htmlFor="name" style={{ textAlign: "left" }}>
                    Lens
                  </label>
                  <div style={{ width: "65%" }}>
                    <Select
                      value={lens}
                      onChange={(e) => {
                        setlens(e.value);
                      }}
                      options={optionslens}
                      placeholder={lens}
                      styles={customStyles_lens}
                    />
                  </div>
                </li>

                <li className="form-row">
                  <label htmlFor="name">Lens Power</label>
                  <div style={{ width: "65%" }}>
                    <Select
                      value={lens_power_val}
                      onChange={(e) => {
                        setlens_power_val(e);
                        setlens_power(e.value);
                      }}
                      options={lenspower_name_options}
                      placeholder={"Search..."}
                      styles={customStyles_lenspower}
                    />
                  </div>
                </li>

                <li className="form-row">
                  <label htmlFor="name" style={{ textAlign: "left" }}>
                    Delivery Date
                  </label>
                  <div className="customDatePickerWidth">
                    <DatePicker
                      value={order_date !== "Invalid date" ? deliver_date : ""}
                      onChange={(date) => {
                        setdeliver_date(moment(date).format("DD-MM-YYYY"));
                      }}
                      dateFormat="yyyy-MM-dd"
                      placeholderText="dd-mm-yyyy"
                      monthsShown={1}
                      showYearDropdown
                    />
                    {errors.deliver_date && (
                      <OnLineErrors text={errors.deliver_date} />
                    )}
                  </div>
                </li>

                {/* <li className="form-row">
                  <label htmlFor="name" style={{ textAlign: "left" }}>
                    Amount
                  </label>
                  <TextField
                    className="txtfld"
                    id="outlined-basic"
                    error={errors.bill_amount ? true : false}
                    type="number"
                    variant="outlined"
                    fullWidth={false}
                    // onChange={(e) => {setbill_amount(e.target.value)}}
                    value={bill_amount * quantity}
                  />
                </li> */}

                <li className="form-row">
                  <Button variant="contained" color="primary" onClick={Submit}>
                    {" "}
                    {is_edit === false ? "Submit" : "Update"}{" "}
                  </Button>
                </li>
              </ul>
            </>
          </Section>
        </div>
      </div>

      <ViewJobOrder
        joborder={data}
        search={search}
        from_date={from_date}
        to_date={to_date}
        cp={currentPage}
        // onHandleUpdate={handleUpdate}
        onHandleDelete={handleDelete}
        onHandleSearch={handleSearch}
        onHandleFromDate={(date) => {
          setfrom_date(moment(date).format("DD-MM-YYYY"));
        }}
        onHandleToDate={(date) => {
          setto_date(moment(date).format("DD-MM-YYYY"));
        }}
        onHandleSearchDate={handleSearchdate}
        onBillFetch={Bill}
        onHandleSwitchChange={handleSwitchChange}
        onHandleEditChange={handleEditJobOrder}
      />
      {fitter_name.length > 1 || search.length > 0 ? null : (
        <>
          <div className="pageNumbers">
            <button
              onClick={handlePrevbtn}
              disabled={currentPage == pages[0] ? true : false}
            >
              {" "}
              &lt;&lt;
            </button>
            {pageDecrementBtn}
            {renderPageNumbers}
            {pageIncrementBtn}
            <button
              onClick={handleNextbtn}
              disabled={currentPage == pages[pages.length - 1] ? true : false}
            >
              {" "}
              &gt;&gt;
            </button>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "20px 50px",
            }}
          >
            <button onClick={handleLoadMore} className="loadmore">
              Load More
            </button>
          </div>
        </>
      )}

      {/*  */}
      <div style={{ textAlign: "center" }}>
        {/* <button onClick={openModal}>Open Modal</button> */}
        <Modal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          //style={customStyles}
          contentLabel="Example Modal"
          ariaHideApp={false}
        >
          <div
            className="row"
            style={{ backgroundColor: "#ffecf2", marginTop: "-1rem" }}
          >
            <div className="col-print-6">
              <h2
                ref={(_subtitle) => (subtitle = _subtitle)}
                onClick={handlePrint}
                style={{ textAlign: "left", cursor: "pointer" }}
              >
                Print
              </h2>
            </div>
            <div className="col-print-6">
              <button
                onClick={closeModal}
                style={{
                  float: "right",
                  backgroundColor: "#ffecf2",
                  fontSize: "2.5rem",
                }}
              >
                X
              </button>
            </div>
          </div>
          <>
            <style>{getPageMargins()}</style>

            <div ref={componentRef}>
              <div
                className="row"
                style={{ textAlign: "left", marginTop: "3rem" }}
              >
                <div className="col-print-1"></div>
                <div className="col-print-11">
                  <div className="row">
                    <div className="col-print-0"> </div>
                    <div className="col-print-9">
                      {/* <h2 style={{textAlign:'left'}}>Logo</h2>   */}
                      <img
                        src={logo}
                        style={{
                          textAlign: "left",
                          height: "5rem",
                          width: "8rem",
                        }}
                      />
                    </div>
                    <div className="col-print-3">
                      <h2 style={{ textAlign: "left", marginTop: "3rem" }}>
                        Reciept Detail
                      </h2>
                    </div>
                  </div>
                </div>
                <div className="col-print-0"></div>
              </div>

              <div
                className="row"
                style={{ textAlign: "center", marginTop: "2rem" }}
              >
                <div className="col-print-1"> </div>
                <div className="col-print-9">
                  <p style={{ textAlign: "left", fontSize: "130%" }}>
                    Nesi Optical Shop <br /> No.6, Gandhipuram Main Road, Karur,{" "}
                    <br />
                    Backside Of EB Office - 639002 <br />
                    +91 9894670007{" "}
                  </p>
                </div>
                <div className="col-print-3"> </div>
              </div>

              <div
                className="row"
                style={{ textAlign: "center", marginTop: "2rem" }}
              >
                <div className="col-print-1"></div>
                <div className="col-print-7">
                  <h5 style={{ textAlign: "left", fontSize: "130%" }}>
                    Job Order To
                  </h5>
                  <p style={{ textAlign: "left", fontSize: "130%" }}>
                    {fitter_name} <br />{" "}
                  </p>
                </div>
                <div className="col-print-4">
                  {/* <h5 style={{textAlign:'left',fontSize:'130%'}}>Invoice# : INV-12</h5>   */}
                  <p style={{ textAlign: "left", fontSize: "130%" }}>
                    Report Date: {tdy_date}{" "}
                  </p>
                </div>
              </div>

              <div
                className="row"
                style={{ textAlign: "center", marginTop: "4rem" }}
              >
                <div className="col-print-1"></div>
                <div className="col-print-10">
                  <table className="table  table-responsive">
                    <thead
                      style={{
                        backgroundColor: "#fff",
                        color: "#1a4567",
                        fontSize: "150%",
                      }}
                    >
                      <tr>
                        <th style={{ width: "100%" }}>Job.No/ Model.No</th>
                        <th style={{ width: "100%" }}>(Order/Deliver)</th>

                        <th style={{ width: "100%" }}>Brand</th>
                        <th style={{ width: "100%" }}>Frame</th>

                        <th style={{ width: "100%" }}>Details</th>
                      </tr>
                    </thead>
                    <tbody>
                      {joborder.length > 0 ? (
                        joborder.map((job, index) => {
                          return (
                            <tr key={index} style={{ fontSize: "130%" }}>
                              <td>
                                {" "}
                                {job.job_order_number} <br /> (
                                {job.model_number})
                              </td>
                              <td>
                                {moment(job.order_date).format("DD-MM-YYYY")}
                                <br />
                                {moment(job.deliver_date).format("DD-MM-YYYY")}
                              </td>

                              <td> {job.brand} </td>
                              <td> {job.frame} </td>

                              <td>
                                {job.frame_color}/ {job.frame_size}/ {job.lens}/
                                {job.lens_power}
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td>No Data</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  <hr />

                  <div style={{ marginTop: "2rem" }}>
                    <br></br>
                    <p
                      style={{
                        textAlign: "right",
                        marginRight: "2rem",
                        fontSize: "130%",
                      }}
                    >
                      {/* SubTotal : {Math.round(subtotal)} */}
                    </p>
                    {/* <p
                      style={{
                        textAlign: "right",
                        marginRight: "2rem",
                        fontSize: "130%",
                      }}
                    >
                      SGST : {Math.round(subtotal * 0.09)}
                    </p>
                    <p
                      style={{
                        textAlign: "right",
                        marginRight: "2rem",
                        fontSize: "130%",
                      }}
                    >
                      CGST : {Math.round(subtotal * 0.09)}
                    </p> */}
                    <p
                      style={{
                        textAlign: "right",
                        marginRight: "2rem",
                        fontSize: "150%",
                        fontWeight: "bold",
                      }}
                    >
                      {/* Total Amount: <span>&#8377;</span> {} */}
                    </p>
                  </div>
                </div>
                <div className="col-print-1"></div>
              </div>

              <div className="footer">
                <p>THANK YOU FOR YOUR BUSINESS </p>
              </div>
            </div>
          </>
        </Modal>
      </div>
      {/*  */}
    </>
  );
}

const marginTop = "20px";
const marginRight = "5px";
const marginBottom = "10px";
const marginLeft = "5px";
const getPageMargins = () => {
  return `@page { margin: ${marginTop} ${marginRight} ${marginBottom} ${marginLeft} !important; }`;
};

const Section = styled.section`
  margin: 10px auto;
  max-width: 650px !important;
  .wrapper {
    background-color: #ffffff;
    border: 1px solid #f0932b;
    list-style-type: none;
    padding: 10px 50px;
    border-radius: 10px;
  }

  .form-row {
    display: flex;
    justify-content: flex-end;
    padding: 0.3rem;
  }
  .form-row > label {
    padding: 0.5em 1em 0.5em 0;
    flex: 1;
    color: #000000;
  }

  .form-row .txtfld {
    flex: 2;
  }

  @media screen and (min-width: 280px) and (max-width: 720px) {
    .form-row {
      display: flex;
      flex-direction: column;
    }
  }
  @media screen and (min-width: 720px) and (max-width: 1080px) {
    .form-row {
      display: flex;
      flex-direction: column;
    }
  }
`;

import axios from "axios";
import { apiUrl } from "../../config";

export const addLensPowerMaster = (acc) => async (dispatch) => {
  try {
    const response = await axios.post(
      `${apiUrl}/api/lens-power-master/create`,
      acc
    );

    return {
      response,
    };
  } catch (error) {}
};

export const getAllLensPowerMasterLength = () => async (dispatch) => {
  try {
    const response = await axios.get(
      `${apiUrl}/api/lens-power-master/getAllLensPowerMasterLength`
    );
    console.log("%%%111%%%", response.data.results[0].tot);
    return { response };
  } catch (error) {}
};

export const getAllLensPowerMaster =
  (offset, row_count) => async (dispatch) => {
    try {
      const response = await axios.get(
        `${apiUrl}/api/lens-power-master/getAllLensPowerMaster/${offset}/${row_count}`
      );
      dispatch({
        type: "GET_LENSPOWER_SUCCESS",
        payload: response.data.results,
      });
    } catch (error) {}
  };

export const updateLensPowerMaster = (pur) => async (dispatch) => {
  try {
    const response = await axios.post(
      `${apiUrl}/api/lens-power-master/update`,
      pur
    );
    return { response };
    // window.location.reload()
  } catch (error) {
    alert("Something Went Wrong");
    console.log(error);
  }
};

export const deleteLensPowerMaster = (accid) => async (dispatch) => {
  try {
    const response = await axios.post(
      `${apiUrl}/api/lens-power-master/delete`,
      {
        accid,
      }
    );
    return { response };
    // window.location.reload()
  } catch (error) {
    alert("Something Went Wrong");
    console.log(error);
  }
};

export const searchLensPowerMaster = (val) => async (dispatch) => {
  const data = { id: val };
  try {
    const response = await axios.post(
      `${apiUrl}/api/lens-power-master/search`,
      data
    );
    return { response };
    // window.location.reload()
  } catch (error) {
    return { error };
  }
};

export const getAllLensPowerNames = () => async (dispatch) => {
  try {
    const response = await axios.get(
      `${apiUrl}/api/lens-power-master/getallLensPowerNames`
    );
    //console.log('##123456##',response.data.results)
    dispatch({ type: "GET_LPNAME_SUCCESS", payload: response.data.results });
  } catch (error) {}
};

import axios from "axios";
import { apiUrl } from "../../config";

export const addAdminPurchase = (acc) => async (dispatch) => {
  console.log("ACTION------>", acc);
  dispatch({ type: "ADD_ADMIN_PURCHASE_REQUEST" });
  try {
    const response = await axios.post(
      `${apiUrl}/api/admin-purchase/create`,
      acc
    );
    console.log("+++ add", response);
    dispatch({ type: "ADD_ADMIN_PURCHASE_SUCCESS" });
    return {
      response,
    };
  } catch (error) {
    dispatch({ type: "ADD_ADMIN_PURCHASE_FAILED", payload: error });
  }
};

export const allAdminPurchaseClear = (acc) => async (dispatch) => {
  try {
    dispatch({ type: "ALL_ADMIN_PURCHASE_CLEAR" });
  } catch (error) {
    dispatch({ type: "ALL_ADMIN_PURCHASE_FAILED", payload: error });
  }
};

export const getAllAdminPurchaseLength = () => async (dispatch) => {
  try {
    const response = await axios.get(
      `${apiUrl}/api/admin-purchase/getallpurchase_length`
    );
    //console.log('%%%111%%%',response.data.results[0].tot);
    return { response };
  } catch (error) {}
};

export const getAllAdminPurchase = (offset, row_count) => async (dispatch) => {
  dispatch({ type: "GET_ADMIN_PURCHASE_REQUEST" });

  try {
    const response = await axios.get(
      `${apiUrl}/api/admin-purchase/getallpurchase/${offset}/${row_count}`
    );
    // console.log('%%%%%%',response);
    dispatch({
      type: "GET_ADMIN_PURCHASE_SUCCESS",
      payload: response.data.results,
    });
  } catch (error) {
    dispatch({ type: "GET_ADMIN_PURCHASE_FAILED", payload: error });
  }
};

export const updateAdminPurchase = (pur) => async (dispatch) => {
  try {
    const response = await axios.post(
      `${apiUrl}/api/admin-purchase/update`,
      pur
    );
    return { response };
    // window.location.reload()
  } catch (error) {
    alert("Something Went Wrong");
    console.log(error);
  }
};

export const updateAdminPurchaseQuantity = (quan) => async (dispatch) => {
  console.log("++++++======+++++++", quan);
  const data = {
    id: quan.id,
    quantity: quan.quantity,
    maxquantity: quan.maxquantity,
  };
  try {
    const response = await axios.post(
      `${apiUrl}/api/admin-purchase/updatepurchasequantity`,
      data
    );
    return { response };
    // window.location.reload()
  } catch (error) {
    alert("Something Went Wrong");
    console.log(error);
  }
};

export const deleteAdminPurchase = (accid) => async (dispatch) => {
  try {
    const response = await axios.post(`${apiUrl}/api/admin-purchase/delete`, {
      accid,
    });
    return { response };
    // window.location.reload()
  } catch (error) {
    alert("Something Went Wrong");
    console.log(error);
  }
};

export const deleteAdminPurchaseQuantity = (quan) => async (dispatch) => {
  console.log("++++++======+++++++", quan);
  const data = { id: quan.p_id, quantity: quan.quantity };
  try {
    const response = await axios.post(
      `${apiUrl}/api/admin-purchase/deletepurchasequantity`,
      data
    );
    return { response };
    // window.location.reload()
  } catch (error) {
    alert("Something Went Wrong");
    console.log(error);
  }
};

export const searchAdminPurchase = (val) => async (dispatch) => {
  const data = { id: val };
  try {
    const response = await axios.post(
      `${apiUrl}/api/admin-purchase/search`,
      data
    );
    return { response };
    // window.location.reload()
  } catch (error) {
    return { error };
    //alert('Something Went Wrong')
    console.log(error);
  }
};

export const filterAdminPurchase = (data) => async (dispatch) => {
  try {
    const response = await axios.post(
      `${apiUrl}/api/admin-purchase/filterdate`,
      data
    );
    return { response };
    // window.location.reload()
  } catch (error) {
    return { error };
    //alert('Something Went Wrong')
    console.log(error);
  }
};

export const getAllCompanyNames = () => async (dispatch) => {
  dispatch({ type: "GET_CNAME_REQUEST" });
  try {
    const response = await axios.get(
      `${apiUrl}/api/supplier-master/getallCompanyNames`
    );
    //console.log('##123456##',response.data.results)
    dispatch({ type: "GET_CNAME_SUCCESS", payload: response.data.results });
  } catch (error) {
    dispatch({ type: "GET_CNAME_FAILED", payload: error });
  }
};

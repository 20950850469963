import axios from "axios";
import { apiUrl } from "../../config";

export const loginUser = (user) => async (dispatch) => {
  dispatch({ type: "USER_LOGIN_REQUEST" });

  try {
    const response = await axios.post(`${apiUrl}/api/login/login`, user);
    console.log("------RES------", response);
    dispatch({ type: "USER_LOGIN_SUCCESS", payload: response.data });
    window.location.href = "/sales";
  } catch (error) {
    dispatch({ type: "USER_LOGIN_FAILED", payload: error });
  }
};

export const logoutUser = () => async (dispatch) => {
  dispatch({ type: "USER_LOGOUT", payload: "" });
  //  dispatch({ type: "GET_PURCHASE_SUCCESS", payload: "" });
  window.location.href = "/login";
};

export const getAllUsers = () => async (dispatch) => {
  dispatch({ type: "GET_USERS_REQUEST" });
  try {
    const response = await axios.get("/api/users/getallusers");
    console.log("+++", response);
    dispatch({ type: "GET_USERS_SUCCESS", payload: response.data });
  } catch (error) {
    dispatch({ type: "GET_USERS_FAILED", payload: error });
  }
};

export const deleteUser = (userid) => async (dispatch) => {
  try {
    const response = await axios.post("/api/users/deleteuser", { userid });
    alert("User Deleted Successfully");
    window.location.reload();
  } catch (error) {
    alert("Something Went Wrong");
    console.log(error);
  }
};

import { combineReducers } from "redux";
//import { createStore, applyMiddleware } from "redux";
import { configureStore } from "@reduxjs/toolkit";
//import thunk from "redux-thunk";
//import {composeWithDevTools} from 'redux-devtools-extension'

import { loginUserReducer } from "./reducers/userReducers";

import {
  getAddPurchaseReducer,
  getAllPurchaseReducer,
} from "./reducers/purchaseReducers";
import {
  getAddAdminPurchaseReducer,
  getAllAdminPurchaseReducer,
} from "./reducers/adminPurchaseReducers";
import {
  getAddSupplierMasterReducer,
  getAllSupplierMasterReducer,
  getAllCompanyNamesReducer,
} from "./reducers/supplierMasterReducers";
import { getAddEstReducer, getAllEstReducer } from "./reducers/estReducers";
import { getAddJobReducer, getAllJobReducer } from "./reducers/jobReducers";
import {
  getAddSalesReducer,
  getAllSalesReducer,
} from "./reducers/salesReducers";
import {
  getAddBillsReducer,
  getAllBillsReducer,
} from "./reducers/billsReducers";
import {
  getAddJobOrderReducer,
  getAllJobOrderReducer,
} from "./reducers/jobOrderReducers";

import {
  getAllBrandMasterReducer,
  getAllBrandNamesReducer,
} from "./reducers/brandMasterReducers";

import {
  getAllModelNumberMasterReducer,
  getAllModelNumberNamesReducer,
} from "./reducers/modelNumberMasterReducers";

import {
  getAllFrameColorMasterReducer,
  getAllFrameColorNamesReducer,
} from "./reducers/frameColorMasterReducers";

import {
  getAllLensPowerMasterReducer,
  getAllLensPowerNamesReducer,
} from "./reducers/lensPowerMasterReducers";

import {
  getAllLensRateMasterReducer,
  getAllLensRateNamesReducer,
} from "./reducers/lensRateMasterReducers";

import {
  getAllFitterMasterReducer,
  getAllFitterNamesReducer,
} from "./reducers/fitterMasterReducers";

import { persistReducer, PERSIST } from "redux-persist";
import { encryptTransform } from "redux-persist-transform-encrypt";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "root",
  storage,
  stateReconciler: autoMergeLevel2,
  transforms: [
    encryptTransform({
      secretKey: "my-research-super-secret-key",
      onError: function (error) {
        // Handle the error.
      },
    }),
  ],
};

const finalReducer = combineReducers({
  loginUserReducer: loginUserReducer,

  getAddPurchaseReducer: getAddPurchaseReducer,
  getAllPurchaseReducer: getAllPurchaseReducer,
  getAddAdminPurchaseReducer: getAddAdminPurchaseReducer,
  getAllAdminPurchaseReducer: getAllAdminPurchaseReducer,

  getAddSupplierMasterReducer: getAddSupplierMasterReducer,
  getAllSupplierMasterReducer: getAllSupplierMasterReducer,
  getAllCompanyNamesReducer: getAllCompanyNamesReducer,

  getAddSalesReducer: getAddSalesReducer,
  getAllSalesReducer: getAllSalesReducer,
  getAddBillsReducer: getAddBillsReducer,
  getAllBillsReducer: getAllBillsReducer,
  getAddJobOrderReducer: getAddJobOrderReducer,
  getAllJobOrderReducer: getAllJobOrderReducer,

  getAllBrandMasterReducer: getAllBrandMasterReducer,
  getAllBrandNamesReducer: getAllBrandNamesReducer,

  getAllModelNumberMasterReducer: getAllModelNumberMasterReducer,
  getAllModelNumberNamesReducer: getAllModelNumberNamesReducer,

  getAllFrameColorMasterReducer: getAllFrameColorMasterReducer,
  getAllFrameColorNamesReducer: getAllFrameColorNamesReducer,
  getAllLensPowerMasterReducer: getAllLensPowerMasterReducer,
  getAllLensPowerNamesReducer: getAllLensPowerNamesReducer,

  getAllLensRateMasterReducer: getAllLensRateMasterReducer,
  getAllLensRateNamesReducer: getAllLensRateNamesReducer,

  getAllFitterMasterReducer: getAllFitterMasterReducer,
  getAllFitterNamesReducer: getAllFitterNamesReducer,

  getAddEstReducer: getAddEstReducer,
  getAllEstReducer: getAllEstReducer,
  getAddJobReducer: getAddJobReducer,
  getAllJobReducer: getAllJobReducer,
});

const persistedReducer = persistReducer(persistConfig, finalReducer);

//const composeEnhancers = composeWithDevTools({})
//const store = createStore(persistedReducer, composeEnhancers(applyMiddleware(thunk)))

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [PERSIST],
      },
    }),
});

export default store;

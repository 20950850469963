import axios from "axios";
import { apiUrl } from "../../config";

export const addBills = (acc) => async (dispatch) => {
  console.log("ACTION------>", acc);
  dispatch({ type: "ADD_BILLS_REQUEST" });
  try {
    const response = await axios.post(`${apiUrl}/api/bills/create`, acc);
    console.log("+++ add", response);
    dispatch({ type: "ADD_BILLS_SUCCESS" });
    return {
      response,
    };
  } catch (error) {
    dispatch({ type: "ADD_BILLS_FAILED", payload: error });
  }
};

export const allBillsClear = (acc) => async (dispatch) => {
  try {
    dispatch({ type: "ALL_BILLS_CLEAR" });
  } catch (error) {
    dispatch({ type: "ALL_BILLS_FAILED", payload: error });
  }
};

export const getAllBillsLength = () => async (dispatch) => {
  try {
    const response = await axios.get(`${apiUrl}/api/bills/getallbills_length`);
    //console.log('%%%111%%%',response.data.results[0].tot);
    return { response };
  } catch (error) { }
};

export const getAllBillsbycus = (
  offset,
  row_count,
  cus_name,
  phone
) => async (dispatch) => {

  dispatch({ type: "GET_BILLS_REQUEST" });

  try {
    const response = await axios.get(
      `${apiUrl}/api/bills/getallbillsbycus/${offset}/${row_count}/${cus_name}/${phone}`
    );
    console.log("%%%%%%", response);
    dispatch({ type: "GET_BILLS_SUCCESS", payload: response.data.results });
  } catch (error) {
    console.log("EEEEEEEE", error);
    dispatch({ type: "GET_BILLS_FAILED", payload: error });
  }
};

export const getAllBills = (offset, row_count) => async (dispatch) => {
  dispatch({ type: "GET_BILLS_REQUEST" });

  try {
    const response = await axios.get(
      `${apiUrl}/api/bills/getallBills/${offset}/${row_count}`
    );
    //console.log('%%%%%%',response);
    dispatch({ type: "GET_BILLS_SUCCESS", payload: response.data.results });
  } catch (error) {
    console.log("EEEEEEEE", error);
    dispatch({ type: "GET_BILLS_FAILED", payload: error });
  }
};

export const updateBills = (pur) => async (dispatch) => {
  try {
    const response = await axios.post(`${apiUrl}/api/bills/update`, pur);
    return { response };
    // window.location.reload()
  } catch (error) {
    alert("Something Went Wrong");
    console.log(error);
  }
};

export const deleteBills = (accid) => async (dispatch) => {
  try {
    const response = await axios.post(`${apiUrl}/api/bills/delete`, { accid });
    return { response };
    // window.location.reload()
  } catch (error) {
    alert("Something Went Wrong");
    console.log(error);
  }
};

export const getBill = (id, cus_name) => async (dispatch) => {
  dispatch({ type: "GET_BILLS_REQUEST" });

  try {
    const response = await axios.get(
      `${apiUrl}/api/bills/getbill/${id}/${cus_name}`
    );
    console.log("%%%%%%", response);
    dispatch({ type: "GET_BILLS_SUCCESS", payload: response.data.results });
  } catch (error) {
    dispatch({ type: "GET_BILLS_FAILED", payload: error });
  }
};

export const searchBills = (val) => async (dispatch) => {
  // console.log('------------', val.length);
  if (val.length == 0) {
    window.location.reload()
  }
  const data = { id: val };
  try {
    const response = await axios.post(`${apiUrl}/api/bills/search`, data);
    return { response };
    // window.location.reload()
  } catch (error) {
    return { error };
    //alert('Something Went Wrong')
    console.log(error);
  }
};



export const CheckedBillBills = (id, val) => async (dispatch) => {
  const data = { id, val };
  try {
    const response = await axios.post(`${apiUrl}/api/bills/checked`, data);
    return { response };

  } catch (error) {
    return { error };
    //alert('Something Went Wrong')
    console.log(error);
  }
};

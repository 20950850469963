import axios from "axios";
import { apiUrl } from "../../config";

export const addJobOrder = (acc) => async (dispatch) => {
  console.log("ACTION------>", acc);
  dispatch({ type: "ADD_JOBORDER_REQUEST" });
  try {
    const response = await axios.post(`${apiUrl}/api/job-order/create`, acc);
    console.log("+++ add", response);
    dispatch({ type: "ADD_JOBORDER_SUCCESS" });
    return {
      response,
    };
  } catch (error) {
    dispatch({ type: "ADD_JOBORDER_FAILED", payload: error });
  }
};

export const allJobOrderClear = (acc) => async (dispatch) => {
  try {
    dispatch({ type: "ALL_JOBORDER_CLEAR" });
  } catch (error) {
    dispatch({ type: "ALL_JOBORDER_FAILED", payload: error });
  }
};

export const getAllJobOrderLength = () => async (dispatch) => {
  try {
    const response = await axios.get(
      `${apiUrl}/api/job-order/getAllJobOrderLength`
    );

    //console.log("CHECK", response.data.results[0].tot);
    return { response };
  } catch (error) {}
};

export const getAllJobOrderbyVen =
  (offset, row_count, fitter_name, from_date) => async (dispatch) => {
    console.log("GGGGGGG", from_date);
    dispatch({ type: "GET_JOBORDER_REQUEST" });

    try {
      const response = await axios.get(
        `${apiUrl}/api/job-order/getallJobOrderbyVen/${offset}/${row_count}/${fitter_name}/${from_date}`
      );
      //console.log("%%%%%%", response);
      dispatch({
        type: "GET_JOBORDER_SUCCESS",
        payload: response.data.results,
      });
    } catch (error) {
      console.log("EEEEEEEE", error);
      dispatch({ type: "GET_JOBORDER_FAILED", payload: error });
    }
  };

export const getAllJobOrder = (offset, row_count) => async (dispatch) => {
  dispatch({ type: "GET_JOBORDER_REQUEST" });

  try {
    const response = await axios.get(
      `${apiUrl}/api/job-order/getAllJobOrder/${offset}/${row_count}`
    );
    // console.log("%%%%%%", response);
    dispatch({ type: "GET_JOBORDER_SUCCESS", payload: response.data.results });
  } catch (error) {
    console.log("EEEEEEEE", error);
    dispatch({ type: "GET_JOBORDER_FAILED", payload: error });
  }
};

// export const updateJobOrder = (pur) => async (dispatch) => {
//   try {
//     const response = await axios.post(`${apiUrl}/api/job-order/update`, pur);
//     return { response };
//     // window.location.reload()
//   } catch (error) {
//     alert("Something Went Wrong");
//     console.log(error);
//   }
// };

export const deleteJobOrder = (accid) => async (dispatch) => {
  try {
    const response = await axios.post(`${apiUrl}/api/job-order/delete`, {
      accid,
    });
    return { response };
    // window.location.reload()
  } catch (error) {
    alert("Something Went Wrong");
    console.log(error);
  }
};

export const getBill = (id, fitter_name) => async (dispatch) => {
  dispatch({ type: "GET_JOBORDER_REQUEST" });

  try {
    const response = await axios.get(
      `${apiUrl}/api/job-order/getbill/${id}/${fitter_name}`
    );
    console.log("%%%%%%", response);
    dispatch({ type: "GET_JOBORDER_SUCCESS", payload: response.data.results });
  } catch (error) {
    dispatch({ type: "GET_JOBORDER_FAILED", payload: error });
  }
};

export const searchJobOrder = (val) => async (dispatch) => {
  const data = { id: val };
  try {
    const response = await axios.post(`${apiUrl}/api/job-order/search`, data);
    return { response };
    // window.location.reload()
  } catch (error) {
    return { error };
    //alert('Something Went Wrong')
    console.log(error);
  }
};

export const filterJobOrder = (data) => async (dispatch) => {
  try {
    const response = await axios.post(
      `${apiUrl}/api/job-order/filterdate`,
      data
    );
    return { response };
    // window.location.reload()
  } catch (error) {
    return { error };
    //alert('Something Went Wrong')
    console.log(error);
  }
};

export const CheckedBillJobOrder = (id, val) => async (dispatch) => {
  const data = { id, val };
  try {
    const response = await axios.post(`${apiUrl}/api/job-order/checked`, data);
    return { response };
    // window.location.reload()
  } catch (error) {
    return { error };
    //alert('Something Went Wrong')
    console.log(error);
  }
};

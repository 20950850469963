import React, { useState, useEffect } from "react";
import styled from "styled-components";
import * as moment from "moment";
import { TextField, Button } from "@material-ui/core";
import DatePicker from "react-datepicker";
import Switch from "react-switch";

export default function ViewSales(props) {
  return (
    <>
      <Section>
        <div className="view__acc_container">
          <h1>Order Details</h1>
          <TextField
            className="txtfld"
            id="outlined-basic"
            variant="outlined"
            fullWidth={false}
            onChange={(e) => {
              props.onHandleSearchSales(e.target.value);
            }}
            value={props.search}
            placeholder="Search"
            style={{ backgroundColor: "white" }}
          />

          <table className="table table-bordered table-responsive-sm">
            <thead style={{ backgroundColor: "#1995AD", color: "#ffffff" }}>
              <tr>
                <th>S.No</th>
                <th>Brand</th>
                <th>ModelNumber</th>
                <th>FrameColor</th>
                <th>FrameSize</th>
                <th>BillNumber</th>
                {/* <th></th> */}
                {/* <th></th> */}
              </tr>
            </thead>
            <tbody
              style={{ maxHeight: "200px", overflowY: "auto", width: "100%" }}
            >
              {props.sales.length > 0 ? (
                props.sales.map((sal, index) => {
                  return (
                    <tr
                      key={index}
                      onClick={() => {
                        props.onHandleEditChange(sal);
                      }}
                    >
                      <td> {index + 1} </td>
                      {/* <td> {moment(sal.entry_date).format("DD-MM-YYYY")} </td> */}

                      <td> {sal.brand} </td>
                      <td>{sal.model_number}</td>
                      <td> {sal.frame_color} </td>
                      <td> {sal.frame_size} </td>
                      <td> {sal.bill_num} </td>
                      {/* <td>
                          <Switch
                            onChange={(val) => {
                              props.onHandleSwitchChange(sal, val);
                            }}
                            checked={sal.bill_checked === 1 ? true : false}
                          />
                        </td> */}
                      {/* <td style={{ cursor: "pointer" }}>
                          <i
                            className="fa fa-trash"
                            onClick={() => {
                              props.onHandleDelete(sal);
                            }}
                          ></i>
                        </td> */}
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td>No Data</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </Section>
    </>
  );
}

const Section = styled.section`
  .view__acc_container {
    margin: 0px 50px;
  }

  .view__filterbox__container {
    margin: 20px auto;
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    gap: 1rem;
    padding: 1rem;
    background-color: #a1d6e2;
  }
  .view__filterdate__container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    flex: 0.7;
  }
  .view__filtersearch__container {
    flex: 0.3;
    text-align: center;
  }

  @media screen and (min-width: 280px) and (max-width: 720px) {
    .view__filterbox__container {
      display: flex;
      flex-direction: column;
    }
    .view__filterdate__container {
      display: flex;
      flex-direction: column;
    }
  }
`;

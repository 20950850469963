export const getAddEstReducer = (state = {}, action) => {
  switch (action.type) {
    case "ADD_EST_REQUEST":
      return {
        loading: true,
        ...state,
      };
    case "ADD_EST_SUCCESS":
      return {
        loading: false,
        success: true,
      };
    case "ADD_EST_FAILED":
      return {
        loading: false,
        error: action.payload,
      };

    case "ALL_EST_CLEAR":
      return {
        loading: false,
        success: false,
      };

    default:
      return state;
  }
};

export const getAllEstReducer = (state = { est: [] }, action) => {
  switch (action.type) {
    case "GET_EST_REQUEST":
      return {
        loading: true,
        ...state,
      };
    case "GET_EST_SUCCESS":
      return {
        loading: false,
        est: action.payload,
      };
    case "GET_EST_FAILED":
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

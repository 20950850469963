

export const getAddBillsReducer = (state = {}, action) => {

    switch (action.type) {
        case 'ADD_BILLS_REQUEST': return {
            loading: true,
            ...state
        }
        case 'ADD_BILLS_SUCCESS': return {
            loading: false,
            success: true
        }
        case 'ADD_BILLS_FAILED': return {
            loading: false,
            error: action.payload
        }

        case 'ALL_BILLS_CLEAR': return {
            loading: false,
            success: false
        }

        default: return state
    }
}

export const getAllBillsReducer = (state = { bills: [] }, action) => {

    switch (action.type) {
        case 'GET_BILLS_REQUEST': return {
            loading: true,
            ...state
        }
        case 'GET_BILLS_SUCCESS': return {
            loading: false,
            bills: action.payload
        }
        case 'GET_BILLS_FAILED': return {
            loading: false,
            error: action.payload
        }
        default: return state
    }
}
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import "react-datepicker/dist/react-datepicker.css";

import AdminNavbar from "../../components/AdminNavbar/AdminNavbar";
import { useDispatch, useSelector } from "react-redux";
import jwt_decode from "jwt-decode";
import ViewBrandMaster from "./ViewBrandMaster";
import Sidebar from "../../components/Sidebar";
import { makeStyles } from "@material-ui/core/styles";
import {
  TextField,
  Button,
  Grid,
  Paper,
  FormControl,
  FormHelperText,
} from "@material-ui/core";
import {
  //
  addBrandMaster,
  getAllBrandMasterLength,
  getAllBrandMaster,
  updateBrandMaster,
  deleteBrandMaster,
  searchBrandMaster,
} from "../../redux/actions/brandMasterAction";
import "./pagestyle.css";

export default function BrandMaster() {
  const classes = useStyles();

  const [is_edit, setis_edit] = useState(false);
  const [id, setid] = useState("");

  const [brand_name, setbrand_name] = useState("");

  const [errors, seterrors] = useState([]);
  const [search, setsearch] = useState("");
  const [tot, setTot] = useState(0);

  const [from_date, setfrom_date] = useState("");
  const [to_date, setto_date] = useState("");

  const userState = useSelector((state) => state.loginUserReducer);
  const { currentUser } = userState;

  const all_brand_state = useSelector(
    (state) => state.getAllBrandMasterReducer
  );
  const { brand } = all_brand_state;

  //console.log("@@@@@ BRAND @@@@@", brand);

  useEffect(() => {
    if (currentUser.length > 0) {
      var decoded = jwt_decode(userState.currentUser);
      if (decoded.role !== "admin" && decoded.role !== "nesi") {
        window.location.href = "/login";
      }
    } else {
      window.location.href = "/login";
    }
    setData(brand);

    dispatch(getAllBrandMasterLength()).then((response) => {
      //console.log("#$#$#$#$#$#$#$", response.response.data.results[0].tot);
      setTot(response.response.data.results[0].tot);
    });
    dispatch(getAllBrandMaster(1, itemsPerPage));
  }, []);

  useEffect(() => {
    setData(brand);
  }, [brand]);

  useEffect(() => {
    if (search.length < 1) {
      dispatch(getAllBrandMaster(currentPage, itemsPerPage));
    }
  }, [search]);

  const dispatch = useDispatch();

  const handleUpdate = (cus) => {
    setis_edit(true);
    setid(cus.id);
    setbrand_name(cus.brand_name);
    window.scrollTo({
      top: 100,
      behavior: "smooth",
    });
  };

  const handleClear = () => {
    // console.log('------clear-------')
    setis_edit(false);
    setbrand_name("");
  };

  const handleDelete = (pur) => {
    console.log("####", pur.id);
    var option = window.confirm(
      `Are You Sure want to delete ${pur.brand_name}`
    );
    if (option) {
      dispatch(deleteBrandMaster(pur.id)).then((res) => {
        dispatch(getAllBrandMaster(1, itemsPerPage));
        handleClear();
      });
    }
  };

  const handleSearch = (val) => {
    setfrom_date("");
    setto_date("");
    setsearch(val);
    dispatch(searchBrandMaster(val))
      .then((res) => {
        setData(res.response.data.results);
      })
      .catch((err) => {
        setsearch("");
        dispatch(getAllBrandMaster(1, itemsPerPage));
      });
  };

  const Submit = () => {
    const user_data = {
      id,
      brand_name: brand_name.trim(),
    };
    console.log("000000", user_data);
    const errors = validate(user_data);
    seterrors(errors);
    console.log("@@@@@", errors);
    if (Object.keys(errors).length === 0) {
      if (!is_edit) {
        dispatch(addBrandMaster(user_data)).then((res) => {
          dispatch(getAllBrandMaster(1, itemsPerPage));
          handleClear();
          alert(res.response.data.msg);
        });
      } else {
        dispatch(updateBrandMaster(user_data)).then((res) => {
          //console.log('!!!!!!',res.response.data.success)
          setis_edit(false);
          handleClear();
          dispatch(getAllBrandMaster(currentPage, itemsPerPage));
          if (res.response.data.success) {
            alert("Updated");
          }
        });
      }
    } else {
      // console.log('@#@#@#@#',errors)
    }
  };

  const validate = (data) => {
    const errors = {};
    if (!data.brand_name) {
      errors.brand_name = "*";
    }
    return errors;
  };

  // ------------- Pagination start---------- //
  const [currentPage, setcurrentPage] = useState(1);
  const [itemsPerPage, setitemsPerPage] = useState(5);

  const [pageNumberLimit, setpageNumberLimit] = useState(5);
  const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
  const [minPageNumberLimit, setminPageNumberLimit] = useState(0);

  const [data, setData] = useState([]);

  const handleClick = (event) => {
    setcurrentPage(Number(event.target.id));
    dispatch(getAllBrandMaster(Number(event.target.id), itemsPerPage));
  };

  const pages = [];
  for (let i = 1; i <= Math.ceil(tot / itemsPerPage); i++) {
    pages.push(i);
  }

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  //const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  useEffect(() => {
    dispatch(getAllBrandMaster(currentPage, itemsPerPage));
  }, [currentPage, itemsPerPage]);

  const handleNextbtn = () => {
    setcurrentPage(currentPage + 1);
    // dispatch(getAllSupplier(currentPage))
    if (currentPage + 1 > maxPageNumberLimit) {
      setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
  };

  const handlePrevbtn = () => {
    setcurrentPage(currentPage - 1);
    if ((currentPage - 1) % pageNumberLimit == 0) {
      setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
    }
  };

  let pageIncrementBtn = null;
  if (pages.length > maxPageNumberLimit) {
    pageIncrementBtn = <li onClick={handleNextbtn}> &hellip;+ </li>;
  }

  let pageDecrementBtn = null;
  if (minPageNumberLimit >= 1) {
    pageDecrementBtn = <li onClick={handlePrevbtn}> &hellip;- </li>;
  }

  const renderPageNumbers = pages.map((number) => {
    if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
      return (
        <li
          key={number}
          id={number}
          onClick={handleClick}
          className={currentPage == number ? "active" : null}
        >
          {number}
        </li>
      );
    } else {
      return null;
    }
  });

  const handleLoadMore = () => {
    setitemsPerPage(itemsPerPage + 5);
    setmaxPageNumberLimit(5);
    setminPageNumberLimit(0);
    setcurrentPage(1);
  };

  // ------------- Pagination end---------- //

  //console.log("_____DATA______", data);
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={1}>
          <Paper className={classes.paper}>
            <Sidebar title=" Brand Master" />
          </Paper>
        </Grid>

        <Grid item xs={11} style={{ marginTop: "5rem" }}>
          <Grid container spacing={1} style={{ marginTop: "2rem" }}>
            <Grid item xs={2} sm={4} md={4}>
              {" "}
            </Grid>{" "}
            <Grid item xs={8} sm={4} md={4}>
              <TextField
                className="txtfld"
                id="outlined-basic"
                error={errors.brand_name ? true : false}
                variant="outlined"
                label="Brand Name"
                fullWidth={true}
                onChange={(e) => {
                  setbrand_name(e.target.value);
                }}
                value={brand_name}
                onKeyDown={(e) => {
                  if (e.key === "Enter")
                    Submit();
                }}



              />
            </Grid>{" "}
            <Grid item xs={2} sm={4} md={4}>
              {" "}
            </Grid>{" "}
          </Grid>

          {/* <Grid container spacing={1} style={{ marginTop: "1rem" }}>
            <Grid item xs={4} sm={4} md={4}>
              {" "}
            </Grid>{" "}
            <Grid item xs={4} sm={4} md={4} style={{ textAlign: "center" }}>
              <Button variant="contained" color="primary" onClick={Submit}>
                {" "}
                {is_edit === false ? "Submit" : "Update"}{" "}
              </Button>
            </Grid>{" "}
            <Grid item xs={4} sm={4} md={4}>
              {" "}
            </Grid>{" "}
          </Grid> */}

          <Grid container spacing={1} style={{ marginTop: "1rem" }}>
            <Grid item xs={12} sm={1} md={3}>
              {" "}
            </Grid>{" "}
            <Grid item xs={12} sm={10} md={6}>
              <ViewBrandMaster
                brand={data}
                search={search}
                from_date={from_date}
                to_date={to_date}
                cp={currentPage}
                onHandleUpdate={handleUpdate}
                onHandleDelete={handleDelete}
                onHandleSearch={handleSearch}
              />
              {search.length > 0 || from_date.length > 0 ? null : (
                <>
                  <div className="pageNumbers">
                    <button
                      onClick={handlePrevbtn}
                      disabled={currentPage == pages[0] ? true : false}
                    >
                      {" "}
                      &lt;&lt;
                    </button>
                    {pageDecrementBtn}
                    {renderPageNumbers}
                    {pageIncrementBtn}
                    <button
                      onClick={handleNextbtn}
                      disabled={
                        currentPage == pages[pages.length - 1] ? true : false
                      }
                    >
                      {" "}
                      &gt;&gt;
                    </button>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      margin: "20px 50px",
                    }}
                  >
                    <button onClick={handleLoadMore} className="loadmore">
                      Load More
                    </button>
                  </div>
                </>
              )}
            </Grid>{" "}
            <Grid item xs={12} sm={1} md={3}>
              {" "}
            </Grid>{" "}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },

  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },

  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
}));

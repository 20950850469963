import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import {
  TextField,
  Button,
  Grid,
  Paper,
  FormControl,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { Box, MenuItem, InputLabel } from "@mui/material";

import { makeStyles } from "@material-ui/core/styles";
import * as moment from "moment";
import AdminNavbar from "../../components/AdminNavbar/AdminNavbar";
import Sidebar from "../../components/Sidebar";
import { useDispatch, useSelector } from "react-redux";
import jwt_decode from "jwt-decode";
import Select from "react-select";

import {
  addPurchase,
  deletePurchase,
  updatePurchase,
  getAllPurchase,
  getAllPurchaseLength,
  allPurchaseClear,
  searchPurchase,
  filterPurchase,
  updatePurchaseQuantity,
  deletePurchaseQuantity,
} from "../../redux/actions/purchaseAction";

import {
  addBills,
  deleteBills,
  updateBills,
  getAllBills,
  getAllBillsbycus,
  getAllBillsLength,
  allBillsClear,
  searchBills,
  filterBills,
  CheckedBillBills,
} from "../../redux/actions/billsAction";

import Loading from "../../components/Loading";
import Error from "../../components/Error";
import OnLineErrors from "../../components/OnLineErrors";
import Success from "../../components/Success";
import "./pagestyle.css";
import "./printbills.css";
import { useReactToPrint } from "react-to-print";
import Modal from "react-modal";
import logo from "../../assets/logo.png";
import ViewBills from "./ViewBills";


var datetime = new Date();
var tdy_date = datetime
  .toISOString()
  .slice(0, 10)
  .split("-")
  .reverse()
  .join("-");
var t_date = datetime.toISOString().slice(0, 10).split("-").join("-");

export default function Bills() {
  const classes = useStyles();

  const [is_edit, setis_edit] = useState(false);
  const [isbill, setis_bill] = useState(false);
  const [id, setid] = useState("");
  const [brand, setbrand] = useState("");
  const [description, setdescription] = useState("");
  const [quantity, setquantity] = useState("");
  const [maxquantity, setmaxquantity] = useState("");
  const [frame_rate, setframe_rate] = useState("");
  const [pay_mode, setpay_mode] = useState("");
  const [pay_mode_val, setpay_mode_val] = useState("");
  const [amount, samount] = useState("");
  const [model_number, setmodel_number] = useState("");
  const [frame_color, setframe_color] = useState("");
  const [frame_size, setframe_size] = useState("");
  const [frame_type, setframe_type] = useState("");
  const [lens_rate, setlens_rate] = useState();

  const [errors, seterrors] = useState([]);
  const [search, setsearch] = useState("");
  const [tot, setTot] = useState(0);

  const [openUpdate, setOpenUpdate] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("sm");

  const optionsframe_type = [
    { value: "Shell", label: "Shell" },
    { value: "Supra", label: "Supra" },
    { value: "3 pc", label: "3 pc" },
    { value: "Metal frame full", label: "Metal frame full" },
    { value: "Metal frame half", label: "Metal frame half" },
    { value: "Reading glass", label: "Reading glass" },
  ];
  const optionspm = [
    { value: "Cash", label: "Cash" },
    { value: "G-Pay", label: "G-Pay" },
    { value: "DebitCard", label: "Debit Card" },
    { value: "CreditCard", label: "Credit Card" },
    { value: "Upi", label: "Upi" },
  ];
  const customStyles = {
    content: {
      width: "50%",
      height: "90%",
      left: "25%",
      backgroundColor: "#FFF",
    },

    control: (base) => ({
      ...base,
      borderColor: errors.pay_mode === "*" ? "red" : "gray",
    }),
  };
  const handleUpdateClose = () => {
    setOpenUpdate(false);
  };

  const userState = useSelector((state) => state.loginUserReducer);
  const { currentUser } = userState;

  const add_purchase_state = useSelector(
    (state) => state.getAddPurchaseReducer
  );
  const { success, error, loading } = add_purchase_state;

  const all_purchase_state = useSelector(
    (state) => state.getAllPurchaseReducer
  );
  const { purchase } = all_purchase_state;

  const all_bills_state = useSelector((state) => state.getAllBillsReducer);
  const { bills } = all_bills_state;
  //console.log('-------#####--------', bills)


  useEffect(() => {

    // handleTodayDate();
    if (currentUser.length > 0) {
      var decoded = jwt_decode(userState.currentUser);
      //console.log('-------#####--------',decoded.role)
      //console.log('-------*****--------',((decoded.role !== 'admin') && (decoded.role !== 'nesi')))
      if (decoded.role !== "admin" && decoded.role !== "nesi") {
        window.location.href = "/login";
      }
    } else {
      window.location.href = "/login";
    }
    setpro_data(purchase);
    setData(bills);

    dispatch(allBillsClear());
    dispatch(getAllBillsLength()).then((response) => {
      setTot(response.response.data.results[0].tot);
    });
    dispatch(getAllBills(1, itemsPerPage));

    dispatch(allPurchaseClear());

  }, []);

  useEffect(() => {
    setData(bills);
  }, [bills]);

  useEffect(() => {
    setpro_data(purchase);
  }, [purchase]);

  useEffect(() => {
    if (search.length < 1) {
      dispatch(getAllPurchase(currentPage, 1));
    }
  }, [search]);

  const dispatch = useDispatch();



  const handleClear = () => {
    // console.log('------clear-------')
    setis_edit(false);
    setbrand("");
    setmodel_number("");
    setdescription("");
    setquantity("");
    setmaxquantity("");
    setframe_rate("");
    setlens_rate("");
    setframe_type("");
  };

  const handleDelete = (sal) => {
    console.log("@@@@", sal);

    var option = window.confirm(`Are You Sure want to delete  ${sal.brand}`);
    if (option) {

      dispatch(deletePurchaseQuantity(sal))
        .then((res) => {
          dispatch(deleteBills(sal.id)).then((res) => {
            dispatch(getAllBills(1, itemsPerPage, cus_name, phone));
            dispatch(getAllPurchase(currentPage, 10));
            handleClear();
          });
        })
        .catch((err) => {
          console.log("--EROOR--", err);
        });
    }
  };

  const handleSwitchChange = (sal, new_status) => {
    console.log("+++++tar++++", sal.id, new_status);

    const data = { cus_name };
    dispatch(CheckedBillBills(sal.id, new_status)).then((res) => {

      dispatch(getAllBills(1, itemsPerPage))
        .then((res) => {
          console.log("------ RP ------", res.response.data.results);
          setData(res.response.data.results);
          window.location.reload();
        })
        .catch((err) => {
          console.log("------ ERR ------", err);
          //dispatch(getAllBillService(1,itemsPerPage, cus_name, phone))
        });
    });
  };

  const handleSearch = (val) => {
    setsearch(val);
    if (search.length > 0) {
      dispatch(searchBills(val))
        .then((res) => {
          setData(res.response.data.results);
        })
        .catch((err) => {
          setsearch("");
          dispatch(getAllBills(1, itemsPerPage, cus_name, phone));
        });
    }

  };


  const Bill = () => {
    if (cus_name.length > 0) {
      dispatch(getAllBillsbycus(1, itemsPerPage, cus_name, phone))
        .then((res) => {
          console.log("&&&&&======BILL DATA =======&&&&", res);
        })
        .then(() => {
          openModal();
          handlePrint();
          closeModal();
        });
    } else {
      alert("Customer Name Required");
    }
  };

  const Submit = () => {
    const user_data = {
      id,
      cus_name,
      phone,
      place,
      pay_mode,

      // brand,
      // quantity,
      // maxquantity,
      // frame_rate,
      // model_number,
      // frame_color,
      // frame_size,
      // frame_type,
      // lens_rate,
      // amount: (frame_rate + lens_rate) * quantity,
    };
    console.log("000000", user_data);
    const errors = validate(user_data);
    seterrors(errors);
    console.log("@@@@@", errors);
    if (Object.keys(errors).length === 0) {
      dispatch(updateBills(user_data)).then((res) => {
        //console.log('!!!!!!',res.response.data.success)
        setis_edit(false);
        handleClear();
        handleUpdateClose();

        dispatch(getAllBills(1, itemsPerPage, cus_name, phone));
        if (res.response.data.success) {
          alert("Updated");
        }
      });
    } else {
      // console.log('@#@#@#@#',errors)
    }
  };

  const validate = (data) => {
    const errors = {};

    if (!data.cus_name || data.cus_name === "*") {
      errors.cus_name = "*";
    }
    if (!data.phone || data.phone === "*") {
      errors.phone = "*";
    }
    if (!data.place) {
      errors.place = "*";
    }
    if (!data.pay_mode) {
      errors.pay_mode = "*";
    }
    // if (!data.brand) {
    //   errors.brand = "*";
    // }
    // if (!data.model_number) {
    //   errors.model_number = "*";
    // }
    // if (!data.quantity) {
    //   errors.quantity = "*";
    // }
    // if (!data.frame_rate) {
    //   errors.frame_rate = "*";
    // }
    // if (!data.frame_type) {
    //   errors.frame_type = "*";
    // }
    // if (!data.lens_rate) {
    //   errors.lens_rate = "*";
    // }

    // if (!data.amount) {
    //   errors.amount = "*";
    // }

    return errors;
  };

  // -------------- CUSTOMER Start------------- //

  const [cus_name, setcus_name] = useState("");
  const [phone, setphone] = useState("");
  const [place, setplace] = useState("");

  // --------------  CUSTOMER End  -----------------//

  // --------------  Products Start ------------- //
  const [pro_data, setpro_data] = useState([]);
  const [pro_search, setpro_search] = useState("");

  const handleSearchProduct = (val) => {
    setpro_search(val);
    console.log("#####", pro_search);
    if (pro_search != "") {
      dispatch(searchPurchase(val))
        .then((res) => {
          setpro_data(res.response.data.results);
        })
        .catch((err) => {
          setsearch("");
          dispatch(getAllPurchase(1, 10));
        });
    }
  };

  const handleEditProduct = (pur) => {
    setid(pur.id);
    // setpur_date(moment(pur.pur_date).format("DD-MM-YYYY"))
    setbrand(pur.brand);
    setmodel_number(pur.model_number);
    setframe_color(pur.frame_color);
    setframe_size(pur.frame_size);
    setdescription(pur.description);
    setquantity(0);
    setmaxquantity(pur.quantity);
    setframe_rate(pur.frame_rate);
    setframe_type(pur.frame_type);
    window.scrollTo({
      top: 100,
      behavior: "smooth",
    });
  };

  const handleEditBills = (sal) => {
    //console.log("@@@@*****@@@@", sal);
    // setis_edit(true);

    //setid(sal.id);
    setis_bill(true);
    setcus_name(sal.cus_name);
    setphone(sal.phone);
    setplace(sal.place);

    window.scrollTo({
      top: 100,
      behavior: "smooth",
    });
  };

  const handleUpdateBills = (sal) => {
    //  console.log("12345", isbill);
    //  console.log("@@@@*****@@@@", sal);
    setid(sal.id);
    setcus_name(sal.cus_name);
    setphone(sal.phone);
    setplace(sal.place);

    setpay_mode(sal.pay_mode);
    setpay_mode_val({ label: sal.pay_mode, value: sal.pay_mode });

    window.scrollTo({
      top: 100,
      behavior: "smooth",
    });

    setOpenUpdate(true);
  };
  // --------------  Products End  -----------------//

  // ------------- Pagination start---------- //
  const [currentPage, setcurrentPage] = useState(1);
  const [itemsPerPage, setitemsPerPage] = useState(25);

  const [pageNumberLimit, setpageNumberLimit] = useState(5);
  const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
  const [minPageNumberLimit, setminPageNumberLimit] = useState(0);

  const [data, setData] = useState([]);

  const handleClick = (event) => {
    setcurrentPage(Number(event.target.id));
    dispatch(getAllBills(Number(event.target.id), itemsPerPage));
  };

  const pages = [];
  for (let i = 1; i <= Math.ceil(tot / itemsPerPage); i++) {
    pages.push(i);
  }

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  //const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  useEffect(() => {
    dispatch(getAllBills(currentPage, itemsPerPage));
  }, [currentPage, itemsPerPage]);

  const handleNextbtn = () => {
    setcurrentPage(currentPage + 1);
    // dispatch(getAllBills(currentPage))
    if (currentPage + 1 > maxPageNumberLimit) {
      setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
  };

  const handlePrevbtn = () => {
    setcurrentPage(currentPage - 1);
    if ((currentPage - 1) % pageNumberLimit == 0) {
      setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
    }
  };

  let pageIncrementBtn = null;
  if (pages.length > maxPageNumberLimit) {
    pageIncrementBtn = <li onClick={handleNextbtn}> &hellip;+ </li>;
  }

  let pageDecrementBtn = null;
  if (minPageNumberLimit >= 1) {
    pageDecrementBtn = <li onClick={handlePrevbtn}> &hellip;- </li>;
  }

  const renderPageNumbers = pages.map((number) => {
    if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
      return (
        <li
          key={number}
          id={number}
          onClick={handleClick}
          className={currentPage == number ? "active" : null}
        >
          {number}
        </li>
      );
    } else {
      return null;
    }
  });

  const handleLoadMore = () => {
    setitemsPerPage(itemsPerPage + 100);
    setmaxPageNumberLimit(5);
    setminPageNumberLimit(0);
    setcurrentPage(1);
  };

  // ------------- Pagination end---------- //

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#f00";
  }

  function closeModal() {
    setIsOpen(false);
  }

  // var subtotal = bills.reduce(
  //   (x, sal) => x + sal.frame_rate * sal.quantity,
  //   0
  // );

  var subtotal = bills.reduce(
    // (x, sal) => x + (sal.frame_rate + sal.lens_rate) * sal.quantity,
    (x, sal) => x + (sal.amount) * sal.quantity,
    0
  );

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={1}>
          <Paper className={classes.paper}>
            <Sidebar title="Bills" bgcolor="#00b894" />
          </Paper>
        </Grid>

        <Grid item xs={11} style={{ marginTop: "5rem" }}>
          <Paper className={classes.paper}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={12}>

              </Grid>
            </Grid>

            <Grid container spacing={2} style={{ marginTop: "3rem" }}>
              <Grid item xs={12} sm={4} md={2}>
                {" "}
                <TextField
                  className="txtfld"
                  id="outlined-basic"
                  error={errors.cus_name ? true : false}
                  variant="outlined"
                  fullWidth={false}
                  onChange={(e) => {
                    setcus_name(e.target.value);
                  }}
                  label="Patient Name"
                  value={cus_name || ""}
                  autoComplete="off"
                />
              </Grid>
              <Grid item xs={12} sm={4} md={2}>
                {" "}
                <TextField
                  className="txtfld"
                  id="outlined-basic"
                  error={errors.phone ? true : false}
                  variant="outlined"
                  fullWidth={false}
                  onChange={(e) => {
                    setphone(e.target.value);
                  }}
                  value={phone || ""}
                  label="Mobile Number"
                  autoComplete="off"
                />
              </Grid>
              <Grid item xs={12} sm={4} md={2}>
                {" "}
                <TextField
                  className="txtfld"
                  id="outlined-basic"
                  error={errors.place ? true : false}
                  variant="outlined"
                  fullWidth={false}
                  onChange={(e) => {
                    setplace(e.target.value);
                  }}
                  label="Place"
                  value={place || ""}
                  autoComplete="off"
                />
              </Grid>
              <Grid item xs={12} sm={4} md={2}>
                {" "}
                {/* <TextField
                  className="txtfld"
                  id="outlined-basic"
                  error={errors.brand ? true : false}
                  variant="outlined"
                  fullWidth={false}
                  onChange={(e) => {
                    setbrand(e.target.value);
                  }}
                  label="Brand"
                  value={brand || ""}
                  autoComplete="off"
                /> */}
              </Grid>
              <Grid item xs={12} sm={4} md={2}>
                {" "}
                {/* <TextField
                  className="txtfld"
                  id="outlined-basic"
                  error={errors.model_number ? true : false}
                  variant="outlined"
                  fullWidth={false}
                  onChange={(e) => {
                    setmodel_number(e.target.value);
                  }}
                  value={model_number || ""}
                  label="Model Number"
                  autoComplete="off"
                /> */}
              </Grid>
              <Grid item xs={12} sm={4} md={2}>
                {/* <TextField
                  className="txtfld"
                  id="outlined-basic"
                  error={errors.frame_type ? true : false}
                  variant="outlined"
                  fullWidth={false}
                  onChange={(e) => {
                    setframe_type(e.target.value);
                  }}
                  label="Frame Type"
                  value={frame_type || ""}
                  autoComplete="off"
                /> */}
              </Grid>
            </Grid>

            <Grid container spacing={2} className="mt-4">
              {/* <Grid item xs={12} sm={4} md={2}>
                {" "}
                <TextField
                  className="txtfld"
                  id="outlined-basic"
                  type="number"
                  error={errors.lens_rate ? true : false}
                  variant="outlined"
                  fullWidth={false}
                  onChange={(e) => {
                    setlens_rate(parseFloat(e.target.value));
                  }}
                  label="Lens Rate"
                  value={lens_rate || ""}
                  autoComplete="off"
                />
              </Grid> */}
              {/* <Grid item xs={12} sm={4} md={2}>
                {" "}
                <TextField
                  className="txtfld"
                  id="outlined-basic"
                  type="number"
                  error={errors.quantity ? true : false}
                  variant="outlined"
                  fullWidth={false}
                  onChange={(e) => {
                    setquantity(
                      e.target.value <= maxquantity && e.target.value > 0
                        ? e.target.value
                        : ""
                    );
                  }}
                  value={quantity || ""}
                  label="Quantity"
                  autoComplete="off"
                />
              </Grid> */}
              {/* <Grid item xs={12} sm={4} md={2}>
                {" "}
                <TextField
                  className="txtfld"
                  id="outlined-basic"
                  error={errors.frame_rate ? true : false}
                  type="number"
                  variant="outlined"
                  fullWidth={false}
                  // onChange={(e) => {setframe_rate(e.target.value)}}
                  value={frame_rate || ""}
                  label="Selling Rate"
                  autoComplete="off"
                />
              </Grid> */}
              {/* <Grid item xs={12} sm={4} md={2}>
                {" "}
                <TextField
                  className="txtfld"
                  id="outlined-basic"
                  error={errors.amount ? true : false}
                  type="number"
                  variant="outlined"
                  label="Amount"
                  fullWidth={false}
                  // onChange={(e) => {setamount(e.target.value)}}
                  value={(frame_rate + lens_rate) * quantity || ""}
                  autoComplete="off"
                />
              </Grid> */}
              <Grid item xs={12} sm={4} md={2}>
                {" "}
              </Grid>
            </Grid>
            {/* 
            <Grid container spacing={5} className="mt-4">
              <Grid item xs={12} sm={6} md={3}></Grid>

              <Grid item xs={12} sm={6} md={3}>
                {" "}
                <Button variant="contained" color="primary" onClick={Submit}>
                  {" "}
                  {is_edit === false ? "Save" : "Update"}{" "}
                </Button>
              </Grid>

              <Grid item xs={12} sm={6} md={3}></Grid>
            </Grid> */}
          </Paper>
        </Grid>
      </Grid>

      {/* ------------------------------------------------EDIT */}
      <Grid>
        <React.Fragment>
          <Dialog
            fullWidth={fullWidth}
            maxWidth={"sm"}
            open={openUpdate}
            onClose={handleUpdateClose}
            aria-labelledby="max-width-dialog-title"
          >
            <DialogTitle id="max-width-dialog-title">
              {cus_name} {place}
            </DialogTitle>
            <DialogContent>
              <>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Paper className={classes.paper}>
                      <Grid container spacing={1} style={{ marginTop: "0rem" }}>
                        <Grid item xs={12} sm={6} md={6}>
                          <Select
                            value={pay_mode_val}
                            onChange={(e) => {
                              setpay_mode(e.value);
                              setpay_mode_val(e);
                            }}
                            options={optionspm}
                            placeholder={"Search..."}
                            styles={customStyles}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={Submit}
                          >
                            {" "}
                            {is_edit === false ? "Save" : "Update"}{" "}
                          </Button>
                        </Grid>
                      </Grid>
                      <br />
                      <br /> <br />
                      <br /> <br /> <br /> <br />
                      <br /> <br />
                    </Paper>

                    <Grid item xs={12} sm={4}></Grid>
                  </Grid>
                </Grid>
              </>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleUpdateClose} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </React.Fragment>
      </Grid>
      {/*  */}

      <Grid item xs={12} style={{ marginTop: "3rem" }}>
        <ViewBills
          bills={data}
          search={search}

          cp={currentPage}
          isbill={isbill}
          // onHandleUpdate={handleUpdate}
          onHandleDelete={handleDelete}
          onHandleSearch={handleSearch}


          onBillFetch={Bill}
          onHandleSwitchChange={handleSwitchChange}
          onHandleEditChange={handleEditBills}
          onHandleUpdate={handleUpdateBills}
        />
        <br /> <br />
        {cus_name.length > 1 || search.length > 0 || phone === "**" ? null : (
          <>
            <div className="pageNumbers">
              <button
                onClick={handlePrevbtn}
                disabled={currentPage == pages[0] ? true : false}
              >
                {" "}
                &lt;&lt;
              </button>
              {pageDecrementBtn}
              {renderPageNumbers}
              {pageIncrementBtn}
              <button
                onClick={handleNextbtn}
                disabled={currentPage == pages[pages.length - 1] ? true : false}
              >
                {" "}
                &gt;&gt;
              </button>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "20px 50px",
              }}
            >
              <button onClick={handleLoadMore} className="loadmore">
                Load More
              </button>
            </div>
          </>
        )}
      </Grid>

      {/*  */}
      <div style={{ textAlign: "center" }}>
        {/* <button onClick={openModal}>Open Modal</button> */}
        <Modal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
          ariaHideApp={false}
        >
          <div
            className="row"
            style={{ backgroundColor: "#ffecf2", marginTop: "-1rem" }}
          >
            <div className="col-print-6">
              <h2
                ref={(_subtitle) => (subtitle = _subtitle)}
                onClick={handlePrint}
                style={{ textAlign: "left", cursor: "pointer" }}
              >
                Print
              </h2>
            </div>
            <div className="col-print-6">
              <button
                onClick={closeModal}
                style={{
                  float: "right",
                  backgroundColor: "#ffecf2",
                  fontSize: "2.5rem",
                }}
              >
                X
              </button>
            </div>
          </div>
          <>
            <style>{getPageMargins()}</style>

            <div ref={componentRef}>
              <div
                className="row"
                style={{ textAlign: "left", marginTop: "3rem" }}
              >
                <div className="col-print-1"></div>
                <div className="col-print-11">
                  <div className="row">
                    <div className="col-print-0"> </div>
                    <div className="col-print-9">
                      {/* <h2 style={{textAlign:'left'}}>Logo</h2>   */}
                      <img
                        src={logo}
                        style={{
                          textAlign: "left",
                          height: "5rem",
                          width: "8rem",
                        }}
                      />
                    </div>
                    <div className="col-print-3">
                      <h2 style={{ textAlign: "left", marginTop: "3rem" }}>
                        BILL
                      </h2>
                      {/* <p>{bill_num}</p> */}
                    </div>
                  </div>
                </div>
                <div className="col-print-0"></div>
              </div>

              <div
                className="row"
                style={{ textAlign: "center", marginTop: "2rem" }}
              >
                <div className="col-print-1"> </div>
                <div className="col-print-9">
                  <p style={{ textAlign: "left", fontSize: "130%" }}>
                    Nesi Optical Shop <br /> No.6, Gandhipuram Main Road, Karur,{" "}
                    <br />
                    Backside Of EB Office - 639002 <br />
                    +91 9894670007{" "}
                  </p>
                </div>
                <div className="col-print-3"> </div>
              </div>

              <div
                className="row"
                style={{ textAlign: "center", marginTop: "2rem" }}
              >
                <div className="col-print-1"></div>
                <div className="col-print-7">
                  <h5 style={{ textAlign: "left", fontSize: "130%" }}>
                    Bill To
                  </h5>
                  <p style={{ textAlign: "left", fontSize: "130%" }}>
                    {cus_name} <br /> {place} <br /> {phone}{" "}
                  </p>
                </div>
                <div className="col-print-4">
                  {/* <h5 style={{textAlign:'left',fontSize:'130%'}}>Invoice# : INV-12</h5>   */}
                  <p style={{ textAlign: "left", fontSize: "130%" }}>
                    Invoice Date: {tdy_date}{" "}
                  </p>
                </div>
              </div>

              <div
                className="row"
                style={{ textAlign: "center", marginTop: "4rem" }}
              >
                <div className="col-print-1"></div>
                <div className="col-print-10">
                  <table className="table  table-responsive">
                    <thead
                      style={{
                        backgroundColor: "#fff",
                        color: "#1a4567",
                        fontSize: "150%",
                      }}
                    >
                      <tr>
                        <th style={{ width: "100%" }}>Description</th>
                        <th style={{ width: "100%" }}>UnitPrice</th>
                        <th style={{ width: "100%" }}>Quantity</th>
                        <th style={{ width: "100%" }}>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {bills.length > 0 ? (
                        bills.map((sal, index) => {
                          return (
                            <tr key={index} style={{ fontSize: "130%" }}>
                              <td> {sal.brand} </td>
                              <td> {sal.amount} </td>
                              <td> {sal.quantity} </td>
                              <td> {sal.amount}</td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td>No Data</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  <hr />

                  <div style={{ marginTop: "2rem" }}>
                    <br></br>
                    <p
                      style={{
                        textAlign: "right",
                        marginRight: "2rem",
                        fontSize: "130%",
                      }}
                    >
                      SubTotal : {Math.round(subtotal)}
                    </p>
                    {/* <p
                      style={{
                        textAlign: "right",
                        marginRight: "2rem",
                        fontSize: "130%",
                      }}
                    >
                      SGST : {Math.round(subtotal * 0.09)}
                    </p>
                    <p
                      style={{
                        textAlign: "right",
                        marginRight: "2rem",
                        fontSize: "130%",
                      }}
                    >
                      CGST : {Math.round(subtotal * 0.09)}
                    </p> */}
                    <p
                      style={{
                        textAlign: "right",
                        marginRight: "2rem",
                        fontSize: "150%",
                        fontWeight: "bold",
                      }}
                    >
                      Total Amount: <span>&#8377;</span> {subtotal}
                    </p>
                  </div>
                </div>
                <div className="col-print-1"></div>
              </div>

              <div className="footer">
                <p>THANK YOU FOR YOUR BUSINESS </p>
              </div>
            </div>
          </>
        </Modal>
      </div>
      {/*  */}
    </>
  );
}

const marginTop = "20px";
const marginRight = "5px";
const marginBottom = "10px";
const marginLeft = "5px";
const getPageMargins = () => {
  return `@page { margin: ${marginTop} ${marginRight} ${marginBottom} ${marginLeft} !important; }`;
};

const Section = styled.section`
  margin: 10px auto;
  max-width: 650px !important;
  .wrapper {
    background-color: #ffffff;
    border: 1px solid #acdf87;
    list-style-type: none;
    padding: 10px 50px;
    border-radius: 10px;
  }

  .form-row {
    display: flex;
    justify-content: flex-end;
    padding: 0.3rem;
  }
  .form-row > label {
    padding: 0.5em 1em 0.5em 0;
    flex: 1;
    color: #1e5631;
  }

  .form-row .txtfld {
    flex: 2;
  }

  @media screen and (min-width: 280px) and (max-width: 720px) {
    .form-row {
      display: flex;
      flex-direction: column;
    }
  }
  @media screen and (min-width: 720px) and (max-width: 1080px) {
    .form-row {
      display: flex;
      flex-direction: column;
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },

  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

import React, { useState, useEffect } from "react";
import styled from "styled-components";
import * as moment from "moment";
import { TextField, Button } from "@material-ui/core";
import DatePicker from "react-datepicker";

export default function ViewPurchase(props) {
  return (
    <>
      <Section>
        <div className="view__acc_container">
          <div className="row">
            <div className="col-md-2 mt-2">
              <h1>Products</h1>
              <TextField
                className="txtfld"
                id="outlined-basic"
                variant="outlined"
                fullWidth={false}
                onChange={(e) => {
                  props.onHandleSearchProduct(e.target.value);
                }}
                value={props.search}
                placeholder="Search"
                style={{ backgroundColor: "white" }}
              />
            </div>
            <div className="col-md-10">
              <table className="table table-bordered table-responsive-sm">
                <thead style={{ backgroundColor: "#3dc1d3", color: "#ffffff" }}>
                  <tr>
                    {/* <th>S.No</th> */}
                    <th>Brand</th>
                    <th>ModelNo</th>
                    <th>FrameType</th>
                    <th>FrameColor</th>
                    <th>FrameSize</th>
                    <th>Quantity</th>
                    <th>SellingRate</th>
                  </tr>
                </thead>
                <tbody>
                  {props.purchase.length > 0 ? (
                    props.purchase.map((pur, index) => {
                      return (
                        <tr
                          key={index}
                          onClick={() => {
                            props.onHandleEditProduct(pur);
                          }}
                        >
                          {/* <td> {index + 1} </td> */}
                          {/* <td> {moment(pur.entry_date).format("DD-MM-YYYY")} </td> */}
                          {/* <td> {moment(pur.pur_date).format("DD-MM-YYYY")} </td> */}
                          <td> {pur.brand} </td>
                          <td> {pur.model_number} </td>
                          <td> {pur.frame_type} </td>
                          <td> {pur.frame_color} </td>
                          <td> {pur.frame_size} </td>
                          <td> {pur.quantity} </td>
                          <td> {pur.selling_rate} </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td>No Data</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <div style={{ textAlign: "left" }}></div>

          <br />
        </div>
      </Section>
    </>
  );
}

const Section = styled.section`
  .view__acc_container {
    margin: 0px 50px;
  }

  .view__filterbox__container {
    margin: 20px auto;
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    gap: 1rem;
    padding: 1rem;
    background-color: #acdf87;
  }
  .view__filterdate__container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    flex: 0.7;
  }
  .view__filtersearch__container {
    flex: 0.3;
    text-align: center;
  }

  @media screen and (min-width: 280px) and (max-width: 720px) {
    .view__filterbox__container {
      display: flex;
      flex-direction: column;
    }
    .view__filterdate__container {
      display: flex;
      flex-direction: column;
    }
  }
`;

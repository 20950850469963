import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.js";

import Login from "./pages/login/Login";
import Sales from "./pages/sales/Sales";
import Bills from "./pages/bills/Bills";
import Est from "./pages/est/Est";
import Job from "./pages/job/Job";
import Products from "./pages/purchase/Purchase";
import AdminPurchase from "./pages/Report/AdminPurchase";
import MrpFixing from "./pages/purchase/MrpFixing";
import SupplierMaster from "./pages/supplier-master/SupplierMaster";
import JobOrder from "./pages/job-order/JobOrders";

import BrandMaster from "./pages/brand-master/BrandMaster";
import ModelNumberMaster from "./pages/model-number/ModelNumberMaster";
import FrameColorMaster from "./pages/frame-color-master/FrameColorMaster";
import LensPowerMaster from "./pages/lens-power-master/LensPowerMaster";
import LensRateMaster from "./pages/lens-rate-master/LensRateMaster";
import FitterMaster from "./pages/fitter-master/FitterMaster";

import { Redirect } from "react-router-dom";
import { BrowserRouter, Route } from "react-router-dom";

export default function Router() {
  return (
    <div>
      <BrowserRouter>
        <Route exact path="/" component={Login} />
        <Route exact path="/login" component={Login} />

        <Route exact path="/est" component={Est} />
        <Route exact path="/job" component={Job} />
        <Route exact path="/sales" component={Sales} />
        <Route exact path="/bills" component={Bills} />
        <Route exact path="/products" component={Products} />
        <Route exact path="/admin-purchase" component={AdminPurchase} />
        <Route exact path="/mrp-fixing" component={MrpFixing} />
        <Route exact path="/supplier-master" component={SupplierMaster} />

        <Route exact path="/brand-master" component={BrandMaster} />
        <Route
          exact
          path="/model-number-master"
          component={ModelNumberMaster}
        />
        <Route exact path="/frame-color-master" component={FrameColorMaster} />
        <Route exact path="/lens-power-master" component={LensPowerMaster} />
        <Route exact path="/lens-rate-master" component={LensRateMaster} />
        <Route exact path="/fitter-master" component={FitterMaster} />

        <Route exact path="/job-order" component={JobOrder} />
        {/* <Route render={() => <Redirect to={{ pathname: "/" }} />} /> */}
      </BrowserRouter>
    </div>
  );
}

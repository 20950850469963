export const getAllBrandMasterReducer = (state = { brand: [] }, action) => {
  switch (action.type) {
    case "GET_BRANDMASTER_SUCCESS":
      return {
        loading: false,
        brand: action.payload,
      };
    default:
      return state;
  }
};

export const getAllBrandNamesReducer = (
  state = { brand_names: [] },
  action
) => {
  switch (action.type) {
    case "GET_BNAME_SUCCESS":
      return {
        brand_names: action.payload,
      };
    default:
      return state;
  }
};

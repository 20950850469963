import axios from "axios";
import { apiUrl } from "../../config";

export const addSupplierMaster = (acc) => async (dispatch) => {
  console.log(" CCCCCUUUUSSSS------>", acc);
  dispatch({ type: "ADD_SUPPLIERMASTER_REQUEST" });
  try {
    const response = await axios.post(
      `${apiUrl}/api/supplier-master/create`,
      acc
    );
    console.log("+++ add", response);
    dispatch({ type: "ADD_SUPPLIERMASTER_SUCCESS" });
    return {
      response,
    };
  } catch (error) {
    dispatch({ type: "ADD_SUPPLIERMASTER_FAILED", payload: error });
  }
};

export const allSupplierMastersClear = (acc) => async (dispatch) => {
  try {
    dispatch({ type: "ALL_SUPPLIERMASTER_CLEAR" });
  } catch (error) {
    dispatch({ type: "ALL_SUPPLIERMASTER_FAILED", payload: error });
  }
};

export const getAllSupplierMasterLength = () => async (dispatch) => {
  try {
    const response = await axios.get(
      `${apiUrl}/api/supplier-master/getAllSupplierMaster_length`
    );
    //console.log("%%%111%%%", response.data.results[0].tot);
    return { response };
  } catch (error) {}
};

export const getAllSupplierMaster = (offset, row_count) => async (dispatch) => {
  dispatch({ type: "GET_SUPPLIERMASTER_REQUEST" });

  try {
    const response = await axios.get(
      `${apiUrl}/api/supplier-master/getallSupplierMaster/${offset}/${row_count}`
    );
    //console.log("%%%%%%", response);
    dispatch({
      type: "GET_SUPPLIERMASTER_SUCCESS",
      payload: response.data.results,
    });
  } catch (error) {
    dispatch({ type: "GET_SUPPLIERMASTER_FAILED", payload: error });
  }
};

export const updateSupplierMaster = (pur) => async (dispatch) => {
  try {
    const response = await axios.post(
      `${apiUrl}/api/supplier-master/update`,
      pur
    );
    return { response };
    // window.location.reload()
  } catch (error) {
    alert("Something Went Wrong");
    console.log(error);
  }
};

export const deleteSupplierMaster = (accid) => async (dispatch) => {
  try {
    const response = await axios.post(`${apiUrl}/api/supplier-master/delete`, {
      accid,
    });
    return { response };
    // window.location.reload()
  } catch (error) {
    alert("Something Went Wrong");
    console.log(error);
  }
};

export const searchSupplierMaster = (val) => async (dispatch) => {
  const data = { id: val };
  try {
    const response = await axios.post(
      `${apiUrl}/api/supplier-master/search`,
      data
    );
    return { response };
    // window.location.reload()
  } catch (error) {
    return { error };
    //alert('Something Went Wrong')
    console.log(error);
  }
};
